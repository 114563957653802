/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Container,
  Alert,
  Media,
} from "reactstrap";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import { Helmet } from "react-helmet";
import Header from "components/Headers/Header";
// import { renderStatus } from "helper/helper";
import NumberFormat from "react-number-format";
// import Select from "react-select";

export default class BankInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      showModalPreviewImage: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      filteredData: [],
      value: "",
      showLoadingTable: false,
      showLoading: false,

      columns: [
        {
          title: "Bank Name",
          dataIndex: "name_bank",
          sorter: (a, b) => a.name_bank.localeCompare(b.name_bank),
        },
        {
          title: "Account Number",
          dataIndex: "number_account",
          sorter: (a, b) => a.number_account.localeCompare(b.number_account),
        },
        {
          title: "Account Name",
          dataIndex: "an",
          sorter: (a, b) => a.an.localeCompare(b.an),
        },
        {
          title: "Image",
          dataIndex: "link_icon",
          sorter: false,
          render: (text, record) => (
            <Media className="align-items-center">
              <a
                className="avatar rounded-circle"
                // href="#pablo"
                onClick={() => this.previewModal(record)}
              >
                <img alt="..." src={text} style={{borderRadius: '50%', maxHeight: '50px'}} />
              </a>
            </Media>
          ),
        },

        {
          title: "Action",
          align: 'center',
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      name_bank: "",
      number_account: "",
      an: "",
      icon: "",
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
      previewImage: null
    };
  }

  componentDidMount = () => {
    this.getdata();
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("content/rekening-bank");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: []
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.name_bank)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.number_account)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.an).toLowerCase().startsWith(value.toLowerCase());
        let includesCondition =
          String(item.name_bank).toLowerCase().includes(value.toLowerCase()) ||
          String(item.number_account)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(item.an).toLowerCase().includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      name_bank: "",
      number_account: "",
      an: "",
      icon: "",
      previewImage: null,
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: true,
      id: record.id,
      name_bank: record.name_bank,
      number_account: record.number_account,
      an: record.an,
      icon: record.link_icon,
      isError: false,
      errorMessage: "",
      previewImage: null,
      contact: record.contact,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      name_bank: record.name_bank,
      number_account: record.number_account,
      an: record.an,
      icon: record.link_icon,
      isError: false,
      errorMessage: "",
    });
  };
  previewModal = (record) => {
    this.toggleModal("showModalPreviewImage");
    this.setState({
      id: record.id,
      name_bank: record.name_bank,
      previewImage: record.link_icon,
      isError: false,
      errorMessage: "",
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = new FormData();
    dataInput.append("name_bank", this.state.name_bank);
    dataInput.append("number_account", this.state.number_account);
    dataInput.append("an", this.state.an);
    dataInput.append("icon", this.state.icon);

    if (this.state.isUpdateForm) {
      this.actionSubmit("content/rekening-bank/" + this.state.id, dataInput);
    } else {
      this.actionSubmit("content/rekening-bank", dataInput);
    }
  };

  actionSubmit = (url, dataInput) => {
    axiosCustom
      .post(url, dataInput, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showModalAdd");
        this.getdata();
      })
      .catch((err) => {
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("content/rekening-bank/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  handleChangeImage = (e) => {
    this.setState({
      icon: e.target.files[0],
      previewImage: URL.createObjectURL(e.target.files[0])
    })
  }

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Helmet>
          <title>Bank Info - Administrator Bikers Dream</title>
          <meta name="description" content="Bank info page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAdd"
                          onClick={() => this.openModal()}
                        >
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Form Bank
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalAdd")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Bank Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.name_bank}
                          onChange={(e) =>
                            this.setState({ name_bank: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Account Number <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.number_account}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ number_account: values.floatValue })
                          }
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Account Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.an}
                          onChange={(e) =>
                            this.setState({ an: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Icon Image <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          // value={this.state.icon}
                          onChange={(e) => this.handleChangeImage(e)
                              // console.log(e)
                            // this.setState({ icon: e.target.files[0]
                          //  })
                          }
                          accept="image/*"
                          // required
                        />
                      </div>
                    </div>
                    {
                      this.state.previewImage && (
                        <div className="col-md-6">
                          <div className="form-group">
                          <img
                              alt="example"
                              style={{ width: "100%", height: '200px', cursor: 'pointer' }}
                              src={this.state.previewImage}
                              onClick={() => this.toggleModal("showModalPreviewImage") }
                            />
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
                {this.state.isError ? (
                  // <div className="row">
                  <div className="col-md-12">
                    <Alert
                      color="danger"
                      isOpen={this.state.isError}
                      toggle={() =>
                        this.setState({
                          isError: false,
                        })
                      }
                    >
                      <strong>Failed!</strong> {this.state.errorMessage}
                    </Alert>
                  </div>
                ) : // </div>
                null}
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalPreviewImage}
              toggle={() => this.toggleModal("showModalPreviewImage")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Image {this.state.name_bank ? `- ${this.state.name_bank}` : ''}
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalPreviewImage")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </div>
            </Modal>

            {/* <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal> */}

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "
                  {this.state.name_bank}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
