/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  // DropdownMenu,
  Row,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownItem,
  Modal,
  CardHeader,
} from "reactstrap";
import { Table, Popconfirm } from "antd";
import "antd/dist/antd.css";
// import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";

export default class FormVariantOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAddOptions1: false,
      showDeleteModalVariantFirst: false,
      showModalConfirmDisable: false,
      showLoadingDisable: false,
      showDeleteModalVariantSecond: false,
      isUpdate: false,

      filteredData: [],
      value: "",

      columnsOptions: [
        {
          title: "Options",
          dataIndex: "options",
          sorter: false,
          render: (text, record) => (
            <span
              onClick={() => this.editDataOption1(record)}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              {text}
            </span>
          ),
        },

        {
          title: "Action",
          align: "center",
          sorter: false,
          render: (text, record) => (
            <div>
              {this.state.showLoadingOptions1 ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => this.processDeleteOptions1(record.id)}
                >
                  <a>
                    <i
                      title="Delete Data"
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </Popconfirm>
              )}
            </div>
          ),
        },
      ],

      idvariant1: "",
      uid: "",
      produkname: "",
      variant: "",
      showLoadingVariantFirst: false,
      isUpdateFormVariant1: false,

      dataOptions: [],
      showLoadingTableOptions1: false,
      idOptions1: "",
      options: "",
      isUpdateFormOption1: false,
      showLoadingOptions1: false,

      columnsOptionSecond: [
        {
          title: "Options",
          dataIndex: "options_second",
          sorter: false,
          render: (text, record) => (
            <span
              onClick={() => this.editDataOptionSecond(record)}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "blue",
              }}
            >
              {text}
            </span>
          ),
        },

        {
          title: "Action",
          align: "center",
          sorter: false,
          render: (text, record) => (
            <div>
              {this.state.showLoadingOptionsecond ? (
                <i className="fas fa-circle-notch fa-spin"></i>
              ) : (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => this.processDeleteOptionsSecond(record.id)}
                >
                  <a>
                    <i
                      title="Delete Data"
                      className="fa fa-trash"
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </Popconfirm>
              )}
            </div>
          ),
        },
      ],

      idvariantsecond: "",
      variant_second: "",
      showLoadingVariantSecond: false,
      isUpdateFormVariantSecond: false,

      dataOptionSecond: [],
      showLoadingTableOptionSecond: false,
      idOptionSecond: "",
      options_second: "",
      isUpdateFormOptionsecond: false,
      showLoadingOptionsecond: false,

      messageErrorVariant: "",

      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  componentDidMount = () => {
    // if (this.props.uid) {
    //   this.getProductDetail(this.props.uid);
    // }
    console.log(this.props.record);
    if (this.props.uid || this.props.record) {
      // this.editData(this.props.uid);
      if (this.props.isUpdate === false) {
        this.getProductDetail(this.props.record.uid);
      } else {
        this.getProductDetail(this.props.uid);
      }
    }
  };
  componentDidUpdate(prevProps) {
    // if (this.props.uid !== prevProps.uid) {
    //   // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    //   this.getProductDetail(this.props.uid);
    // }
    if (
      this.props.uid !== prevProps.uid ||
      this.props.record !== prevProps.record
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      if (this.props.isUpdate === false) {
        this.getProductDetail(this.props.record.uid);
      } else {
        this.getProductDetail(this.props.uid);
      }
    }
  }

  getProductDetail = async (uid) => {
    try {
      const res = await axiosCustom.get("product/" + uid);
      if (res.status === 200) {
        var data = res.data.result;
        this.editData(data);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  editData = (record) => {
    this.getdatavariantFirst(record.uid);
    this.getdatavariantSecond(record.uid);
    this.setState({
      uid: record.uid,
      produkname: record.produkname,
      isUpdate: this.props.isUpdate,
    });
  };

  getdatavariantFirst = async (uid) => {
    this.setState({
      showLoadingTableOptions1: true,
    });
    try {
      const res = await axiosCustom.get("product-variants", {
        params: {
          uid: uid,
        },
      });
      if (res.status === 200) {
        var data = res.data.result;
        if (data.length) {
          this.setState({
            variant: data[0].variant,
            idvariant1: data[0].id,
            isUpdateFormVariant1: true,
          });
          this.getdataOptions1(data[0].id);
        } else {
          this.setState({
            variant: "",
            idvariant1: "",
            isUpdateFormVariant1: false,
            showLoadingTableOptions1: false,
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        variant: "",
        idvariant1: "",
        isUpdateFormVariant1: false,
        showLoadingTableOptions1: false,
      });
    }
  };
  getdataOptions1 = async (id_variant) => {
    this.setState({
      showLoadingTableOptions1: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("product-variants-options", {
        params: {
          id_variant: id_variant,
        },
      });
      if (res.status === 200) {
        var dataOptions = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        this.setState({
          dataOptions,
        });
      }
      this.setState({
        showLoadingTableOptions1: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableOptions1: false,
        dataOptions: [],
      });
    }
  };

  getdatavariantSecond = async (uid) => {
    this.setState({
      showLoadingTableOptionSecond: true,
    });
    try {
      const res = await axiosCustom.get("product-variants-second", {
        params: {
          uid: uid,
        },
      });
      if (res.status === 200) {
        var data = res.data.result;
        if (data.length) {
          this.setState({
            variant_second: data[0].variant_second,
            idvariantsecond: data[0].id,
            isUpdateFormVariantSecond: true,
          });
          this.getdataOptionSecond(data[0].id);
        } else {
          this.setState({
            variant_second: "",
            idvariantsecond: "",
            isUpdateFormVariantSecond: false,
            showLoadingTableOptionSecond: false,
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        variant_second: "",
        idvariantsecond: "",
        isUpdateFormVariantSecond: false,
        showLoadingTableOptionSecond: false,
      });
    }
  };
  getdataOptionSecond = async (id_variant_second) => {
    this.setState({
      showLoadingTableOptionSecond: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("product-variants-options-second", {
        params: {
          id_variant_second: id_variant_second,
        },
      });
      if (res.status === 200) {
        var dataOptionSecond = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        this.setState({
          dataOptionSecond,
        });
      }
      this.setState({
        showLoadingTableOptionSecond: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableOptionSecond: false,
        dataOptionSecond: [],
      });
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  saveVariantFirst = async () => {
    this.setState({
      showLoadingVariantFirst: true,
    });

    const dataInput = {
      uid: this.state.uid,
      variant: this.state.variant,
    };

    try {
      let res = {};
      if (this.state.isUpdateFormVariant1) {
        res = await axiosCustom.put(
          "product-variants/" + this.state.idvariant1,
          dataInput
        );
      } else {
        res = await axiosCustom.post("product-variants", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoadingVariantFirst: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdatavariantFirst(this.state.uid);
      }
    } catch (err) {
      // Handle Error Here
      var msg;
      if (err) {
        if (err.response.data.status === 300) {
          msg = err ? err.response.data.messages : "Error invalid";
          this.setState({
            messageErrorVariant: msg,
            showModalConfirmDisable: true,
          });
        } else {
          msg = err ? err.response.data.messages : "Error invalid";
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }

      this.setState({
        showLoadingVariantFirst: false,
      });
    }
  };
  handleSubmitOptions1 = async (e) => {
    e.preventDefault();
    this.setState({
      showLoadingOptions1: true,
    });

    const dataInput = {
      id_variant: this.state.idvariant1,
      options: this.state.options,
    };

    try {
      let res = {};
      if (this.state.isUpdateFormOption1) {
        res = await axiosCustom.put(
          "product-variants-options/" + this.state.idOptions1,
          dataInput
        );
      } else {
        res = await axiosCustom.post("product-variants-options", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoadingOptions1: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdataOptions1(this.state.idvariant1);

        this.toggleModal("showModalAddOptions1");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingOptions1: false,
      });
    }
  };

  saveVariantSecond = async () => {
    this.setState({
      showLoadingVariantSecond: true,
    });

    const dataInput = {
      uid: this.state.uid,
      variant_second: this.state.variant_second,
    };

    try {
      let res = {};
      if (this.state.isUpdateFormVariantSecond) {
        res = await axiosCustom.put(
          "product-variants-second/" + this.state.idvariantsecond,
          dataInput
        );
      } else {
        res = await axiosCustom.post("product-variants-second", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoadingVariantSecond: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdatavariantSecond(this.state.uid);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingVariantSecond: false,
      });
    }
  };
  handleSubmitOptionSecond = async (e) => {
    e.preventDefault();
    this.setState({
      showLoadingOptionsecond: true,
    });

    const dataInput = {
      id_variant_second: this.state.idvariantsecond,
      options_second: this.state.options_second,
    };

    try {
      let res = {};
      if (this.state.isUpdateFormOptionsecond) {
        res = await axiosCustom.put(
          "product-variants-options-second/" + this.state.idOptionSecond,
          dataInput
        );
      } else {
        res = await axiosCustom.post(
          "product-variants-options-second",
          dataInput
        );
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoadingOptionsecond: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdataOptionSecond(this.state.idvariantsecond);

        this.toggleModal("showModalAddOptionSecond");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingOptionsecond: false,
      });
    }
  };

  handleNext = () => {
    // setTimeout(() => {
    //   this.props.handleChangeTabActive("form-upload");
    // }, 200);
    this.props.handleChangeTabActive("form-variantstockproduct");
  };

  handleSaveFinish = () => {
    localStorage.setItem("successSubmit", true);
    setTimeout(() => {
      this.props.history.push({
        pathname: "/admin/product",
      });
    }, 200);
  };

  openModalOptions1 = () => {
    this.toggleModal("showModalAddOptions1");
    this.setState({
      isUpdateFormOption1: false,
      idOptions1: "",
      options: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  editDataOption1 = (record) => {
    this.toggleModal("showModalAddOptions1");
    this.setState({
      isUpdateFormOption1: true,
      idOptions1: record.id,
      options: record.options,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };

  processDeleteOptions1 = async (id) => {
    this.setState({
      showLoadingTableOptions1: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("product-variants-options/" + id);
      // console.log(res);

      if (res.status === 200) {
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdataOptions1(this.state.idvariant1);
        this.setState({
          showLoadingTableOptions1: false,
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableOptions1: false,
      });
    }
  };

  openModalOptionSecond = () => {
    this.toggleModal("showModalAddOptionSecond");
    this.setState({
      isUpdateFormOptionsecond: false,
      idOptionSecond: "",
      options_second: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  editDataOptionSecond = (record) => {
    this.toggleModal("showModalAddOptionSecond");
    this.setState({
      isUpdateFormOptionsecond: true,
      idOptionSecond: record.id,
      options_second: record.options_second,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };

  processDeleteOptionsSecond = async (id) => {
    this.setState({
      showLoadingTableOptionSecond: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("product-variants-options-second/" + id);
      // console.log(res);

      if (res.status === 200) {
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdataOptionSecond(this.state.idvariantsecond);
        this.setState({
          showLoadingTableOptionSecond: false,
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableOptionSecond: false,
      });
    }
  };

  DeleteVariantFirst = () => {
    this.toggleModal("showDeleteModalVariantFirst");
    // console.log(this.state.idvariant1, this.state.variant);
    // console.log(this.state.variant);
  };
  DeleteVariantSecond = () => {
    this.toggleModal("showDeleteModalVariantSecond");
    // console.log(this.state.idvariantsecond, this.state.variant_second);
    // console.log(this.state.variant);
  };

  processDeleteVariantFirst = async () => {
    this.setState({
      showLoadingVariantFirst: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete(
        "product-variants/" + this.state.idvariant1
      );
      // console.log(res);

      if (res.status === 200) {
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModalVariantFirst");
        this.getdatavariantFirst(this.state.uid);
        this.setState({
          showLoadingVariantFirst: false,
          dataOptions: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingVariantFirst: false,
      });
    }
  };

  processDeleteVariantSecond = async () => {
    this.setState({
      showLoadingVariantSecond: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete(
        "product-variants-second/" + this.state.idvariantsecond
      );
      // console.log(res);

      if (res.status === 200) {
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModalVariantSecond");
        this.getdatavariantSecond(this.state.uid);
        this.setState({
          showLoadingVariantSecond: false,
          dataOptionSecond: [],
        });
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingVariantSecond: false,
      });
    }
  };

  processDisableProduct = async () => {
    this.setState({
      showLoadingDisable: true,
    });
    try {
      let res = {};
      res = await axiosCustom.post("product-change-status/" + this.state.uid, {
        status_posting: "N",
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoadingDisable: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showModalConfirmDisable");
        this.saveVariantFirst();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingDisable: false,
      });
    }
  };

  render() {
    const {
      dataOptions,
      showLoadingTableOptions1,
      columnsOptions,
      dataOptionSecond,
      showLoadingTableOptionSecond,
      columnsOptionSecond,
    } = this.state;
    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">
                  Variant Options - {this.state.produkname}
                </h3>
              </CardHeader>
              <CardBody>
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Variant 1 <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.variant}
                                onChange={(e) =>
                                  this.setState({ variant: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <Button
                                color="primary"
                                onClick={() => this.saveVariantFirst()}
                                disabled={this.state.showLoadingVariantFirst}
                                style={{ width: "100%" }}
                              >
                                {this.state.showLoadingVariantFirst ? (
                                  <div>
                                    <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                    Executing
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Search Filter */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <Table
                            className="table-hover"
                            pagination={false}
                            style={{ overflowX: "auto" }}
                            columns={columnsOptions}
                            bordered
                            dataSource={dataOptions}
                            rowKey={(record) => record.id}
                            loading={showLoadingTableOptions1}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-sm-12 col-md-12">
                        {/* <div className="float-right"> */}
                        <Button
                          color="primary"
                          onClick={() => this.openModalOptions1()}
                          disabled={this.state.idvariant1 === ""}
                        >
                          + Add Option
                        </Button>
                        <Button
                          color="primary"
                          onClick={() =>
                            this.getdatavariantFirst(this.state.uid)
                          }
                        >
                          Refresh
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => this.DeleteVariantFirst()}
                          disabled={this.state.idvariant1 === ""}
                        >
                          Delete Variant 1
                        </Button>
                        {/* </div> */}
                      </div>
                      {/* <div className="col-sm-6 col-md-6"></div> */}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Variant 2 <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-md-9">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.variant_second}
                                onChange={(e) =>
                                  this.setState({
                                    variant_second: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <Button
                                color="primary"
                                onClick={() => this.saveVariantSecond()}
                                disabled={this.state.showLoadingVariantSecond}
                                style={{ width: "100%" }}
                              >
                                {this.state.showLoadingVariantSecond ? (
                                  <div>
                                    <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                    Executing
                                  </div>
                                ) : (
                                  "Save"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Search Filter */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive">
                          <Table
                            className="table-hover"
                            pagination={false}
                            style={{ overflowX: "auto" }}
                            columns={columnsOptionSecond}
                            bordered
                            dataSource={dataOptionSecond}
                            rowKey={(record) => record.id}
                            loading={showLoadingTableOptionSecond}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-sm-12 col-md-12">
                        {/* <div className="float-right"> */}
                        <Button
                          color="primary"
                          onClick={() => this.openModalOptionSecond()}
                          disabled={this.state.idvariantsecond === ""}
                        >
                          + Add Option
                        </Button>
                        <Button
                          color="primary"
                          onClick={() =>
                            this.getdatavariantSecond(this.state.uid)
                          }
                        >
                          Refresh
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => this.DeleteVariantSecond()}
                          disabled={this.state.idvariantsecond === ""}
                        >
                          Delete Variant 2
                        </Button>
                        {/* </div> */}
                      </div>
                      {/* <div className="col-sm-6 col-md-6"></div> */}
                    </div>
                  </div>
                </div>

                {!this.state.isUpdate ? (
                  <div className="row mt-6">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => this.handleNext()}
                          >
                            Next to variant stock product
                          </Button>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => this.handleSaveFinish()}
                          >
                            Finish
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </div>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showModalAddOptions1}
            toggle={() => this.toggleModal("showModalAddOptions1")}
          >
            <form onSubmit={this.handleSubmitOptions1}>
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Form Options Variant 1
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalAddOptions1")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Options <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.options}
                        onChange={(e) =>
                          this.setState({ options: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.showLoadingOptions1}
                >
                  {this.state.showLoadingOptions1 ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </form>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showModalAddOptionSecond}
            toggle={() => this.toggleModal("showModalAddOptionSecond")}
          >
            <form onSubmit={this.handleSubmitOptionSecond}>
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Form Options Variant 2
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalAddOptionSecond")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Options <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.options_second}
                        onChange={(e) =>
                          this.setState({ options_second: e.target.value })
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.showLoadingOptionsecond}
                >
                  {this.state.showLoadingOptionsecond ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </form>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showDeleteModalVariantFirst}
            toggle={() => this.toggleModal("showDeleteModalVariantFirst")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Delete data
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("showDeleteModalVariantFirst")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.idvariantsecond === ""
                ? [
                    <p>
                      Are you sure you want to delete this variant "
                      {this.state.variant}"?
                    </p>,
                    <br />,
                    <strong className="text-danger">
                      Warning: if you delete this variant, the stock on this
                      product will be deleted too
                    </strong>,
                  ]
                : [
                    <strong className="text-danger">
                      Warning: You can't delete this variant, there is still a
                      second variant.
                    </strong>,
                    <br></br>,
                    <strong className="text-danger">
                      Please delete second variant
                    </strong>,
                  ]}
            </div>
            <div className="modal-footer">
              {this.state.idvariantsecond === "" ? (
                this.state.showLoadingVariantFirst ? (
                  <Button
                    color="primary"
                    type="button"
                    disabled={true}
                    // onClick={() => this.processDeleteVariantFirst()}
                  >
                    <i className="fas fa-circle-notch fa-spin"></i> Executing
                    your request
                  </Button>
                ) : (
                  [
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={() =>
                        this.toggleModal("showDeleteModalVariantFirst")
                      }
                    >
                      Close
                    </Button>,
                    <Button
                      color="primary"
                      type="button"
                      // disabled={this.state.showLoadingVariantFirst}
                      onClick={() => this.processDeleteVariantFirst()}
                    >
                      Delete
                    </Button>,
                  ]
                )
              ) : null}
            </div>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showDeleteModalVariantSecond}
            toggle={() => this.toggleModal("showDeleteModalVariantSecond")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Delete data
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("showDeleteModalVariantSecond")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this variant "
                {this.state.variant_second}"?
              </p>
              <br />
              <strong className="text-danger">
                Warning: if you delete this variant, the stock on this product
                will be deleted too
              </strong>
            </div>
            <div className="modal-footer">
              {this.state.showLoadingVariantSecond ? (
                <Button
                  color="primary"
                  type="button"
                  disabled={true}
                  // onClick={() => this.processDeleteVariantSecond()}
                >
                  <i className="fas fa-circle-notch fa-spin"></i> Executing your
                  request
                </Button>
              ) : (
                [
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("showDeleteModalVariantSecond")
                    }
                  >
                    Close
                  </Button>,
                  <Button
                    color="primary"
                    type="button"
                    // disabled={this.state.showLoadingVariantSecond}
                    onClick={() => this.processDeleteVariantSecond()}
                  >
                    Delete
                  </Button>,
                ]
              )}
            </div>
          </Modal>
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showModalConfirmDisable}
            toggle={() => this.toggleModal("showModalConfirmDisable")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Confirmation
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("showModalConfirmDisable")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <h4 className="text-danger">{this.state.messageErrorVariant}</h4>
              <br />
              {/* <strong className="text-danger">
                Warning: if you delete this variant, the stock on this product
                will be deleted too
              </strong> */}
            </div>
            <div className="modal-footer">
              {this.state.showLoadingDisable ? (
                <Button
                  color="primary"
                  type="button"
                  disabled={true}
                  // onClick={() => this.processDeleteVariantSecond()}
                >
                  <i className="fas fa-circle-notch fa-spin"></i> Executing your
                  request
                </Button>
              ) : (
                [
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalConfirmDisable")}
                  >
                    Close
                  </Button>,
                  <Button
                    color="primary"
                    type="button"
                    // disabled={this.state.showLoadingVariantSecond}
                    onClick={() => this.processDisableProduct()}
                  >
                    Change to unposted
                  </Button>,
                ]
              )}
            </div>
          </Modal>
        </Row>
        {/* <ToastContainer /> */}
      </>
    );
  }
}
