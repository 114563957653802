import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import axiosCustom from "../../http/axios";
// import axios from 'axios';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showLoading: false,
      typePassword: "password",
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("tokenExpired")) {
      toast.error("Your token has expired, please login again", {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.removeItem("tokenExpired");
    }
  };

  handleLogin = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    try {
      const res = await axiosCustom.post("login", {
        username: this.state.email,
        userpass: this.state.password,
      });
      // console.log(res);

      if (res.status === 200) {
        var result = res.data.result;
        this.setState({
          showLoading: false,
        });
        if (result.usertype === "1") {
          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });

          localStorage.setItem("token", result.token);
          localStorage.setItem("firstlogin", true);
          delete result["token"];
          localStorage.setItem("userInfo", JSON.stringify(result));

          setTimeout(() => {
            window.location.href = "/admin/dashboard";
          }, 1000);
        } else {
          toast.error("Access Forbidden", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Sign In - Administrator Bikers Dream</title>
          <meta name="description" content="Login page" />
        </Helmet>
        {/* <AuthNavbar /> */}
        <div className="header bg-gradient-bikers py-7 py-lg-8">
          {/* <div className="header bg-gradient-info py-7 py-lg-8"> */}
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                  <h4 className="text-lead text-light">
                    Login system administrator
                  </h4>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0 box-card-custom">
                {/* <CardHeader className="bg-transparent">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in</small>
            </div>
          </CardHeader> */}
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={this.handleLogin}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type={this.state.typePassword}
                          autoComplete="new-password"
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          required
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            {this.state.typePassword === "password" ? (
                              <i
                                class="fa fa-eye"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.setState({ typePassword: "text" })
                                }
                              ></i>
                            ) : (
                              <i
                                class="fa fa-eye-slash"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.setState({ typePassword: "password" })
                                }
                              ></i>
                            )}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                    <div className="text-left">
                      {/* <a
                className="text-muted"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <small>Forgot password?</small>
              </a> */}
                      <Link to={"/auth/forgot-password"}>
                        <small>Forgot password?</small>
                      </Link>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        {this.state.showLoading ? (
                          <div>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Loading
                          </div>
                        ) : (
                          "Sign In"
                        )}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </>
    );
  }
}
