import Categories from "views/pages/categories/Categories";
import Dashboard from "views/pages/Dashboard";
import ChangePassword from "./pages/ChangePassword";
import BankInfo from "./pages/information/BankInfo";
import Banner from "./pages/information/Banner";
import ContactInfo from "./pages/information/ContactInfo";
import GeneralInfo from "./pages/information/GeneralInfo";
import Artikel from "./pages/information/Artikel";
import GeneralSetting from "./pages/information/GeneralSetting";
import LinkTree from "./pages/information/LinkTree";
import ServiceInfo from "./pages/information/ServiceInfo";
import Transaction from "./pages/information/Transaction";
import FormProduct from "./pages/product/FormProduct";
import FormProductDetail from "./pages/product/FormProductDetail";
import FormUploadProduct from "./pages/product/FormUploadProduct";
import Product from "./pages/product/Product";
import UserManagement from "./pages/user-management/UserManagement";
import VideoMaster from "./pages/information/VideoMaster";
import MediaMaster from "./pages/information/MediaMaster";

var listallroutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-bullet-list-67 text-red",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/change-password",
    name: "Change Password",
    icon: "ni ni-bullet-list-67 text-red",
    component: ChangePassword,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Product",
    icon: "ni ni-bullet-list-67 text-red",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/form-product",
    name: "Form Product",
    icon: "ni ni-bullet-list-67 text-red",
    component: FormProduct,
    layout: "/admin",
  },
  {
    path: "/form-upload-product",
    name: "Form Upload Product",
    icon: "ni ni-bullet-list-67 text-red",
    component: FormUploadProduct,
    layout: "/admin",
  },
  {
    path: "/form-product-detail/:tabid/:uid",
    name: "Form Product Detail",
    icon: "ni ni-bullet-list-67 text-red",
    component: FormProductDetail,
    layout: "/admin",
  },
  // {
  //   path: "/form-product-detail",
  //   name: "Form Product Detail",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: FormProductDetail,
  //   layout: "/admin",
  // },
  {
    path: "/information",
    name: "Information",
    icon: "ni ni-collection text-red",
    component: GeneralInfo,
    layout: "/admin",
  },
  {
    path: "/service",
    name: "Service Information",
    icon: "ni ni-delivery-fast text-blue",
    component: ServiceInfo,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contact Information",
    icon: "ni ni-badge text-green",
    component: ContactInfo,
    layout: "/admin",
  },
  {
    path: "/banner",
    name: "Banner",
    icon: "ni ni-album-2 text-green",
    component: Banner,
    layout: "/admin",
  },
  {
    path: "/bank-info",
    name: "Bank Information",
    icon: "ni ni-building text-yellow",
    component: BankInfo,
    layout: "/admin",
  },
  {
    path: "/link-tree",
    name: "Linktree Information",
    icon: "ni ni-world-2 text-blue",
    component: LinkTree,
    layout: "/admin",
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "ni ni-user-run text-red",
    component: UserManagement,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Transaction",
    icon: "ni ni-cart text-blue",
    component: Transaction,
    layout: "/admin",
  },
  {
    path: "/artikel",
    name: "Artikel",
    icon: "ni ni-laptop text-yellow",
    component: Artikel,
    layout: "/admin",
  },
  {
    path: "/video",
    name: "Video",
    icon: "ni ni-folder-17 text-red",
    component: VideoMaster,
    layout: "/admin",
  },
  {
    path: "/media",
    name: "Media",
    icon: "ni ni-button-play text-blue",
    component: MediaMaster,
    layout: "/admin",
  },
  {
    path: "/general-setting",
    name: "General Setting",
    icon: "ni ni-settings text-green",
    component: GeneralSetting,
    layout: "/admin",
  },

  // {
  //   path: "/auth/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // // {
  // //   path: "/auth/forgot-password",
  // //   name: "Forgot Password",
  // //   icon: "ni ni-key-25 text-info",
  // //   component: ForgotPassword,
  // //   // layout: "/auth",
  // // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default listallroutes;
