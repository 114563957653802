/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from "react";
import { Row, Card, CardBody, Alert, CardHeader, Button } from "reactstrap";
import {
  Upload,
  Modal,
  // Progress
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axiosCustom from "http/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default class FormUploadProduct extends Component {
  state = {
    previewVisible: false,
    showLoadingURL: false,
    isUpdate: false,
    previewImage: "",
    uid: "",
    produkname: "",
    previewTitle: "",
    url_video: "",
    fileList: [],
    fileListThumbnail: [],
    fileListVideo: [],
    messageAlert: "",
    percent: 0,
    // fileList: [
    //   {
    //     uid: "-1",
    //     name: "image.png",
    //     status: "done",
    //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   },
    //   {
    //     uid: "-2",
    //     name: "image.png",
    //     status: "done",
    //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   },
    //   {
    //     uid: "-3",
    //     name: "image.png",
    //     status: "done",
    //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   },
    //   {
    //     uid: "-4",
    //     name: "image.png",
    //     status: "done",
    //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   },
    //   {
    //     uid: "-xxx",
    //     percent: 50,
    //     name: "image.png",
    //     status: "uploading",
    //     url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    //   },
    //   {
    //     uid: "-5",
    //     name: "image.png",
    //     status: "error",
    //   },
    // ],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handleRemove = async (file, urlVideo = false) => {
    // console.log(file);
    // return new Promise((resolve, reject) => {
    //   resolve(true)
    //  })

    this.setState({
      showLoading: true,
      showLoadingURL: true,
      messageAlert: "Removing",
    });
    try {
      let res = {};
      res = await axiosCustom.delete("product-images/" + file.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
          showLoadingURL: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (urlVideo) {
          this.setState({
            fileListVideo: [],
            url_video: "",
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
        showLoadingURL: false,
      });
    }
  };

  componentDidMount = () => {
    console.log(this.props.record);
    if (this.props.uid || this.props.record) {
      // this.editData(this.props.uid);
      if (this.props.isUpdate === false) {
        console.log("componentdidmount isupdate false");
        this.getProductDetail(this.props.record.uid);
      } else {
        console.log("componentdidmount isupdate true");
        this.getProductDetail(this.props.uid);
      }
    }
  };
  componentDidUpdate(prevProps) {
    // console.log(prevProps.record);
    if (
      this.props.uid !== prevProps.uid ||
      this.props.record !== prevProps.record
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      if (this.props.isUpdate === false) {
        console.log("componentDidUpdate isupdate false");
        this.getProductDetail(this.props.record.uid);
      } else {
        console.log("componentDidUpdate isupdate true");
        this.getProductDetail(this.props.uid);
      }
    }
  }

  getProductDetail = async (uid) => {
    try {
      const res = await axiosCustom.get("product/" + uid);
      if (res.status === 200) {
        var data = res.data.result;
        this.editData(data);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getImageList = async (uid) => {
    this.setState({
      showLoading: true,
      messageAlert: "Loading image",
    });

    try {
      const res = await axiosCustom.get("product/images/" + uid);
      if (res.status === 200) {
        var dt = res.data.result.sort(function (a, b) {
          return moment(b.updated_at).unix() - moment(a.updated_at).unix();
        });

        var fileList = dt
          .filter((x) => x.thumb === "N" && x.url_video === null)
          .map((item) => {
            return {
              id: item.id,
              image_name: item.image_name,
              url: item.url,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          });
        var fileListThumbnail = dt
          .filter((x) => x.thumb === "Y")
          .map((item) => {
            return {
              id: item.id,
              image_name: item.image_name,
              url: item.url,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          });
        var fileListVideo = dt
          .filter((x) => x.thumb === "N" && x.url_video !== null)
          .map((item) => {
            return {
              id: item.id,
              image_name: item.image_name,
              url_video: item.url_video,
              url: item.url,
              created_at: item.created_at,
              updated_at: item.updated_at,
            };
          });
        // console.log(fileListVideo);
        this.setState({
          fileList,
          fileListVideo,
          fileListThumbnail,
        });
      } else {
        this.setState({
          fileList: [],
          fileListThumbnail: [],
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
        fileList: [],
        fileListThumbnail: [],
      });
    }
  };

  // editData = (uid) => {
  //   this.getProductDetail(uid);
  //   this.getImageList(uid);
  //   this.setState({
  //     uid: uid,
  //     // produkname: record.produkname,
  //     // isUpdate: record.isUpdate,
  //   });
  // };
  editData = (record) => {
    this.getImageList(record.uid);
    this.setState({
      uid: record.uid,
      produkname: record.produkname,
      isUpdate: this.props.isUpdate,
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });
  handleChangeThumbnail = ({ fileList }) =>
    this.setState({ fileListThumbnail: fileList });

  handleUploadFile = (options, type, thumb = false) => {
    const { onSuccess, onError, file, onProgress } = options;
    const dataInput = new FormData();

    dataInput.append("uid", this.state.uid);
    if (thumb) {
      dataInput.append("thumb", "Y");
    } else {
      dataInput.append("thumb", "N");
    }

    if (type === "image") {
      dataInput.append("image_product", file);
    } else {
      dataInput.append("url_video", this.state.url_video);
    }

    axiosCustom
      .post("product-images", dataInput, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          const percent = Math.floor((event.loaded / event.total) * 100);
          this.setState({
            percent,
          });
          if (percent === 100) {
            setTimeout(() => this.setState({ percent: 0 }), 1000);
          }
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      })
      .then((res) => {
        // const result = res.data.result;
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        onSuccess("Uploaded");
        this.getImageList(this.state.uid);
      })
      .catch((err) => {
        // console.log(error);
        onError(err);
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  getURLembed = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);
    // console.log(match && match[2].length === 11 ? match[2] : null);
    var idURL = match && match[2].length === 11 ? match[2] : null;
    // var newURL = `https://www.youtube.com/embed/${idURL}`;

    return idURL;
  };

  handleAddVideo = () => {
    var getID_url = this.getURLembed(this.state.url_video);
    if (getID_url) {
      this.setState({
        showLoadingURL: true,
      });
      const dataInput = new FormData();
      var idURL = getID_url;
      var embed = `https://www.youtube.com/embed/${idURL}`;

      dataInput.append("uid", this.state.uid);
      dataInput.append("thumb", "N");
      dataInput.append("url_video", embed);

      axiosCustom
        .post("product-images", dataInput, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // const result = res.data.result;
          this.setState({
            showLoadingURL: false,
            url_video: "",
          });
          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.getImageList(this.state.uid);
        })
        .catch((err) => {
          // console.log(error);
          this.setState({
            showLoadingURL: false,
          });
          var msg = err ? err.response.data.messages : "Error invalid";
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      toast.error("URL link unknown", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  handleNext = () => {
    // setTimeout(() => {
    //   this.props.handleChangeTabActive("form-upload");
    // }, 200);
    this.props.handleChangeTabActive("form-variantoption");
  };

  handleSaveFinish = () => {
    localStorage.setItem("successSubmit", true);
    setTimeout(() => {
      this.props.history.push({
        pathname: "/admin/product",
      });
    }, 200);
  };

  render() {
    const {
      previewVisible,
      previewImage,
      fileList,
      fileListThumbnail,
      previewTitle,
    } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0 card-title">
                  Product - {this.state.produkname}
                  <div className="float-right">
                    <Button
                      color="primary"
                      onClick={() => this.getImageList(this.state.uid)}
                    >
                      Refresh
                    </Button>
                  </div>
                </h3>
              </CardHeader>
              <CardBody>
                {/* <div className="row">
                  <div className="col-sm-6 col-md-9">
                   
                  </div>
                  <div className="col-sm-6 col-md-3">
                   
                  </div>
                </div> */}
                {this.state.showLoading ? (
                  <div className="row">
                    <div className="col-md-12">
                      <Alert
                        color="info"
                        isOpen={this.state.showLoading}
                        toggle={() =>
                          this.setState({
                            showLoading: false,
                          })
                        }
                      >
                        <strong>
                          <i className="fas fa-spinner fa-spin"></i>
                        </strong>{" "}
                        {this.state.messageAlert}
                      </Alert>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <span className="text-danger">
                        <i>(Note: recommendation 270x360)</i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label>Thumbnail (Main Image)</label>
                    <Upload
                      customRequest={(options) =>
                        this.handleUploadFile(options, "image", true)
                      }
                      listType="picture-card"
                      fileList={fileListThumbnail}
                      onPreview={this.handlePreview}
                      onChange={this.handleChangeThumbnail}
                      onRemove={(file) => this.handleRemove(file)}
                      accept="image/*"
                      multiple={false}
                    >
                      {fileListThumbnail.length >= 1 ? null : uploadButton}
                      {/* {
                          uploadButton
                        } */}
                    </Upload>
                    {/* {this.state.showLoading ? (
                        <Progress strokeLinecap="square" percent={this.state.percent} />
                        ) : null } */}
                  </div>
                  <div className="col-md-12">
                    <label>List Image</label>
                    <Upload
                      customRequest={(options) =>
                        this.handleUploadFile(options, "image")
                      }
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      onRemove={(file) => this.handleRemove(file)}
                      accept="image/*"
                      multiple={true}
                    >
                      {fileList.length >= 8 ? null : uploadButton}
                      {/* {
                          uploadButton
                        } */}
                    </Upload>
                    {/* {this.state.showLoading ? (
                        <Progress strokeLinecap="square" percent={this.state.percent} />
                        ) : null } */}
                  </div>
                  <div className="col-md-12">
                    <label>
                      URL Video{" "}
                      <span className="text-danger">
                        <i>(Note: source youtube)</i>
                      </span>
                    </label>
                    <br></br>
                    {this.state.fileListVideo.length ? (
                      <>
                        <div className="row">
                          <div className="col-md-4">
                            <iframe
                              width={"100%"}
                              height="315"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              src={this.state.fileListVideo[0].url_video}
                            ></iframe>
                          </div>
                          <div className="col-md-8">
                            <Button
                              color="primary"
                              type="button"
                              disabled={this.state.showLoadingURL}
                              onClick={() =>
                                this.handleRemove(
                                  this.state.fileListVideo[0],
                                  true
                                )
                              }
                            >
                              {this.state.showLoadingURL ? (
                                <div>
                                  <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                  Executing your request
                                </div>
                              ) : (
                                "Delete URL"
                              )}
                            </Button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.url_video}
                            onChange={(e) =>
                              this.setState({ url_video: e.target.value })
                            }
                            required
                            disabled={this.state.showLoadingURL}
                          />
                        </div>
                        <div className="col-md-6">
                          <Button
                            color="primary"
                            type="button"
                            disabled={
                              this.state.url_video === "" ||
                              this.state.showLoadingURL
                            }
                            onClick={() => this.handleAddVideo()}
                          >
                            {this.state.showLoadingURL ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                Executing your request
                              </div>
                            ) : (
                              "Add URL Video"
                            )}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                  <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                  >
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  </Modal>
                  {!this.state.isUpdate ? (
                    <div className="col-md-12">
                      <div>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => this.handleNext()}
                        >
                          Next to variant options
                        </Button>
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => this.handleSaveFinish()}
                        >
                          Finish
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </div>
        </Row>
        {/* <ToastContainer /> */}
      </>
    );
  }
}
