/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Container,
  Alert,
  Badge,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
} from "reactstrap";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import { Helmet } from "react-helmet";
import Header from "components/Headers/Header";
import { renderStatus } from "helper/helper";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Switch } from "antd";
// import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showAddressModal: false,
      showAddressAddModal: false,
      showAddressDeleteModal: false,
      showAddressDefaultModal: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      dataAddress: [],
      filteredDataAddress: [],
      filteredData: [],
      value: "",
      valueAddress: "",
      showLoadingTable: false,
      showLoadingTableAddress: false,
      showLoading: false,
      typeNewPass: "password",
      typeconfpassword: "password",
      genderOption: [
        {
          value: "1",
          label: "Male",
        },
        {
          value: "2",
          label: "Female",
        },
      ],
      userTypeOption: [
        {
          value: "1",
          label: "Admin",
        },
        {
          value: "2",
          label: "User",
        },
      ],
      columns: [
        {
          title: "Email",
          dataIndex: "useremail",
          sorter: (a, b) => a.useremail.localeCompare(b.useremail),
        },
        {
          title: "Name",
          dataIndex: "fullname",
          sorter: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
          title: "Gender",
          dataIndex: "gender",
          sorter: (a, b) => a.gender.localeCompare(b.gender),
          render: (text, record) => (
            <span>{text === "1" ? "Male" : "Female"}</span>
          ),
        },
        {
          title: "Contact",
          dataIndex: "contact",
          sorter: (a, b) => a.contact.localeCompare(b.contact),
        },
        {
          title: "Type",
          dataIndex: "usertype",
          sorter: (a, b) => a.usertype.localeCompare(b.usertype),
          render: (text, record) => (
            // <span>{text === "1" ? renderStatus("Y") : renderStatus("N")}</span>
            <span>
              {text === "1" ? (
                <Badge color="danger" pill>
                  Admin
                </Badge>
              ) : (
                <Badge color="success" pill>
                  User
                </Badge>
              )}
            </span>
          ),
        },

        {
          title: "Action",
          align: "center",
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                {record.usertype === "2" ? (
                  <DropdownItem onClick={() => this.getAddressUser(record)}>
                    <i className="fa fa-tasks" />
                    <span>Customer Address</span>
                  </DropdownItem>
                ) : null}
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],
      columnsAddress: [
        {
          title: "Address",
          dataIndex: "address",
          ellipsis: true,
          sorter: (a, b) => a.address.localeCompare(b.address),
        },
        {
          title: "Receiver",
          dataIndex: "receiver",
          sorter: (a, b) => a.receiver.localeCompare(b.receiver),
        },
        {
          title: "Phone",
          dataIndex: "phone",
          sorter: (a, b) => a.phone.localeCompare(b.phone),
        },
        // {
        //   title: "Kelurahan",
        //   dataIndex: "kelurahan",
        //   sorter: (a, b) => a.kelurahan.localeCompare(b.kelurahan),
        // },
        // {
        //   title: "Kecamatan",
        //   dataIndex: "kecamatan",
        //   sorter: (a, b) => a.kecamatan.localeCompare(b.kecamatan),
        // },
        {
          title: "City",
          dataIndex: "city_name",
          sorter: (a, b) => a.city_name.localeCompare(b.city_name),
        },
        {
          title: "Province",
          dataIndex: "province",
          sorter: (a, b) => a.province.localeCompare(b.province),
        },
        {
          title: "Default",
          dataIndex: "default",
          sorter: (a, b) => a.default.localeCompare(b.default),
          render: (text, record) => <span>{renderStatus(text)}</span>,
        },
        {
          title: "Action",
          align: "center",
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editDataAddress(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                {record.default === "N" ? (
                  <DropdownItem onClick={() => this.setDefaultAddress(record)}>
                    <i className="ni ni-check-bold" />
                    <span>Change to default address</span>
                  </DropdownItem>
                ) : null}
                <DropdownItem onClick={() => this.deleteDataAddress(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      usertype: "2",
      fullname: "",
      gender: "1",
      email: "",
      userpass: "",
      confpassword: "",
      contact: "",
      created_at: new Date(),
      updated_at: new Date(),
      isRequired: true,
      isError: false,
      errorMessage: "",

      id_address: "",
      id_user: "",
      id_province: "",
      id_kabupaten: "",
      district: "",
      id_kelurahan: "",
      receiver: "",
      address: "",
      default: "N",
      zip_code: "",
      phone: "",

      provinceOption: [],
      cityOption: [],
      districtOption: [],
      urbanVillageOption: [],

      showLoadingProvince: false,
      showLoadingCity: false,
      showLoadingDistrict: false,
      showLoadingUrbanVillage: false,
    };
  }

  componentDidMount = () => {
    this.getdata();
    this.getProvince();
  };

  getAddressUser = (record) => {
    this.setState({
      id_user: record.id,
      fullname: record.fullname,
    });
    this.toggleModal("showAddressModal");
    this.getAddressUserSync(record.id);
  };

  getAddressUserSync = async (id) => {
    this.setState({
      showLoadingTableAddress: true,
      valueAddress: "",
    });

    try {
      const res = await axiosCustom.get("address-options/" + id);
      if (res.status === 200) {
        if (res.data.result) {
          var dataAddress = []
          var dt = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });

          if (dt.length) {
            // console.log('dt', dt);
            dataAddress = dt.map((item) => {
              return {
                id: item.id,
                id_user: item.id_user,
                address: item.address,
                receiver: item.receiver,
                zip_code: item.zip_code,
                default: item.default,
                phone: item.phone,
                city_id: item.raja_ongkir ? item.raja_ongkir.city_id : "",
                city_name: item.raja_ongkir ? item.raja_ongkir.city_name : "",
                province_id: item.raja_ongkir ? item.raja_ongkir.province_id : "",
                province: item.raja_ongkir ? item.raja_ongkir.province : ""
              }
            })
          }

          this.setState({
            dataAddress,
          });


      
			// "raja_ongkir": {
			// 	"city_id": "37",
			// 	"city_name": "Banjarnegara",
			// 	"province_id": "10",
			// 	"province": "Jawa Tengah"
			// }
          
        }
      }
      this.setState({
        showLoadingTableAddress: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableAddress: false,
        dataAddress: [],
      });
    }
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("users");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: [],
      });
    }
  };

  handleFilterAddress = (e) => {
    let valueAddress = e.target.value;
    let dataAddress = this.state.dataAddress;
    let filteredDataAddress = this.state.filteredDataAddress;
    this.setState({ valueAddress });

    if (valueAddress.length) {
      filteredDataAddress = dataAddress.filter((item) => {
        let startsWithCondition =
          String(item.address)
            .toLowerCase()
            .startsWith(valueAddress.toLowerCase()) ||
          String(item.receiver)
            .toLowerCase()
            .startsWith(valueAddress.toLowerCase()) ||
          String(item.phone)
            .toLowerCase()
            .startsWith(valueAddress.toLowerCase()) ||
          // String(item.kelurahan)
          //   .toLowerCase()
          //   .startsWith(valueAddress.toLowerCase()) ||
          // String(item.kecamatan)
          //   .toLowerCase()
          //   .startsWith(valueAddress.toLowerCase()) ||
          String(item.kabupaten)
            .toLowerCase()
            .startsWith(valueAddress.toLowerCase()) ||
          String(item.propinsi)
            .toLowerCase()
            .startsWith(valueAddress.toLowerCase());
        let includesCondition =
          String(item.address)
            .toLowerCase()
            .includes(valueAddress.toLowerCase()) ||
          String(item.receiver)
            .toLowerCase()
            .includes(valueAddress.toLowerCase()) ||
          String(item.phone)
            .toLowerCase()
            .includes(valueAddress.toLowerCase()) ||
          // String(item.kelurahan)
          //   .toLowerCase()
          //   .includes(valueAddress.toLowerCase()) ||
          // String(item.kecamatan)
          //   .toLowerCase()
          //   .includes(valueAddress.toLowerCase()) ||
          String(item.kabupaten)
            .toLowerCase()
            .includes(valueAddress.toLowerCase()) ||
          String(item.propinsi)
            .toLowerCase()
            .includes(valueAddress.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredDataAddress });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.usertype).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.fullname).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.gender).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.email).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.contact).toLowerCase().startsWith(value.toLowerCase());
        let includesCondition =
          String(item.usertype).toLowerCase().includes(value.toLowerCase()) ||
          String(item.fullname).toLowerCase().includes(value.toLowerCase()) ||
          String(item.gender).toLowerCase().includes(value.toLowerCase()) ||
          String(item.email).toLowerCase().includes(value.toLowerCase()) ||
          String(item.contact).toLowerCase().includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  handleChangeProvince = (e) => {
    if (e !== null) {
      this.setState({
        id_province: e.value,
      });
      this.getCity(e.value);
    } else {
      this.setState({
        id_province: "",
        id_kabupaten: "",
        district: "",
        id_kelurahan: "",
        cityOption: [],
        districtOption: [],
        urbanVillageOption: [],
      });
    }
  };
  handleChangeCity = (e) => {
    if (e !== null) {
      this.setState({
        id_kabupaten: e.value,
      });
      this.getDistrict(e.value);
    } else {
      this.setState({
        id_kabupaten: "",
        district: "",
        id_kelurahan: "",
        districtOption: [],
        urbanVillageOption: [],
      });
    }
  };
  handleChangeDistrict = (e) => {
    if (e !== null) {
      this.setState({
        district: e.value,
      });
      this.getUrbanVillage(e.value);
    } else {
      this.setState({
        district: "",
        id_kelurahan: "",
        urbanVillageOption: [],
      });
    }
  };
  handleChangeUrbanVillage = (e) => {
    if (e !== null) {
      this.setState({
        id_kelurahan: e.value,
      });
    } else {
      this.setState({
        id_kelurahan: "",
      });
    }
  };

  getProvince = async () => {
    this.setState({
      showLoadingProvince: true,
    });
    try {
      const res = await axiosCustom.get("ongkir/wilayah/province");
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var provinceOption = dt.map((item) => {
          return {
            value: item.province_id,
            label: item.province,
          };
        });

        this.setState({
          provinceOption,
        });
      } else {
        this.setState({
          provinceOption: [],
        });
      }
      this.setState({
        showLoadingProvince: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingProvince: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCity = async (id) => {
    this.setState({
      showLoadingCity: true,
    });
    try {
      const res = await axiosCustom.get("ongkir/wilayah/city/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var cityOption = dt.map((item) => {
          return {
            value: item.city_id,
            label: item.city_name,
            province_id: item.province_id,
            province: item.province,
            type: item.type,
            postal_code: item.postal_code,
          };
        });

        this.setState({
          cityOption,
        });
      } else {
        this.setState({
          cityOption: [],
        });
      }
      this.setState({
        showLoadingCity: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingCity: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  getDistrict = async (id) => {
    this.setState({
      showLoadingDistrict: true,
    });
    try {
      const res = await axiosCustom.get("kecamatan/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.result;
        var districtOption = dt.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        this.setState({
          districtOption,
        });
      } else {
        this.setState({
          districtOption: [],
        });
      }
      this.setState({
        showLoadingDistrict: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingDistrict: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  getUrbanVillage = async (id) => {
    this.setState({
      showLoadingUrbanVillage: true,
    });
    try {
      const res = await axiosCustom.get("kelurahan/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.result;
        var urbanVillageOption = dt.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });

        this.setState({
          urbanVillageOption,
        });
      } else {
        this.setState({
          urbanVillageOption: [],
        });
      }
      this.setState({
        showLoadingUrbanVillage: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingUrbanVillage: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      typeNewPass: "password",
      typeconfpassword: "password",
      id: "",
      usertype: "2",
      fullname: "",
      gender: "1",
      email: "",
      userpass: "",
      confpassword: "",
      contact: "",
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: true,
      id: record.id,
      usertype: record.usertype,
      typeNewPass: "password",
      typeconfpassword: "password",
      fullname: record.fullname,
      gender: record.gender,
      email: record.useremail,
      isError: false,
      errorMessage: "",
      isRequired: record.usertype === "1" ? false : true,
      // userpass: record.userpass,
      // confpassword: record.confpassword,
      contact: record.contact,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };

  openModalAddress = () => {
    this.toggleModal("showAddressAddModal");
    this.setState({
      isUpdateForm: false,
      id_address: "",
      address: "",
      id_province: "",
      id_kabupaten: "",
      district: "",
      id_kelurahan: "",
      receiver: "",
      default: "N",
      zip_code: "",
      phone: "",

      cityOption: [],
      districtOption: [],
      urbanVillageOption: [],
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
    });
  };

  editDataAddress = (record) => {
    this.toggleModal("showAddressAddModal");
    this.getCity(record.province_id);
    this.setState({
      isUpdateForm: true,
      id_address: record.id,
      address: record.address,
      id_province: record.province_id,
      id_kabupaten: record.city_id,
      // district: record.id_kecamatan,
      // id_kelurahan: record.id_kelurahan,
      receiver: record.receiver,
      default: record.default,
      zip_code: record.zip_code,
      phone: this.getNumberPhoneReverse(record.phone),
      isError: false,
      errorMessage: "",
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  setDefaultAddress = (record) => {
    this.toggleModal("showAddressDefaultModal");
    this.setState({
      id_address: record.id,
      address: record.address,
      isError: false,
      errorMessage: "",
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };

  deleteDataAddress = (record) => {
    this.toggleModal("showAddressDeleteModal");
    this.setState({
      id_address: record.id,
      id_user: record.id_user,
      address: record.address,
      // id_province: record.province_id,
      // id_kabupaten: record.id_kabupaten,
      // district: record.id_kecamatan,
      // id_kelurahan: record.id_kelurahan,
      // receiver: record.receiver,
      // default: record.default,
      // zip_code: record.zip_code,
      isError: false,
      errorMessage: "",
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      usertype: record.usertype,
      fullname: record.fullname,
      gender: record.gender,
      email: record.useremail,
      contact: record.contact,
      isError: false,
      errorMessage: "",
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  getNumberPhone = (numb) => {
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd !== "0") {
      xd = `0${numb}`;
    }
    return xd;
  };

  getNumberPhoneReverse = (numb) => {
    
    var xd = String(numb);
    var dd = xd.slice(0, 1);
    // console.log(dd);
    if (dd === "0") {
      xd = numb.substring(1);
    }
    return xd;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    if (this.state.isUpdateForm) {
      var UpdateInput = {
        usertype: this.state.usertype,
        fullname: this.state.fullname,
        gender: this.state.gender,
        email: this.state.email,
        contact: this.getNumberPhone(this.state.contact),
      };
      this.actionSubmit(UpdateInput);
    } else {
      if (this.state.confpassword !== this.state.userpass) {
        // toast.error('Account password must be same as confirm password, please try again', {
        //   position: toast.POSITION.TOP_RIGHT
        // });
        this.setState({
          showLoading: false,
          isError: true,
          errorMessage:
            "Confirm password must be same as account password, please try again",
        });
      } else {
        var dataInput = {
          // id: this.state.id,
          usertype: this.state.usertype,
          fullname: this.state.fullname,
          gender: this.state.gender,
          email: this.state.email,
          userpass: this.state.userpass,
          confpassword: this.state.confpassword,
          contact: this.getNumberPhone(this.state.contact),
        };
        this.actionSubmit(dataInput);
      }
    }
  };
  handleSubmitAddress = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    var dataInput = {
      id_user: this.state.id_user,
      address: this.state.address,
      id_province: this.state.id_province,
      id_kabupaten: this.state.id_kabupaten,
      // district: this.state.id_kecamatan,
      // id_kelurahan: this.state.id_kelurahan,
      receiver: this.state.receiver,
      default: this.state.default,
      zip_code: this.state.zip_code,
      phone: this.getNumberPhone(this.state.phone),
    };

    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axiosCustom.put(
          "address-options/" + this.state.id_address,
          dataInput
        );
      } else {
        res = await axiosCustom.post("address-options", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getAddressUserSync(this.state.id_user);

        this.toggleModal("showAddressAddModal");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  actionSubmit = async (dataInput) => {
    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axiosCustom.put("users/" + this.state.id, dataInput);
      } else {
        res = await axiosCustom.post("users", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdata();

        this.toggleModal("showModalAdd");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  processDeleteAddress = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete(
        "address-options/" + this.state.id_user + "/" + this.state.id_address
      );
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showAddressDeleteModal");
        this.getAddressUserSync(this.state.id_user);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDefaultAddress = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.get(
        "address-set-default/" +
          this.state.id_user +
          "/" +
          this.state.id_address
      );
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showAddressDefaultModal");
        this.getAddressUserSync(this.state.id_user);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete(
        "users/" + this.state.id + "/" + userInfo.id
      );
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Helmet>
          <title>User Management - Administrator Bikers Dream</title>
          <meta name="description" content="User Management page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAdd"
                          onClick={() => this.openModal()}
                        >
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showAddressAddModal}
              toggle={() => this.toggleModal("showAddressAddModal")}
            >
              <form onSubmit={this.handleSubmitAddress}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Form Customer Address
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showAddressAddModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Receiver Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.receiver}
                          onChange={(e) =>
                            this.setState({ receiver: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Phone <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.phone}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ phone: values.floatValue })
                          }
                          format="+62 (###) #### #### ####"
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Address <span className="text-danger">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          rows={3}
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Province <span className="text-danger">*</span>
                        </label>
                        <Select
                          isClearable={true}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) => this.handleChangeProvince(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     id_province: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.provinceOption}
                          value={this.state.provinceOption.filter(
                            (obj) => obj.value === this.state.id_province
                          )}
                          isDisabled={this.state.showLoadingProvince}
                          isLoading={this.state.showLoadingProvince}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_province}
                          required
                          onChange={(e) =>
                            this.setState({ id_province: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          City <span className="text-danger">*</span>
                        </label>
                        <Select
                          isClearable={true}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) => this.handleChangeCity(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     id_kabupaten: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.cityOption}
                          value={this.state.cityOption.filter(
                            (obj) => obj.value === this.state.id_kabupaten
                          )}
                          isDisabled={this.state.showLoadingCity}
                          isLoading={this.state.showLoadingCity}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_kabupaten}
                          required
                          onChange={(e) =>
                            this.setState({ id_kabupaten: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          District <span className="text-danger">*</span>
                        </label>
                        <Select
                          isClearable={true}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) => this.handleChangeDistrict(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     district: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.districtOption}
                          value={this.state.districtOption.filter(
                            (obj) => obj.value === this.state.district
                          )}
                          isDisabled={this.state.showLoadingDistrict}
                          isLoading={this.state.showLoadingDistrict}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.district}
                          required
                          onChange={(e) =>
                            this.setState({ district: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Village <span className="text-danger">*</span>
                        </label>
                        <Select
                          isClearable={true}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) => this.handleChangeUrbanVillage(e)}
                          // onChange={(e) =>
                          //   this.setState({
                          //     id_kelurahan: e !== null ? e.value : "",
                          //   })
                          // }
                          options={this.state.urbanVillageOption}
                          value={this.state.urbanVillageOption.filter(
                            (obj) => obj.value === this.state.id_kelurahan
                          )}
                          isDisabled={this.state.showLoadingUrbanVillage}
                          isLoading={this.state.showLoadingUrbanVillage}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_kelurahan}
                          required
                          onChange={(e) =>
                            this.setState({ id_kelurahan: e.target.value })
                          }
                        />
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Zip Code <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.zip_code}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ zip_code: values.floatValue })
                          }
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Default</label>
                        <br />
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.default === "N" ? false : true}
                          onChange={(checked) =>
                            this.setState({
                              default: checked === true ? "Y" : "N",
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isError ? (
                  // <div className="row">
                  <div className="col-md-12">
                    <Alert
                      color="danger"
                      isOpen={this.state.isError}
                      toggle={() =>
                        this.setState({
                          isError: false,
                        })
                      }
                    >
                      <strong>Failed!</strong> {this.state.errorMessage}
                    </Alert>
                  </div>
                ) : // </div>
                null}
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>
            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Form User
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalAdd")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          User Type <span className="text-danger">*</span>
                        </label>
                        <Select
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) =>
                            this.setState({
                              usertype: e !== null ? e.value : "",
                              isRequired: e.value === "1" ? false : true
                            })
                          }
                          options={this.state.userTypeOption}
                          value={this.state.userTypeOption.filter(
                            (obj) => obj.value === this.state.usertype
                          )}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.usertype}
                          required
                          onChange={(e) =>
                            this.setState({ usertype: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Fullname <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.fullname}
                          onChange={(e) =>
                            this.setState({ fullname: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    {!this.state.isUpdateForm ? (
                      <>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Account Password{" "}
                              <span className="text-danger">*</span>
                            </label>
                            {/* <InputGroup className="input-group-alternative">
                              <Input
                                // placeholder="Password"
                                className="form-control"
                                type={this.state.typeNewPass}
                                autoComplete="new-password"
                                value={this.state.userpass}
                                disabled={this.state.isUpdateForm}
                                onChange={(e) =>
                                  this.setState({ userpass: e.target.value })
                                }
                                required
                              />
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  {this.state.typeNewPass === "password" ? (
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({ typeNewPass: "text" })
                                      }
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.setState({
                                          typeNewPass: "password",
                                        })
                                      }
                                    ></i>
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup> */}
                            <div className="input-group">
                              <input
                                type={this.state.typeNewPass}
                                className="form-control"
                                value={this.state.userpass}
                                onChange={(e) =>
                                  this.setState({ userpass: e.target.value })
                                }
                                required
                                disabled={this.state.isUpdateForm}
                              />
                              <div className="input-group-append">
                                {this.state.typeNewPass === "password" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() =>
                                      this.setState({ typeNewPass: "text" })
                                    }
                                  >
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() =>
                                      this.setState({
                                        typeNewPass: "password",
                                      })
                                    }
                                  >
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Confirm Password{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <div className="input-group">
                              <input
                                type={this.state.typeconfpassword}
                                className="form-control"
                                value={this.state.confpassword}
                                onChange={(e) =>
                                  this.setState({
                                    confpassword: e.target.value,
                                  })
                                }
                                required
                                disabled={this.state.isUpdateForm}
                              />
                              <div className="input-group-append">
                                {this.state.typeconfpassword === "password" ? (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() =>
                                      this.setState({
                                        typeconfpassword: "text",
                                      })
                                    }
                                  >
                                    <i
                                      className="fa fa-eye"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    onClick={() =>
                                      this.setState({
                                        typeconfpassword: "password",
                                      })
                                    }
                                  >
                                    <i
                                      className="fa fa-eye-slash"
                                      style={{ cursor: "pointer" }}
                                    ></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Gender <span className="text-danger">*</span>
                        </label>
                        <Select
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) =>
                            this.setState({
                              gender: e !== null ? e.value : "",
                            })
                          }
                          options={this.state.genderOption}
                          value={this.state.genderOption.filter(
                            (obj) => obj.value === this.state.gender
                          )}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.gender}
                          required
                          onChange={(e) =>
                            this.setState({ gender: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Contact {this.state.isRequired ? (
                            <span className="text-danger">*</span> 
                          ): null }
                        </label>
                        <NumberFormat
                          // style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.contact}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ contact: values.floatValue })
                          }
                          format="+62 (###) #### #### ####"
                          // mask={"_"}
                          allowEmptyFormatting={false}
                          required={this.state.isRequired}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.isError ? (
                  // <div className="row">
                  <div className="col-md-12">
                    <Alert
                      color="danger"
                      isOpen={this.state.isError}
                      toggle={() =>
                        this.setState({
                          isError: false,
                        })
                      }
                    >
                      <strong>Failed!</strong> {this.state.errorMessage}
                    </Alert>
                  </div>
                ) : // </div>
                null}
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showAddressDefaultModal}
              toggle={() => this.toggleModal("showAddressDefaultModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Set Default Address
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressDefaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to change default this address "
                  {this.state.address}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressDefaultModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDefaultAddress()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Change to default"
                  )}
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showAddressDeleteModal}
              toggle={() => this.toggleModal("showAddressDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "
                  {this.state.address}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDeleteAddress()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "{this.state.email}
                  "?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered modal-xl"
              isOpen={this.state.showAddressModal}
              toggle={() => this.toggleModal("showAddressModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Address - {this.state.fullname}
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        value={this.state.valueAddress}
                        onChange={(e) => this.handleFilterAddress(e)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3"></div>
                  <div className="col-sm-6 col-md-3"></div>
                  <div className="col-sm-6 col-md-3">
                    <div className="float-right">
                      <Button
                        color="primary"
                        // data-toggle="modal"
                        // data-target="#showModalAddSubcategory"
                        onClick={() => this.openModalAddress()}
                      >
                        + Add data
                      </Button>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.getAddressUserSync(this.state.id_user)
                        }
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                </div>
                {/* Search Filter */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <Table
                        className="table-hover"
                        pagination={{
                          total: this.state.valueAddress.length
                            ? this.state.filteredDataAddress.length
                            : this.state.dataAddress.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={this.state.columnsAddress}
                        bordered
                        dataSource={
                          this.state.valueAddress.length
                            ? this.state.filteredDataAddress
                            : this.state.dataAddress
                        }
                        rowKey={(record) => record.id}
                        loading={this.state.showLoadingTableAddress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showAddressModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
