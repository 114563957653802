// reactstrap components
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";

const AuthLayout = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);
  return (
    <>
      <div className="main-content" ref={mainContent}>
        {localStorage.getItem("token") ? (
          <Redirect to="/admin/dashboard" />
        ) : (
          <Switch>
            <Route path={"/auth/login"} component={Login} />
            <Route path={"/auth/forgot-password"} component={ForgotPassword} />
            <Redirect from="*" to="/auth/login" />
          </Switch>
        )}
      </div>
    </>
  );
};

export default AuthLayout;
