import React, { Component } from "react";
import axios from "../../http/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      showLoading: false,
      success: false,
      successMessage: "",
    };
  }

  handleForgot = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    try {
      const res = await axios.post("forgot-password", {
        email: this.state.email,
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
          success: true,
          successMessage: res.data.messages,
        });
        // var result = res.data.result
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoading: false,
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Forgot Password - Administrator Bikers Dream</title>
          <meta name="description" content="Forgot password page" />
        </Helmet>
        <div className="header bg-gradient-bikers py-7 py-lg-8">
          {/* <div className="header bg-gradient-info py-7 py-lg-8"> */}
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Forgot Password?</h1>
                  <h4 className="text-lead text-light">
                    Enter your email and we'll send you instructions to reset
                    your password
                  </h4>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-muted text-center mt-2 mb-3">
                    <small>
                      Enter your email and we'll send you instructions to reset
                      your password
                    </small>
                  </div>
                  <Form role="form" onSubmit={this.handleForgot}>
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          autoComplete="new-email"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-left">
                      <Link to={"/auth/login"}>
                        <small>Back to login</small>
                      </Link>
                    </div>
                    <div className="text-center">

                      {this.state.success ? (
                        <Alert variant={"success"}>
                          <strong>Success!</strong> {this.state.successMessage}
                        </Alert>
                      ) : (
                        <Button
                          className="my-4"
                          color="primary"
                          type="submit"
                          disabled={this.state.showLoading}
                        >
                          {this.state.showLoading ? (
                            <div>
                              <i className="fas fa-circle-notch fa-spin"></i>{" "}
                              Loading
                            </div>
                          ) : (
                            "Send reset link"
                          )}
                        </Button>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </>
    );
  }
}
