/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Alert,
  Button,
  // Button,
  Card,
  CardBody,
  Row,
  // DropdownMenu,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownItem,
  // Modal,
  // CardHeader,
} from "reactstrap";
// import { Table } from "antd";
// import "antd/dist/antd.css";
// import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
import { formatDate } from "helper/helper";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { removetwoZero } from "helper/helper";

export default class FormVariantStockDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      dataVariantStock: [],
      filteredData: [],
      value: "",
      showLoadingTable: true,
      showLoading: false,
      showLoadingTypeDiscount: false,

      id: "",
      uid: "",
      produkname: "",

      created_at: new Date(),
      updated_at: new Date(),

      idvariant1: null,
      variant: null,
      showLoadingVariantFirst: false,
      isUpdateFormVariant1: false,

      typeDiscountOption: [],
      dataOptions: [],
      dataOptionSecond: [],
      showLoadingTableOptions1: false,
      idOptions1: "",
      options: "",

      idvariantsecond: null,
      variant_second: null,

      //form utama
      idRow: [],
      id_option: [],
      id_option_second: [],
      price: [],
      type_discount: [],
      discount: [],
      reduction: [],
      stock: [],
      priceFinal: [],
      editableRow: [],
      priceSet: 0,
      type_discountSet: 0,
      discountSet: 0,
      reductionSet: 0,
      stockSet: 0,
      priceFinalSet: 0,
    };
  }

  componentDidMount = () => {
    // console.log(this.props.record);
    this.getTypeDiscount();
    // if (this.props.uid) {
    //   this.getProductDetail(this.props.uid);
    // }
    console.log(this.props.record);
    if (this.props.isUpdate === false) {
      this.getProductDetail(this.props.record.uid);
    } else {
      this.getProductDetail(this.props.uid);
    }
  };
  componentDidUpdate(prevProps) {
    // console.log(prevProps.record);
    // if (this.props.uid !== prevProps.uid) {
    //   // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    //   this.getProductDetail(this.props.uid);
    // }
    if (
      this.props.uid !== prevProps.uid ||
      this.props.record !== prevProps.record
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      if (this.props.isUpdate === false) {
        this.getProductDetail(this.props.record.uid);
      } else {
        this.getProductDetail(this.props.uid);
      }
    }
  }

  getProductDetail = async (uid) => {
    try {
      const res = await axiosCustom.get("product/" + uid);
      if (res.status === 200) {
        var data = res.data.result;
        // console.log("tes 22");
        this.editData(data);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  editData = (record) => {
    // console.log("tess");
    // this.getdataVariantStock(record.uid);
    this.getdatavariantFirst(record.uid);
    this.getdatavariantSecond(record.uid);
    this.setState({
      uid: record.uid,
      produkname: record.produkname,
      isUpdate: this.props.isUpdate,
    });
  };

  getTypeDiscount = async () => {
    this.setState({
      showLoadingTypeDiscount: true,
    });

    try {
      const res = await axiosCustom.get("type-discount");
      if (res.status === 200) {
        var data = res.data.result;
        // var data = res.data.result.sort(function (a, b) {
        //   return moment(b.updated_at).unix() - moment(a.updated_at).unix();
        // });
        var typeDiscountOption = data.map((item) => {
          return {
            value: item.type_discount,
            label: item.name,
          };
        });

        this.setState({
          typeDiscountOption,
        });
      } else {
        this.setState({
          typeDiscountOption: [],
        });
      }
      this.setState({
        showLoadingTypeDiscount: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTypeDiscount: false,
        typeDiscountOption: [],
      });
    }
  };

  getdatavariantFirst = async (uid) => {
    try {
      const res = await axiosCustom.get("product-variants", {
        params: {
          uid: uid,
        },
      });
      if (res.status === 200) {
        var data = res.data.result;
        if (data.length) {
          this.setState({
            variant: data[0].variant,
            idvariant1: data[0].id,
          });
          this.getdataOptions1(data[0].id);
        } else {
          this.setState({
            variant: null,
            idvariant1: null,
            dataOptions: [],
          });
          this.getdataVariantStockOneRow(this.state.uid);
          this.changeStateOneRow();
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        variant: null,
        idvariant1: null,
        isUpdateFormVariant1: false,
        dataOptions: [],
      });
    }
  };

  getdatavariantSecond = async (uid) => {
    try {
      const res = await axiosCustom.get("product-variants-second", {
        params: {
          uid: uid,
        },
      });
      if (res.status === 200) {
        var data = res.data.result;
        if (data.length) {
          this.setState({
            variant_second: data[0].variant_second,
            idvariantsecond: data[0].id,
          });
          // this.getdataOptionSecond(data[0].id);
        } else {
          this.setState({
            variant_second: null,
            idvariantsecond: null,
            isUpdateFormVariantSecond: false,
            dataOptionSecond: [],
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        variant_second: null,
        idvariantsecond: null,
        dataOptionSecond: [],
      });
    }
  };

  getdataOptions1 = async (id_variant) => {
    try {
      const res = await axiosCustom.get("product-variants-options", {
        params: {
          id_variant: id_variant,
        },
      });
      if (res.status === 200) {
        var dataOptions = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });

        if (dataOptions.length) {
          this.setState({
            dataOptions,
          });
          if (this.state.idvariantsecond) {
            this.getdataOptionSecond(this.state.idvariantsecond, dataOptions);
          } else {
            this.getdataVariantStockOptionFirst(this.state.uid);
            this.changeStateVariantFirst(dataOptions);
          }
        } else {
          this.setState({
            dataOptions: [],
          });
          this.getdataVariantStockOneRow(this.state.uid);
          this.changeStateOneRow();
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        dataOptions: [],
      });
    }
  };

  getdataOptionSecond = async (id_variant_second, dataOptions) => {
    try {
      const res = await axiosCustom.get("product-variants-options-second", {
        params: {
          id_variant_second: id_variant_second,
        },
      });
      if (res.status === 200) {
        var dataOptionSecond = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        if (dataOptionSecond.length) {
          this.setState({
            dataOptionSecond,
          });

          dataOptions.map((row) => {
            this.changeState(dataOptionSecond, row);
          });

          this.getdataVariantStock(this.state.uid);
        } else {
          this.getdataVariantStockOptionFirst(this.state.uid);
          this.changeStateVariantFirst(dataOptions);
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        dataOptionSecond: [],
      });
    }
  };

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[[key]]] = result[currentValue[[key]]] || []).push(
        currentValue
      );
      // console.log(result);
      return result;
    }, {});
  };

  getdataVariantStock = async (uid) => {
    this.setState({
      showLoadingTable: true,
      dataVariantStock: [],
    });

    try {
      const res = await axiosCustom.get("product-variants-stock", {
        params: {
          uid: uid,
          // uid: 'jo09fwe908-9jr9mmf-84jf9'
        },
      });
      if (res.status === 200) {
        var dataResult = res.data.result.sort(function (a, b) {
          return parseInt(b.id_option) - parseInt(a.id_option);
        });

        // var dataVariantStock = this.groupBy(dataResult, "id_option");
        var dataVariantStock = dataResult.map((item) => {
          this.setState({
            idRow: {
              ...this.state.idRow,
              ...{
                [`${item.options}_${item.options_second}`]: item.id,
              },
            },
            id_option: {
              ...this.state.id_option,
              ...{
                [`${item.options}_${item.options_second}`]: item.id_option,
              },
            },
            id_option_second: {
              ...this.state.id_option_second,
              ...{
                [`${item.options}_${item.options_second}`]:
                  item.id_option_second,
              },
            },
            price: {
              ...this.state.price,
              ...{
                [`${item.options}_${item.options_second}`]: item.price
                  ? removetwoZero(item.price)
                  : 0,
              },
            },
            type_discount: {
              ...this.state.type_discount,
              ...{
                [`${item.options}_${item.options_second}`]: parseInt(
                  item.type_discount
                ),
              },
            },
            discount: {
              ...this.state.discount,
              ...{
                [`${item.options}_${item.options_second}`]: item.discount
                  ? item.discount
                  : 0,
              },
            },
            reduction: {
              ...this.state.reduction,
              ...{
                [`${item.options}_${item.options_second}`]: item.reduction
                  ? removetwoZero(item.reduction)
                  : 0,
              },
            },
            stock: {
              ...this.state.stock,
              ...{ [`${item.options}_${item.options_second}`]: item.stock },
            },
            editableRow: {
              ...this.state.editableRow,
              ...{ [`${item.options}_${item.options_second}`]: true },
            },
            priceFinal: {
              ...this.state.priceFinal,
              ...{
                [`${item.options}_${item.options_second}`]:
                  item.type_discount === "1"
                    ? item.last_price_with_discount
                    : item.type_discount === "2"
                    ? removetwoZero(item.last_price_with_reduction)
                    : removetwoZero(item.price),
              },
            },
          });
        });

        this.setState({
          dataVariantStock,
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        dataVariantStock: [],
      });
    }
  };
  getdataVariantStockOptionFirst = async (uid) => {
    this.setState({
      showLoadingTable: true,
      dataVariantStock: [],
    });

    try {
      const res = await axiosCustom.get("product-variants-stock", {
        params: {
          uid: uid,
          // uid: 'jo09fwe908-9jr9mmf-84jf9'
        },
      });
      if (res.status === 200) {
        var dataResult = res.data.result.sort(function (a, b) {
          return parseInt(b.id_option) - parseInt(a.id_option);
        });

        // var dataVariantStock = this.groupBy(dataResult, "id_option");
        var dataVariantStock = dataResult.map((item) => {
          this.setState({
            idRow: {
              ...this.state.idRow,
              ...{
                [`${item.options}`]: item.id,
              },
            },
            id_option: {
              ...this.state.id_option,
              ...{
                [`${item.options}`]: item.id_option,
              },
            },
            id_option_second: {
              ...this.state.id_option_second,
              ...{
                [`${item.options}`]: item.id_option_second,
              },
            },
            price: {
              ...this.state.price,
              ...{
                [`${item.options}`]: item.price ? removetwoZero(item.price) : 0,
              },
            },
            type_discount: {
              ...this.state.type_discount,
              ...{
                [`${item.options}`]: parseInt(item.type_discount),
              },
            },
            discount: {
              ...this.state.discount,
              ...{
                [`${item.options}`]: item.discount ? item.discount : 0,
              },
            },
            reduction: {
              ...this.state.reduction,
              ...{
                [`${item.options}`]: item.reduction
                  ? removetwoZero(item.reduction)
                  : 0,
              },
            },
            stock: {
              ...this.state.stock,
              ...{ [`${item.options}`]: item.stock },
            },
            editableRow: {
              ...this.state.editableRow,
              ...{ [`${item.options}`]: true },
            },
            priceFinal: {
              ...this.state.priceFinal,
              ...{
                [`${item.options}`]:
                  item.type_discount === "1"
                    ? item.last_price_with_discount
                    : item.type_discount === "2"
                    ? removetwoZero(item.last_price_with_reduction)
                    : removetwoZero(item.price),
              },
            },
          });
        });

        this.setState({
          dataVariantStock,
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        dataVariantStock: [],
      });
    }
  };

  getdataVariantStockOneRow = async (uid) => {
    this.setState({
      showLoadingTable: true,
      dataVariantStock: [],
    });

    try {
      const res = await axiosCustom.get("product-variants-stock", {
        params: {
          uid: uid,
          // uid: 'jo09fwe908-9jr9mmf-84jf9'
        },
      });
      if (res.status === 200) {
        var dataResult = res.data.result.sort(function (a, b) {
          return parseInt(b.id_option) - parseInt(a.id_option);
        });

        // var dataVariantStock = this.groupBy(dataResult, "id_option");
        var dataVariantStock = dataResult.map((item) => {
          this.setState({
            idRow: {
              ...this.state.idRow,
              ...{
                row: item.id,
              },
            },
            id_option: {
              ...this.state.id_option,
              ...{
                row: item.id_option,
              },
            },
            id_option_second: {
              ...this.state.id_option_second,
              ...{
                row: item.id_option_second,
              },
            },
            price: {
              ...this.state.price,
              ...{
                row: item.price ? removetwoZero(item.price) : 0,
              },
            },
            type_discount: {
              ...this.state.type_discount,
              ...{
                row: parseInt(item.type_discount),
              },
            },
            discount: {
              ...this.state.discount,
              ...{
                row: item.discount ? item.discount : 0,
              },
            },
            reduction: {
              ...this.state.reduction,
              ...{
                row: item.reduction ? removetwoZero(item.reduction) : 0,
              },
            },
            stock: {
              ...this.state.stock,
              ...{ row: item.stock },
            },
            editableRow: {
              ...this.state.editableRow,
              ...{ row: true },
            },
            priceFinal: {
              ...this.state.priceFinal,
              ...{
                row:
                  item.type_discount === "1"
                    ? item.last_price_with_discount
                    : item.type_discount === "2"
                    ? removetwoZero(item.last_price_with_reduction)
                    : removetwoZero(item.price),
              },
            },
          });
        });

        this.setState({
          dataVariantStock,
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        dataVariantStock: [],
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.variant).toLowerCase().startsWith(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .startsWith(value.toLowerCase());
        let includesCondition =
          String(item.variant).toLowerCase().includes(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      variant: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  // editData = (record) => {
  //   this.toggleModal("showModalAdd");
  //   this.setState({
  //     isUpdateForm: true,
  //     id: record.id,
  //     variant: record.variant,
  //     created_at: new Date(record.created_at),
  //     updated_at: new Date(),
  //   });
  // };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      variant: record.variant,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async () => {
    this.setState({
      showLoading: true,
    });

    const dataInput = [];

    this.state.dataOptions.map((row) => {
      this.state.dataOptionSecond.map((item, index) => {
        const obj = {
          uid: this.state.uid,
          id_option: row.id,
          id_option_second: item.id,
          price: this.state.price[`${row.options}_${item.options_second}`],
          type_discount:
            this.state.type_discount[`${row.options}_${item.options_second}`],
          discount:
            this.state.discount[`${row.options}_${item.options_second}`],
          reduction:
            this.state.reduction[`${row.options}_${item.options_second}`],
          stock: this.state.stock[`${row.options}_${item.options_second}`],
        };

        dataInput.push(obj);
      });
    });

    try {
      let res = {};
      // if (this.state.isUpdateForm) {
      //   res = await axiosCustom.put(
      //     "product-variants-stock-push/" + this.state.id,
      //     dataInput
      //   );
      // } else {
      // }
      res = await axiosCustom.post("product-variants-stock-push", dataInput);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.handleSaveFinish();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  handleSaveRow = async (index, disable) => {
    if (disable) {
      return null;
    }
    this.setState({
      showLoading: true,
    });

    // if (this.state.idRow[index]) {
    //    dataInput = {
    //     uid: this.state.uid,
    //     id: this.state.idRow[index],
    //     id_option: this.state.id_option[index],
    //     id_option_second: this.state.id_option_second[index],
    //     price: this.state.price[index],
    //     type_discount:
    //       this.state.type_discount[index],
    //     discount:
    //       this.state.discount[index],
    //     reduction:
    //       this.state.reduction[index],
    //     stock: this.state.stock[index],
    //   };
    // } else {
    // }
    var dataInput = {
      uid: this.state.uid,
      id_option: this.state.id_option[index],
      id_option_second: this.state.id_option_second[index],
      price: this.state.price[index] ? this.state.price[index] : 0,
      type_discount: String(this.state.type_discount[index]),
      discount: this.state.discount[index] ? this.state.discount[index] : 0,
      reduction: this.state.reduction[index] ? this.state.reduction[index] : 0,
      stock: this.state.stock[index],
    };

    try {
      let res = {};
      if (this.state.idRow[index]) {
        res = await axiosCustom.put(
          "product-variants-stock/" + this.state.idRow[index],
          dataInput
        );
      } else {
        res = await axiosCustom.post("product-variants-stock", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (!this.state.idRow[index]) {
          if (this.state.dataOptions.length) {
            if (this.state.dataOptionSecond.length) {
              this.getdataVariantStock(this.state.uid);
            } else {
              this.getdataVariantStockOptionFirst(this.state.uid);
            }
          } else {
            this.getdataVariantStockOneRow(this.state.uid);
          }
        } else {
          this.setState({
            editableRow: {
              ...this.state.editableRow,
              ...{ [index]: true },
            },
          });
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDelete = async () => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("product-variants/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdataVariantStock(this.state.uid);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err
        ? err.response.data
          ? err.response.data.messages
          : "Error invalid"
        : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  handleSaveFinish = () => {
    localStorage.setItem("successSubmit", true);
    setTimeout(() => {
      this.props.history.push({
        pathname: "/admin/product",
      });
    }, 200);
  };

  renderData = (data) => {
    console.log("data", data);
    return Object.entries(data).forEach(([key, value]) => {
      value.forEach((item, index) => (
        <tr>
          {index === 0 ? (
            <td rowSpan={value.length}>{item.id_option}</td>
          ) : null}
          <td>{item.id_option_second}</td>
          <td>{item.price}</td>
          <td>{item.type_discount}</td>
          <td>{item.discount}</td>
          <td>{item.reduction}</td>
          <td>{item.stock}</td>
        </tr>
      ));
    });
  };

  changeState = (dataOptionSecond, row) => {
    dataOptionSecond.map((item, index) => {
      this.setState({
        idRow: {
          ...this.state.idRow,
          ...{
            [`${row.options}_${item.options_second}`]: null,
          },
        },
        id_option: {
          ...this.state.id_option,
          ...{
            [`${row.options}_${item.options_second}`]: row.id,
          },
        },
        id_option_second: {
          ...this.state.id_option_second,
          ...{
            [`${row.options}_${item.options_second}`]: item.id,
          },
        },
        price: {
          ...this.state.price,
          ...{ [`${row.options}_${item.options_second}`]: 0 },
        },
        type_discount: {
          ...this.state.type_discount,
          ...{ [`${row.options}_${item.options_second}`]: 0 },
        },
        discount: {
          ...this.state.discount,
          ...{ [`${row.options}_${item.options_second}`]: 0 },
        },
        reduction: {
          ...this.state.reduction,
          ...{ [`${row.options}_${item.options_second}`]: 0 },
        },
        stock: {
          ...this.state.stock,
          ...{ [`${row.options}_${item.options_second}`]: 0 },
        },
        editableRow: {
          ...this.state.editableRow,
          ...{ [`${row.options}_${item.options_second}`]: false },
        },
      });
    });
  };

  changeStateOneRow = () => {
    this.setState({
      idRow: {
        ...this.state.idRow,
        ...{
          row: null,
        },
      },
      id_option: {
        ...this.state.id_option,
        ...{
          row: null,
        },
      },
      id_option_second: {
        ...this.state.id_option_second,
        ...{
          row: null,
        },
      },
      price: {
        ...this.state.price,
        ...{ row: 0 },
      },
      type_discount: {
        ...this.state.type_discount,
        ...{ row: 0 },
      },
      discount: {
        ...this.state.discount,
        ...{ row: 0 },
      },
      reduction: {
        ...this.state.reduction,
        ...{ row: 0 },
      },
      stock: {
        ...this.state.stock,
        ...{ row: 0 },
      },
      editableRow: {
        ...this.state.editableRow,
        ...{ row: false },
      },
    });
  };

  changeStateVariantFirst = (dataOptions) => {
    dataOptions.map((row, index) => {
      this.setState({
        idRow: {
          ...this.state.idRow,
          ...{
            [`${row.options}`]: null,
          },
        },
        id_option: {
          ...this.state.id_option,
          ...{
            [`${row.options}`]: row.id,
          },
        },
        id_option_second: {
          ...this.state.id_option_second,
          ...{
            [`${row.options}`]: null,
          },
        },
        price: {
          ...this.state.price,
          ...{ [`${row.options}`]: 0 },
        },
        type_discount: {
          ...this.state.type_discount,
          ...{ [`${row.options}`]: 0 },
        },
        discount: {
          ...this.state.discount,
          ...{ [`${row.options}`]: 0 },
        },
        reduction: {
          ...this.state.reduction,
          ...{ [`${row.options}`]: 0 },
        },
        stock: {
          ...this.state.stock,
          ...{ [`${row.options}`]: 0 },
        },
        editableRow: {
          ...this.state.editableRow,
          ...{ [`${row.options}`]: false },
        },
      });
    });
  };

  renderTable = (dataOptionSecond, row) => {
    // setTimeout(() => {
    //   this.changeState(dataOptionSecond, row)
    // }, 300);
    return dataOptionSecond.map((item, index) => (
      <tr key={`${row.options}_${item.options_second}`}>
        {index === 0 ? (
          <td rowSpan={dataOptionSecond.length}>{row.options}</td>
        ) : null}
        <td>{item.options_second}</td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.price[`${row.options}_${item.options_second}`]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(
                e,
                "price",
                `${row.options}_${item.options_second}`
              )
            }
            disabled={
              this.state.showLoading ||
              this.state.editableRow[`${row.options}_${item.options_second}`]
            }
          />
        </td>
        <td>
          <Select
            // isClearable={true}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            menuShouldScrollIntoView={false}
            // onChange={(e) =>
            //   this.setState({
            //     type_discount:
            //       e !== null ? e.value : "",
            //   })
            // }
            onChange={(e) =>
              this.handleSelect(
                e,
                "type_discount",
                `${row.options}_${item.options_second}`
              )
            }
            options={this.state.typeDiscountOption}
            value={this.state.typeDiscountOption.filter(
              (obj) =>
                obj.value ===
                this.state.type_discount[
                  `${row.options}_${item.options_second}`
                ]
            )}
            isDisabled={
              this.state.showLoadingTypeDiscount ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}_${item.options_second}`]
            }
            isLoading={this.state.showLoadingTypeDiscount}
            placeholder=""
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.discount[`${row.options}_${item.options_second}`]}
            // thousandSeparator={"."}
            // decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(
                e,
                "discount",
                `${row.options}_${item.options_second}`
              )
            }
            disabled={
              this.state.type_discount[
                `${row.options}_${item.options_second}`
              ] !== 1 ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}_${item.options_second}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={
              this.state.reduction[`${row.options}_${item.options_second}`]
            }
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(
                e,
                "reduction",
                `${row.options}_${item.options_second}`
              )
            }
            disabled={
              this.state.type_discount[
                `${row.options}_${item.options_second}`
              ] !== 2 ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}_${item.options_second}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.stock[`${row.options}_${item.options_second}`]}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(
                e,
                "stock",
                `${row.options}_${item.options_second}`
              )
            }
            disabled={
              this.state.showLoading ||
              this.state.editableRow[`${row.options}_${item.options_second}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={
              this.state.priceFinal[`${row.options}_${item.options_second}`]
            }
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(
                e,
                "priceFinal",
                `${row.options}_${item.options_second}`
              )
            }
            disabled
          />
        </td>
        <td>
          {!this.state.editableRow[`${row.options}_${item.options_second}`] ? (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: this.state.showLoading ? "not-allowed" : "pointer",
              }}
              onClick={() =>
                this.handleSaveRow(
                  `${row.options}_${item.options_second}`,
                  this.state.showLoading
                )
              }
            >
              {this.state.showLoading ? (
                <div>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </div>
              ) : (
                "Save"
              )}
            </span>
          ) : (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  editableRow: {
                    ...this.state.editableRow,
                    ...{ [`${row.options}_${item.options_second}`]: false },
                  },
                })
              }
            >
              Edit
            </span>
          )}
        </td>
      </tr>
    ));
  };
  renderTableOptionFirst = (row) => {
    // setTimeout(() => {
    //   this.changeState(dataOptionSecond, row)
    // }, 300);
    return (
      <tr key={`${row.options}`}>
        <td>{row.options}</td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.price[`${row.options}`]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "price", `${row.options}`)
            }
            disabled={
              this.state.showLoading || this.state.editableRow[`${row.options}`]
            }
          />
        </td>
        <td>
          <Select
            // isClearable={true}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            menuShouldScrollIntoView={false}
            // onChange={(e) =>
            //   this.setState({
            //     type_discount:
            //       e !== null ? e.value : "",
            //   })
            // }
            onChange={(e) =>
              this.handleSelect(e, "type_discount", `${row.options}`)
            }
            options={this.state.typeDiscountOption}
            value={this.state.typeDiscountOption.filter(
              (obj) => obj.value === this.state.type_discount[`${row.options}`]
            )}
            isDisabled={
              this.state.showLoadingTypeDiscount ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}`]
            }
            isLoading={this.state.showLoadingTypeDiscount}
            placeholder=""
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.discount[`${row.options}`]}
            // thousandSeparator={"."}
            // decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "discount", `${row.options}`)
            }
            disabled={
              this.state.type_discount[`${row.options}`] !== 1 ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.reduction[`${row.options}`]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "reduction", `${row.options}`)
            }
            disabled={
              this.state.type_discount[`${row.options}`] !== 2 ||
              this.state.showLoading ||
              this.state.editableRow[`${row.options}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.stock[`${row.options}`]}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "stock", `${row.options}`)
            }
            disabled={
              this.state.showLoading || this.state.editableRow[`${row.options}`]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.priceFinal[`${row.options}`]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "priceFinal", `${row.options}`)
            }
            disabled
          />
        </td>
        <td>
          {!this.state.editableRow[`${row.options}`] ? (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: this.state.showLoading ? "not-allowed" : "pointer",
              }}
              onClick={() =>
                this.handleSaveRow(`${row.options}`, this.state.showLoading)
              }
            >
              {this.state.showLoading ? (
                <div>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </div>
              ) : (
                "Save"
              )}
            </span>
          ) : (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  editableRow: {
                    ...this.state.editableRow,
                    ...{ [`${row.options}`]: false },
                  },
                })
              }
            >
              Edit
            </span>
          )}
        </td>
      </tr>
    );
  };

  renderOneRow = () => {
    return (
      <tr>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.price["row"]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) => this.handleChangeNumber(e, "price", `row`)}
            disabled={this.state.showLoading || this.state.editableRow["row"]}
          />
        </td>
        <td>
          <Select
            // isClearable={true}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            menuShouldScrollIntoView={false}
            // onChange={(e) =>
            //   this.setState({
            //     type_discount:
            //       e !== null ? e.value : "",
            //   })
            // }
            onChange={(e) => this.handleSelect(e, "type_discount", "row")}
            options={this.state.typeDiscountOption}
            value={this.state.typeDiscountOption.filter(
              (obj) => obj.value === this.state.type_discount["row"]
            )}
            isDisabled={
              this.state.showLoadingTypeDiscount ||
              this.state.showLoading ||
              this.state.editableRow["row"]
            }
            isLoading={this.state.showLoadingTypeDiscount}
            placeholder=""
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.discount["row"]}
            // thousandSeparator={"."}
            // decimalSeparator={","}
            prefix={""}
            onValueChange={(e) => this.handleChangeNumber(e, "discount", "row")}
            disabled={
              this.state.type_discount["row"] !== 1 ||
              this.state.showLoading ||
              this.state.editableRow["row"]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.reduction["row"]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "reduction", "row")
            }
            disabled={
              this.state.type_discount["row"] !== 2 ||
              this.state.showLoading ||
              this.state.editableRow["row"]
            }
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.stock["row"]}
            prefix={""}
            onValueChange={(e) => this.handleChangeNumber(e, "stock", "row")}
            disabled={this.state.showLoading || this.state.editableRow["row"]}
          />
        </td>
        <td>
          <NumberFormat
            style={{ textAlign: "right" }}
            className="form-control"
            value={this.state.priceFinal["row"]}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={""}
            onValueChange={(e) =>
              this.handleChangeNumber(e, "priceFinal", "row")
            }
            disabled
          />
        </td>
        <td>
          {!this.state.editableRow["row"] ? (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: this.state.showLoading ? "not-allowed" : "pointer",
              }}
              onClick={() => this.handleSaveRow("row", this.state.showLoading)}
            >
              {this.state.showLoading ? (
                <div>
                  <i className="fas fa-circle-notch fa-spin"></i>
                </div>
              ) : (
                "Save"
              )}
            </span>
          ) : (
            <span
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  editableRow: {
                    ...this.state.editableRow,
                    ...{ row: false },
                  },
                })
              }
            >
              Edit
            </span>
          )}
        </td>
      </tr>
    );
  };

  handleSelect = (e, input, index) => {
    if (input === "type_discount") {
      // if (e !== null) {
      //   if (e.value === 0) {
      //     this.setState({
      //       discount: {
      //         ...this.state.discount, ...{ [index]: 0 }
      //       },
      //       reduction: {
      //         ...this.state.reduction, ...{ [index]: 0 }
      //       },
      //       priceFinal: {
      //         ...this.state.reduction, ...{ [index]: this.state.price[index] }
      //       },
      //     })
      //   } else if (e.value === 1) {

      //   }
      // }
      this.setState({
        discount: {
          ...this.state.discount,
          ...{ [index]: 0 },
        },
        reduction: {
          ...this.state.reduction,
          ...{ [index]: 0 },
        },
        priceFinal: {
          ...this.state.priceFinal,
          ...{ [index]: this.state.price[index] },
        },
      });
    }
    this.setState({
      [input]: {
        ...this.state[input],
        ...{ [index]: e !== null ? e.value : "" },
      },
    });
  };

  handleSelectSet = (e, input) => {
    if (input === "type_discountSet") {
      this.setState({
        discountSet: 0,
        reductionSet: 0,
        priceFinalSet: this.state.priceSet,
      });
    }
    this.setState({
      [input]: e !== null ? e.value : "",
    });
  };

  countPersentase = (price, discount) => {
    var disc = (discount / 100) * price;
    var total = price - disc;
    return total;
  };
  countReduction = (price, discount) => {
    var total = price - discount;
    return total;
  };

  handleChangeNumber = (e, input, index) => {
    switch (input) {
      case "price":
        const harga = e.floatValue;
        // const priceFinal = this.state.priceFinal[index]
        const discount = this.state.discount[index];
        const reduction = this.state.reduction[index];
        if (this.state.type_discount[index] === 1) {
          var priceFinalTotal = this.countPersentase(harga, discount);
          this.setState({
            priceFinal: {
              ...this.state.priceFinal,
              ...{ [index]: priceFinalTotal },
            },
            [input]: {
              ...this.state[input],
              ...{ [index]: e.floatValue },
            },
          });
        } else if (this.state.type_discount[index] === 2) {
          var total = this.countReduction(harga, reduction);
          this.setState({
            priceFinal: {
              ...this.state.priceFinal,
              ...{ [index]: total },
            },
            [input]: {
              ...this.state[input],
              ...{ [index]: e.floatValue },
            },
          });
        } else {
          this.setState({
            priceFinal: {
              ...this.state.priceFinal,
              ...{ [index]: e.floatValue },
            },
            [input]: {
              ...this.state[input],
              ...{ [index]: e.floatValue },
            },
          });
        }

        break;
      case "reduction":
        const potongan = e.floatValue;
        const hargaAsli = this.state.price[index];
        const hargaBaru = this.countReduction(hargaAsli, potongan);
        this.setState({
          priceFinal: {
            ...this.state.priceFinal,
            ...{ [index]: hargaBaru },
          },
          [input]: {
            ...this.state[input],
            ...{ [index]: e.floatValue },
          },
        });
        break;
      case "discount":
        const disc = e.floatValue;
        const PriceAsli = this.state.price[index];
        const priceBaru = this.countPersentase(PriceAsli, disc);
        this.setState({
          priceFinal: {
            ...this.state.priceFinal,
            ...{ [index]: priceBaru },
          },
          [input]: {
            ...this.state[input],
            ...{ [index]: e.floatValue },
          },
        });
        break;

      default:
        this.setState({
          [input]: {
            ...this.state[input],
            ...{ [index]: e.floatValue },
          },
        });
        break;
    }
  };
  handleChangeNumberSet = (e, input) => {
    switch (input) {
      case "priceSet":
        const harga = e.floatValue;
        // const priceFinalSet = this.state.priceFinalSet
        const discountSet = this.state.discountSet;
        const reductionSet = this.state.reductionSet;
        if (this.state.type_discountSet === 1) {
          var priceFinalTotal = this.countPersentase(harga, discountSet);
          this.setState({
            priceFinalSet: priceFinalTotal,
            [input]: e.floatValue,
          });
        } else if (this.state.type_discountSet === 2) {
          var total = this.countReduction(harga, reductionSet);
          this.setState({
            priceFinalSet: total,
            [input]: e.floatValue,
          });
        } else {
          this.setState({
            priceFinalSet: e.floatValue,
            [input]: e.floatValue,
          });
        }

        break;
      case "reductionSet":
        const potongan = e.floatValue;
        const hargaAsli = this.state.priceSet;
        const hargaBaru = this.countReduction(hargaAsli, potongan);
        this.setState({
          priceFinalSet: hargaBaru,
          [input]: e.floatValue,
        });
        break;
      case "discountSet":
        const disc = e.floatValue;
        const PriceAsli = this.state.priceSet;
        const priceBaru = this.countPersentase(PriceAsli, disc);
        this.setState({
          priceFinalSet: priceBaru,
          [input]: e.floatValue,
        });
        break;

      default:
        this.setState({
          [input]: e.floatValue,
        });
        break;
    }
  };

  handleSetAll = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    if (this.state.dataOptionSecond.length) {
      this.state.dataOptions.map((row) => {
        this.state.dataOptionSecond.map((item, index) => {
          setTimeout(() => {
            // console.log(this.state.priceSet, index);
            this.setState({
              price: {
                ...this.state.price,
                ...{
                  [`${row.options}_${item.options_second}`]:
                    this.state.priceSet,
                },
              },
              type_discount: {
                ...this.state.type_discount,
                ...{
                  [`${row.options}_${item.options_second}`]:
                    this.state.type_discountSet,
                },
              },
              discount: {
                ...this.state.discount,
                ...{
                  [`${row.options}_${item.options_second}`]:
                    this.state.discountSet,
                },
              },
              reduction: {
                ...this.state.reduction,
                ...{
                  [`${row.options}_${item.options_second}`]:
                    this.state.reductionSet,
                },
              },
              stock: {
                ...this.state.stock,
                ...{
                  [`${row.options}_${item.options_second}`]:
                    this.state.stockSet,
                },
              },
              editableRow: {
                ...this.state.editableRow,
                ...{ [`${row.options}_${item.options_second}`]: false },
              },
            });
          }, 50);
        });
      });
    } else {
      this.state.dataOptions.map((row) => {
        setTimeout(() => {
          // console.log(this.state.priceSet, index);
          this.setState({
            price: {
              ...this.state.price,
              ...{
                [`${row.options}`]: this.state.priceSet,
              },
            },
            type_discount: {
              ...this.state.type_discount,
              ...{
                [`${row.options}`]: this.state.type_discountSet,
              },
            },
            discount: {
              ...this.state.discount,
              ...{
                [`${row.options}`]: this.state.discountSet,
              },
            },
            reduction: {
              ...this.state.reduction,
              ...{
                [`${row.options}`]: this.state.reductionSet,
              },
            },
            stock: {
              ...this.state.stock,
              ...{
                [`${row.options}`]: this.state.stockSet,
              },
            },
            editableRow: {
              ...this.state.editableRow,
              ...{ [`${row.options}`]: false },
            },
          });
        }, 50);
      });
    }

    setTimeout(() => {
      this.setState({
        showLoading: false,
      });
    }, 200);
  };

  render() {
    const {
      // value,
      // filteredData,
      // data,
      // showLoadingTable,
      // columns,
      dataOptions,
      dataOptionSecond,
      variant,
      variant_second,
    } = this.state;
    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              {/* <CardHeader className="border-0 mb-0">
                <h3 className="mb-0">
                  Variant List Product - {this.state.produkname}
                </h3>
              </CardHeader> */}
              <CardBody className="mt-0">
                <div className="row">
                  <div className="col-sm-6 col-md-8">
                    <h3 className="mb-0">
                      Variant Stock Product - {this.state.produkname}
                    </h3>
                  </div>
                  <div className="col-sm-6 col-md-4">
                    {/* <div className="float-right">
                    <Button
                            color="primary"
                            onClick={() => this.handleSubmit()}
                            disabled={this.state.showLoading}
                          >
                            {this.state.showLoading ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i> Executing
                              </div>
                            ) : (
                              "Save Changes"
                            )}
                          </Button>
                    </div> */}
                  </div>
                </div>
                <div className="row mt-0">
                  {dataOptions.length ? (
                    <div className="col-md-12">
                      <div className="table-responsive mt-0">
                        <form onSubmit={this.handleSetAll}>
                          <table className="styled-table-variant">
                            <thead>
                              <tr>
                                <th style={{ width: "15%" }}>Price</th>
                                <th style={{ width: "20%" }}>Type Discount</th>
                                <th style={{ width: "10%" }}>Disc (%)</th>
                                <th style={{ width: "15%" }}>Reduction</th>
                                <th style={{ width: "10%" }}>Stock</th>
                                <th style={{ width: "15%" }}>Price Final</th>
                                <th style={{ width: "15%" }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <NumberFormat
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    value={this.state.priceSet}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={""}
                                    onValueChange={(e) =>
                                      this.handleChangeNumberSet(e, "priceSet")
                                    }
                                    disabled={this.state.showLoading}
                                  />
                                </td>
                                <td>
                                  <Select
                                    // isClearable={true}
                                    styles={{
                                      menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 9999,
                                      }),
                                    }}
                                    menuPortalTarget={document.body}
                                    menuPosition={"fixed"}
                                    menuShouldScrollIntoView={false}
                                    onChange={(e) =>
                                      this.handleSelectSet(
                                        e,
                                        "type_discountSet"
                                      )
                                    }
                                    options={this.state.typeDiscountOption}
                                    value={this.state.typeDiscountOption.filter(
                                      (obj) =>
                                        obj.value ===
                                        this.state.type_discountSet
                                    )}
                                    isDisabled={
                                      this.state.showLoadingTypeDiscount ||
                                      this.state.showLoading
                                    }
                                    isLoading={
                                      this.state.showLoadingTypeDiscount
                                    }
                                    placeholder=""
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    value={this.state.discountSet}
                                    // thousandSeparator={"."}
                                    // decimalSeparator={","}
                                    prefix={""}
                                    onValueChange={(e) =>
                                      this.handleChangeNumberSet(
                                        e,
                                        "discountSet"
                                      )
                                    }
                                    disabled={
                                      this.state.type_discountSet !== 1 ||
                                      this.state.showLoading
                                    }
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    value={this.state.reductionSet}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={""}
                                    onValueChange={(e) =>
                                      this.handleChangeNumberSet(
                                        e,
                                        "reductionSet"
                                      )
                                    }
                                    disabled={
                                      this.state.type_discountSet !== 2 ||
                                      this.state.showLoading
                                    }
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    value={this.state.stockSet}
                                    prefix={""}
                                    onValueChange={(e) =>
                                      this.handleChangeNumberSet(e, "stockSet")
                                    }
                                    disabled={this.state.showLoading}
                                  />
                                </td>
                                <td>
                                  <NumberFormat
                                    style={{ textAlign: "right" }}
                                    className="form-control"
                                    value={this.state.priceFinalSet}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    prefix={""}
                                    onValueChange={(e) =>
                                      this.handleChangeNumberSet(
                                        e,
                                        "priceFinalSet"
                                      )
                                    }
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Button
                                    color="primary"
                                    // onClick={() => this.handleSubmit()}
                                    type="submit"
                                    disabled={this.state.showLoading}
                                    style={{ width: "100%" }}
                                  >
                                    {this.state.showLoading ? (
                                      <div>
                                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                        Executing
                                      </div>
                                    ) : (
                                      "Set to all"
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-12">
                    {this.state.showLoadingTable ? (
                      <Alert
                        color="info"
                        className="mt-2"
                        isOpen={this.state.showLoadingTable}
                        toggle={() =>
                          this.setState({
                            showLoadingTable: false,
                          })
                        }
                      >
                        <strong>
                          <i className="fas fa-spinner fa-spin"></i>
                        </strong>{" "}
                        Loading variant stock product
                      </Alert>
                    ) : (
                      <div className="table-responsive mt-0">
                        <table className="styled-table-variant">
                          <thead>
                            <tr>
                              {variant ? (
                                this.state.dataOptions.length ? (
                                  <th style={{ width: "10%" }}>{variant}</th>
                                ) : null
                              ) : null}
                              {variant_second ? (
                                this.state.dataOptionSecond.length ? (
                                  <th style={{ width: "10%" }}>
                                    {variant_second}
                                  </th>
                                ) : null
                              ) : null}
                              <th style={{ width: "15%" }}>Price</th>
                              <th style={{ width: "15%" }}>Type Discount</th>
                              <th style={{ width: "8%" }}>Disc (%)</th>
                              <th style={{ width: "15%" }}>Reduction</th>
                              <th style={{ width: "7%" }}>Stock</th>
                              <th style={{ width: "15%" }}>Price Final</th>
                              <th style={{ width: "5%" }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataOptions.length
                              ? dataOptions.map((row) =>
                                  dataOptionSecond.length
                                    ? this.renderTable(dataOptionSecond, row)
                                    : this.renderTableOptionFirst(row)
                                )
                              : this.renderOneRow()}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>

                {/* {!this.state.isUpdate ? (
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="form-group">
                        <div>
                          <Button
                            color="primary"
                            type="button"
                            onClick={() => this.handleSubmit()}
                            disabled={this.state.showLoading}
                            // onClick={() => this.handleSaveFinish()}
                          >
                            {this.state.showLoading ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i> Executing your request
                              </div>
                            ) : (
                              "Save & Finish"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null} */}
              </CardBody>
            </Card>
          </div>
        </Row>
        {/* <ToastContainer /> */}
      </>
    );
  }
}
