import Categories from "views/pages/categories/Categories";
import Dashboard from "views/pages/Dashboard";
import BankInfo from "views/pages/information/BankInfo";
import Banner from "views/pages/information/Banner";
import ContactInfo from "views/pages/information/ContactInfo";
import GeneralInfo from "views/pages/information/GeneralInfo";
import Artikel from "views/pages/information/Artikel";
import GeneralSetting from "views/pages/information/GeneralSetting";
import LinkTree from "views/pages/information/LinkTree";
import ServiceInfo from "views/pages/information/ServiceInfo";
import Transaction from "views/pages/information/Transaction";
import Product from "views/pages/product/Product";
import UserManagement from "views/pages/user-management/UserManagement";
import VideoMaster from "views/pages/information/VideoMaster";
import MediaMaster from "views/pages/information/MediaMaster";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-bullet-list-67 text-red",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Product",
    icon: "ni ni-box-2 text-blue",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/information",
    name: "Information",
    icon: "ni ni-collection text-red",
    component: GeneralInfo,
    layout: "/admin",
  },
  {
    path: "/service",
    name: "Service",
    icon: "ni ni-delivery-fast text-blue",
    component: ServiceInfo,
    layout: "/admin",
  },
  {
    path: "/contact",
    name: "Contact",
    icon: "ni ni-badge text-green",
    component: ContactInfo,
    layout: "/admin",
  },
  {
    path: "/bank-info",
    name: "Bank",
    icon: "ni ni-building text-yellow",
    component: BankInfo,
    layout: "/admin",
  },
  {
    path: "/link-tree",
    name: "Linktree",
    icon: "ni ni-world-2 text-blue",
    component: LinkTree,
    layout: "/admin",
  },
  {
    path: "/banner",
    name: "Banner",
    icon: "ni ni-album-2 text-green",
    component: Banner,
    layout: "/admin",
  },
  {
    path: "/user-management",
    name: "User Management",
    icon: "ni ni-user-run text-red",
    component: UserManagement,
    layout: "/admin",
  },
  {
    path: "/transaction",
    name: "Transaction",
    icon: "ni ni-cart text-blue",
    component: Transaction,
    layout: "/admin",
  },
  {
    path: "/artikel",
    name: "Artikel",
    icon: "ni ni-laptop text-yellow",
    component: Artikel,
    layout: "/admin",
  },
  {
    path: "/video",
    name: "Video",
    icon: "ni ni-folder-17 text-red",
    component: VideoMaster,
    layout: "/admin",
  },
  {
    path: "/media",
    name: "Media",
    icon: "ni ni-button-play text-blue",
    component: MediaMaster,
    layout: "/admin",
  },
  {
    path: "/general-setting",
    name: "General Setting",
    icon: "ni ni-settings text-green",
    component: GeneralSetting,
    layout: "/admin",
  },

  // {
  //   path: "/auth/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth",
  // },
  // // {
  // //   path: "/auth/forgot-password",
  // //   name: "Forgot Password",
  // //   icon: "ni ni-key-25 text-info",
  // //   component: ForgotPassword,
  // //   // layout: "/auth",
  // // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  // },
];
export default routes;
