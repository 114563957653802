import Header from "components/Headers/Header";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { v4 as uuid } from "uuid";
import FormGeneral from "./FormGeneral";
import FormUploadProduct from "./FormUploadProduct";
// import SizeProduct from "./SizeProduct";
import FormVariantStock from "./FormVariantStock";
import FormVariantOptions from "./FormVariantOptions";

export default class FormProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      showLoadingCategory: false,
      showLoading: false,
      success: false,
      isUpdate: false,
      successMessage: "",
      errorMessage: "",
      uid: null,
      id_category: "",
      produkname: "",
      slugname: "",
      code: "",
      price: "0",
      discount: "0",
      weight: "0",
      stock: "0",
      description: "",
      status_posting: "",
      created_at: new Date(),
      updated_at: new Date(),
      categoryname: "",
      slugcategory: "",
      tabActive: "form-general",

      categoryOption: [],
      record: null,
    };
  }

  componentDidMount = () => {
    if (this.props.location.state) {
      this.editData(this.props.location.state);
    }
  };

  editData = (dt) => {
    const tambahan = {
      isUpdate: false,
    };
    const finalResult = Object.assign(dt, tambahan);
    this.setState({
      record: finalResult,
    });
  };

  handleChangeState = (input, value) => {
    this.setState({
      [input]: value,
    });
  };

  handleChangeTabActive = (tabActive) => {
    this.setState({
      tabActive,
    });
  };

  renderSwitch = (tabActive) => {
    switch (tabActive) {
      case "form-general":
        return (
          <FormGeneral
            {...this.state}
            {...this.props}
            record={this.state.record}
            handleChangeTabActive={this.handleChangeTabActive}
            handleChangeState={this.handleChangeState}
          />
        );
      case "form-upload":
        return (
          <FormUploadProduct
            {...this.state}
            {...this.props}
            record={this.state.record}
            handleChangeTabActive={this.handleChangeTabActive}
            handleChangeState={this.handleChangeState}
          />
        );
      case "form-variantoption":
        return (
          <FormVariantOptions
            {...this.state}
            {...this.props}
            record={this.state.record}
            handleChangeTabActive={this.handleChangeTabActive}
            handleChangeState={this.handleChangeState}
          />
        );
      case "form-variantstockproduct":
        return (
          <FormVariantStock
            {...this.state}
            {...this.props}
            record={this.state.record}
            handleChangeTabActive={this.handleChangeTabActive}
            handleChangeState={this.handleChangeState}
          />
        );

      default:
        return (
          <FormGeneral
            {...this.props}
            record={this.state.record}
            handleChangeTabActive={this.handleChangeTabActive}
            handleChangeState={this.handleChangeState}
          />
        );
      // break;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Form Product - Administrator Bikers Dream</title>
          <meta name="description" content="Form product page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <>{this.renderSwitch(this.state.tabActive)}</>
          <ToastContainer />
        </Container>
      </>
    );
  }
}
