import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
// import ChangePassword from "views/pages/ChangePassword";
// import Dashboard from "views/pages/Dashboard";
// import Categories from "views/pages/categories/Categories";
import listallroutes from "views/listallroutes";
import axiosCustom from "http/axios";
// import Product from "views/pages/product/Product";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [count, setCount] = React.useState(0);

  // React.useEffect(() => {
    
  // });

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    getTotalTrx()
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < listallroutes.length; i++) {
      if (
        props.location.pathname.indexOf(listallroutes[i].layout + listallroutes[i].path) !==
        -1
      ) {
        return listallroutes[i].name;
      }
      // return listallroutes[i].name;
    }
    return "Brand";
  };

  const getTotalTrx = async () => {
    try {
      const res = await axiosCustom.get("list-order", {
        params: {
          status_code: '2'
        }
      });
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result
          setCount(data.length)
        }
      } else {
        setCount(0)
      }
    } catch (err) {
      // Handle Error Here
      setCount(0)
    }
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        totalTrxWaiting={count}
        logo={{
          innerLink: "/admin/dashboard",
          imgSrc: require("../assets/img/logosidebar.png").default,
          // imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        {localStorage.getItem("token") ? (
          <Switch>
            {getRoutes(listallroutes)}
            {/* <Route path={"/admin/dashboard"} component={Dashboard} />
            <Route path={"/admin/categories"} component={Categories} />
            <Route path={"/admin/product"} component={Product} />
            <Route path={"/admin/change-password"} component={ChangePassword} /> */}
            <Redirect from="*" to="/admin/dashboard" />
          </Switch>
        ) : (
          <Redirect to="/auth/login" />
        )}

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
