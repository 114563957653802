/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Container,
  Media,
} from "reactstrap";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import { Helmet } from "react-helmet";
import Header from "components/Headers/Header";

export default class VideoMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      showModalPreviewImage: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      filteredData: [],
      value: "",
      showLoadingTable: false,
      showLoading: false,
      columns: [
        {
          title: "Title",
          dataIndex: "title",
          ellipsis: true,
          width: "55%",
          sorter: (a, b) => a.title.localeCompare(b.title),
        },
        {
          title: "URL Video",
          dataIndex: "url",
          width: "25%",
          sorter: (a, b) => a.url.localeCompare(b.url),
          render: (text, record) => (
            <a href={record.url} target="_blank">
              {text}
            </a>
          ),
        },
        // {
        //   title: "Created Date",
        //   dataIndex: "created_at",
        //   sorter: (a, b) =>
        //     moment(a.created_at).unix() - moment(b.created_at).unix(),
        //   render: (text, record) => <span>{formatDateTime(text)}</span>,
        // },
        // {
        //   title: "Updated Date",
        //   dataIndex: "updated_at",
        //   sorter: (a, b) =>
        //     moment(a.updated_at).unix() - moment(b.updated_at).unix(),
        //   render: (text, record) => <span>{formatDateTime(text)}</span>,
        // },
        {
          title: "Thumbnail",
          dataIndex: "link_icon",
          width: "15%",
          sorter: false,
          render: (text, record) => (
            <Media className="align-items-center">
              <a
                // className="avatar"
                // href="#pablo"
                onClick={() => this.previewModal(record)}
              >
                <img
                  alt="..."
                  src={text}
                  // style={{ borderRadius: "50%", maxHeight: "50px" }}
                  style={{ maxHeight: "50px", maxWidth: "80px" }}
                />
              </a>
            </Media>
          ),
        },

        {
          title: "Action",
          align: "center",
          sorter: false,
          width: "10%",
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      id_parent: "",
      title: "",
      old_icon: "",
      content: "",
      icon: "",
      url: "",
      previewImage: null,
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  componentDidMount = () => {
    this.getdata();
  };

  previewModal = (record) => {
    this.toggleModal("showModalPreviewImage");
    this.setState({
      id: record.id,
      previewImage: record.link_icon,
      isError: false,
      errorMessage: "",
    });
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("content/video");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: [],
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.title).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.url).toLowerCase().startsWith(value.toLowerCase());
        let includesCondition =
          String(item.title).toLowerCase().includes(value.toLowerCase()) ||
          String(item.url).toLowerCase().includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      id_parent: "",
      title: "",
      icon: "",
      old_icon: "",
      url: "",
      previewImage: null,
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: true,
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      icon: record.icon,
      url: record.url,
      old_icon: record.icon,
      previewImage: null,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      content: record.content,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    // const dataInput = {
    //   id_parent: this.state.id_parent,
    //   title: this.state.title,
    //   content: this.state.content,
    // };

    const dataInput = new FormData();
    // dataInput.append("title", this.state.title);
    dataInput.append("icon", this.state.icon);
    dataInput.append("url", this.state.url);
    dataInput.append("id_parent", this.state.id_parent);
    dataInput.append("title", this.state.title);
    dataInput.append("old_icon", this.state.old_icon);

    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axiosCustom.post(
          "content/video/" + this.state.id,
          dataInput
        );
      } else {
        res = await axiosCustom.post("content/video", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdata();

        this.toggleModal("showModalAdd");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("content/video/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  handleChangeImage = (e) => {
    this.setState({
      icon: e.target.files[0],
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    // console.log(this.state.data.length);
    return (
      <>
        <Helmet>
          <title>Video - Administrator Bikers Dream</title>
          <meta name="description" content="Video page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAdd"
                          onClick={() => this.openModal()}
                        >
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Form Video
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalAdd")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Title <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.title}
                          onChange={(e) =>
                            this.setState({ title: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          URL Video <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.url}
                          onChange={(e) =>
                            this.setState({ url: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Thumbnail <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          // value={this.state.icon}
                          onChange={
                            (e) => this.handleChangeImage(e)
                            // this.setState({ icon: e.target.files[0] })
                          }
                          accept="image/*"
                        />
                      </div>
                    </div>
                    {this.state.previewImage && (
                      <div className="col-md-6">
                        <div className="form-group">
                          <img
                            alt="example"
                            style={{
                              width: "100%",
                              height: "200px",
                              cursor: "pointer",
                            }}
                            src={this.state.previewImage}
                            onClick={() =>
                              this.toggleModal("showModalPreviewImage")
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "{this.state.title}
                  "?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalPreviewImage}
              toggle={() => this.toggleModal("showModalPreviewImage")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Thumbnail
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalPreviewImage")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={this.state.previewImage}
                />
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
