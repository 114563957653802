/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Helmet } from "react-helmet";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import moment from "moment";
import { formatDate } from "helper/helper";
import axiosCustom from "http/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDateTime } from "helper/helper";

export default class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      showSubcategory: false,
      isUpdate: false,
      data: [],
      filteredData: [],
      value: "",
      dataSubcategory: [],
      filteredDataSubcategory: [],
      valueSubcategory: "",
      showLoadingTableSubcategory: false,
      showLoadingTable: false,
      showLoading: false,
      columns: [
        {
          title: "Category Name",
          dataIndex: "categoryname",
          sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
        },
        {
          title: "Slug Name",
          dataIndex: "slugname",
          sorter: (a, b) => a.slugname.localeCompare(b.slugname),
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          sorter: (a, b) =>
            moment(a.created_at).unix() - moment(b.created_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },
        {
          title: "Updated Date",
          dataIndex: "updated_at",
          sorter: (a, b) =>
            moment(a.updated_at).unix() - moment(b.updated_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },
        {
          title: "Action",
          align: 'center',
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.getSubcategory(record)}>
                  <i className="fa fa-tasks" />
                  <span>Subcategory</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      columnsSubcategory: [
        {
          title: "Category Name",
          dataIndex: "categoryname",
          sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
        },
        {
          title: "Slug Name",
          dataIndex: "slugname",
          sorter: (a, b) => a.slugname.localeCompare(b.slugname),
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          sorter: (a, b) =>
            moment(a.created_at).unix() - moment(b.created_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },
        {
          title: "Updated Date",
          dataIndex: "updated_at",
          sorter: (a, b) =>
            moment(a.updated_at).unix() - moment(b.updated_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },
        {
          title: "Action",
          align: 'center',
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id_parent: "0",
      categorynameHeader: "",
      id: "",
      categoryname: "",
      slugname: "",
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  componentDidMount = () => {
    this.getdata();
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("list-parent");
      if (res.status === 200) {
        if (res.data.result) {
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: []
      });
    }
  };

  getSubcategory = (record) => {
    this.setState({
      id_parent: record.id,
      categorynameHeader: record.categoryname,
    })
    this.toggleModal("showSubcategory");
    this.getSubcategorylist(record.id)
  }

  getSubcategorylist = async (id) => {
    this.setState({
      showLoadingTableSubcategory: true,
      valueSubcategory: "",
    });

    try {
      const res = await axiosCustom.get("list-child/" + id);
      if (res.status === 200) {
        var dataSubcategory = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        // var dataSubcategory = res.data.result.sort(function (a, b) {
        //   return moment(b.updated_at).unix() - moment(a.updated_at).unix();
        // });
        this.setState({
          dataSubcategory,
        });
      }
      this.setState({
        showLoadingTableSubcategory: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTableSubcategory: false,
        dataSubcategory: []
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.categoryname)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.slugname).toLowerCase().startsWith(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .startsWith(value.toLowerCase());
        let includesCondition =
          String(item.categoryname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(item.slugname).toLowerCase().includes(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  handleFilterSubcategory = (e) => {
    let valueSubcategory = e.target.value;
    let dataSubcategory = this.state.dataSubcategory;
    let filteredDataSubcategory = this.state.filteredDataSubcategory;
    this.setState({ valueSubcategory });

    if (valueSubcategory.length) {
      filteredDataSubcategory = dataSubcategory.filter((item) => {
        let startsWithCondition =
          String(item.categoryname)
            .toLowerCase()
            .startsWith(valueSubcategory.toLowerCase()) ||
          String(item.slugname).toLowerCase().startsWith(valueSubcategory.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .startsWith(valueSubcategory.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .startsWith(valueSubcategory.toLowerCase());
        let includesCondition =
          String(item.categoryname)
            .toLowerCase()
            .includes(valueSubcategory.toLowerCase()) ||
          String(item.slugname).toLowerCase().includes(valueSubcategory.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .includes(valueSubcategory.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .includes(valueSubcategory.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredDataSubcategory });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdate: false,
      id: "",
      id_parent: "0",
      categoryname: "",
      slugname: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  openModalSubcateogry = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdate: false,
      id: "",
      categoryname: "",
      slugname: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdate: true,
      id: record.id,
      id_parent: record.id_parent,
      categoryname: record.categoryname,
      slugname: record.slugname,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      isUpdate: true,
      id: record.id,
      id_parent: record.id_parent,
      categoryname: record.categoryname,
      slugname: record.slugname,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = {
      categoryname: this.state.categoryname,
      id_parent: this.state.id_parent,
    };

    try {
      let res = {};
      if (this.state.isUpdate) {
        res = await axiosCustom.put("category/" + this.state.id, dataInput);
      } else {
        res = await axiosCustom.post("category", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (this.state.id_parent !== "0") {
          this.getSubcategorylist(this.state.id_parent);
        } else {
          this.getdata();
        }
        
        this.toggleModal("showModalAdd");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("category/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        if (this.state.id_parent !== "0") {
          this.getSubcategorylist(this.state.id_parent);
        } else {
          this.getdata();
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    const { 
      value, filteredData, data, showLoadingTable, columns,
      dataSubcategory,
      filteredDataSubcategory,
      valueSubcategory,
      showLoadingTableSubcategory,
      columnsSubcategory
     } = this.state;
    return (
      <>
        <Helmet>
          <title>Categories - Administrator Bikers Dream</title>
          <meta name="description" content="Categories page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                  
                </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAdd"
                          onClick={() => this.openModal()}
                        >
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    {
                      this.state.id_parent !== "0" ? "Form Subcategory" : "Form Categories"
                    }
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalAdd")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Category Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.categoryname}
                          onChange={(e) =>
                            this.setState({ categoryname: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "
                  {this.state.categoryname}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered modal-xl"
              isOpen={this.state.showSubcategory}
              toggle={() => this.toggleModal("showSubcategory")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Subcategory - {this.state.categorynameHeader}
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showSubcategory")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
              <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={valueSubcategory}
                          onChange={(e) => this.handleFilterSubcategory(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAddSubcategory"
                          onClick={() => this.openModalSubcateogry()}
                        >
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getSubcategorylist(this.state.id_parent)}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: valueSubcategory.length
                              ? filteredDataSubcategory.length
                              : dataSubcategory.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columnsSubcategory}
                          bordered
                          dataSource={valueSubcategory.length ? filteredDataSubcategory : dataSubcategory}
                          rowKey={(record) => record.id}
                          loading={showLoadingTableSubcategory}
                        />
                      </div>
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showSubcategory")}
                >
                  Close
                </Button>
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
