
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// import "assets/css/bootstrap.css";
// import "assets/css/bootstrap.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/scss/argon-dashboard-react.scss";
// import 'assets/css/font-awesome.min.css';
// import 'assets/css/line-awesome.min.css';

import AdminLayout from "layouts/Admin.js";
// import ForgotPassword from "views/auth/ForgotPassword";
// import Login from "views/auth/Login";
import AuthLayout from "views/auth/AuthLayout";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      {/* <Route path="/auth/forgot-password" render={(props) => <ForgotPassword {...props} />} />
      <Route path="/auth/login" render={(props) => <Login {...props} />} /> */}
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
