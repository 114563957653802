/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Container,
  Alert,
  Media,
  // Badge,
} from "reactstrap";
import Select from "react-select";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import { Helmet } from "react-helmet";
import Header from "components/Headers/Header";
import ReactPlayer from "react-player";
// import { renderStatus } from "helper/helper";
// import NumberFormat from "react-number-format";
// import Select from "react-select";

export default class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      showModalPreviewImage: false,
      showHighlightModal: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      filteredData: [],
      value: "",
      showLoadingTable: false,
      showLoading: false,

      columns: [
        {
          title: "Type",
          dataIndex: "flag",
          sorter: (a, b) => a.flag.localeCompare(b.flag),
          render: (text, record) => (
            <span>{text === "1" ? "Image" : "Video"}</span>
          ),
        },
        {
          title: "URL Video",
          dataIndex: "url_video",
          width: "50%",
          sorter: (a, b) =>
            String(a.url_video).localeCompare(String(b.url_video)),
          render: (text, record) => (
            <a href={text} target="_blank">
              {text}
            </a>
          ),
        },
        {
          title: "Image",
          dataIndex: "link_image",
          width: "30%",
          sorter: false,
          render: (text, record) =>
            text ? (
              <Media className="align-items-center">
                <a
                  // className="avatar"
                  // className="avatar rounded-circle"
                  // href="#pablo"
                  onClick={() => this.previewModal(record)}
                >
                  <img
                    alt="..."
                    src={text}
                    style={{ maxHeight: "50px", maxWidth: "100px" }}
                    // style={{ borderRadius: "50%", maxHeight: "50px" }}
                  />
                </a>
              </Media>
            ) : null,
        },
        {
          title: "Action",
          align: "center",
          width: "5%",
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>

                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      flag: "1",
      url_video: "",
      image: "",
      old_image: "",
      previewImage: null,
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
      typeOption: [
        {
          label: "Image",
          value: "1",
        },
        {
          label: "Video",
          value: "2",
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getdata();
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("content/slide");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: [],
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.flag).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.url_video).toLowerCase().startsWith(value.toLowerCase());
        let includesCondition =
          String(item.flag).toLowerCase().includes(value.toLowerCase()) ||
          String(item.url_video).toLowerCase().includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      flag: "1",
      url_video: "",
      image: "",
      old_image: "",
      previewImage: null,
      created_at: new Date(),
      updated_at: new Date(),
      isError: false,
      errorMessage: "",
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: true,
      id: record.id,
      // title: record.title,
      url_video: record.url_video,
      image: record.link_image,
      old_image: record.image,
      flag: record.flag,
      isError: false,
      errorMessage: "",
      previewImage: null,
      // contact: record.contact,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      // title: record.title,
      url_video: record.url_video,
      image: record.link_image,
      isError: false,
      errorMessage: "",
    });
  };
  previewModal = (record) => {
    this.toggleModal("showModalPreviewImage");
    this.setState({
      id: record.id,
      // title: record.title,
      previewImage: record.link_image,
      isError: false,
      errorMessage: "",
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = new FormData();
    dataInput.append("flag", this.state.flag);
    if (this.state.flag === "1") {
      dataInput.append("image", this.state.image);
      dataInput.append("old_image", this.state.old_image);
    } else {
      dataInput.append("url_video", this.state.url_video);
    }

    if (this.state.isUpdateForm) {
      this.actionSubmit(
        "content/slide/" + this.state.id,
        dataInput,
        "showModalAdd"
      );
    } else {
      this.actionSubmit("content/slide", dataInput, "showModalAdd");
    }
  };

  actionSubmit = (url, dataInput, modal) => {
    axiosCustom
      .post(url, dataInput, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal(modal);
        this.getdata();
      })
      .catch((err) => {
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("content/slide/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  handleChangeImage = (e) => {
    this.setState({
      image: e.target.files[0],
      previewImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Helmet>
          <title>Banner - Administrator Bikers Dream</title>
          <meta name="description" content="Banner page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        {/* {data.length === 5 ? null : (
                          <Button
                            color="primary"
                            data-toggle="modal"
                            data-target="#showModalAdd"
                            onClick={() => this.openModal()}
                          >
                            + Add data
                          </Button>
                        )} */}
                        <Button
                          color="primary"
                          data-toggle="modal"
                          data-target="#showModalAdd"
                          onClick={() => this.openModal()}
                        >
                          + Add data
                        </Button>

                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    {/* <div className="col-md-12">
                      <Alert
                        color="info"
                        isOpen={true}
                        // toggle={() =>
                        //   this.setState({
                        //     isError: false,
                        //   })
                        // }
                      >
                        <strong>Note: </strong> The highlight feature will run
                        when the data is 5
                      </Alert>
                    </div> */}
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Form Banner
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("showModalAdd")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Type Banner <span className="text-danger">*</span>
                        </label>
                        <Select
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) =>
                            this.setState({
                              flag: e !== null ? e.value : "",
                            })
                          }
                          options={this.state.typeOption}
                          value={this.state.typeOption.filter(
                            (obj) => obj.value === this.state.flag
                          )}
                          placeholder="Select type"
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.flag}
                          required
                          onChange={(e) =>
                            this.setState({ flag: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    {this.state.flag === "1" ? (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Image <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              // value={this.state.image}
                              onChange={
                                (e) => this.handleChangeImage(e)
                                // this.setState({ image: e.target.files[0] })
                              }
                              accept="image/*"
                              // required
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span className="text-danger">
                              <i>
                                (Note: recommendation 1920 x 600 pixel)
                                {/* (Note: recommendation 472 x 314, for highlight
                                image recommendation 955 x 638) */}
                              </i>
                            </span>
                          </div>
                        </div>
                        {this.state.previewImage && (
                          <div className="col-md-12">
                            <div className="form-group">
                              <img
                                alt="example"
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  cursor: "pointer",
                                }}
                                src={this.state.previewImage}
                                onClick={() =>
                                  this.toggleModal("showModalPreviewImage")
                                }
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              URL Video <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              value={this.state.url_video}
                              onChange={(e) =>
                                this.setState({ url_video: e.target.value })
                              }
                              required
                            />
                          </div>
                        </div>
                        {this.state.url_video !== "" ? (
                          <div className="col-md-12">
                            <div>
                              <ReactPlayer
                                url={this.state.url_video}
                                playing={false}
                                loop={true}
                                width={"100%"}
                                height="300px"
                                style={{ objectFit: "fill" }}
                                // height={"100%"}
                                controls={true}
                              />
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}

                    {/* <div className="col-md-12">
                      <div className="form-group">
                        <label>Highlight</label>
                        <br />
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={this.state.hg === '0' ? false : true}
                          onChange={(checked) =>
                            this.setState({
                              hg: checked === true ? '1' : '0',
                            })
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                {this.state.isError ? (
                  // <div className="row">
                  <div className="col-md-12">
                    <Alert
                      color="danger"
                      isOpen={this.state.isError}
                      toggle={() =>
                        this.setState({
                          isError: false,
                        })
                      }
                    >
                      <strong>Failed!</strong> {this.state.errorMessage}
                    </Alert>
                  </div>
                ) : // </div>
                null}
                <div className="modal-footer">
                  <Button
                    color="primary"
                    type="submit"
                    disabled={this.state.showLoading}
                  >
                    {this.state.showLoading ? (
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </form>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalPreviewImage}
              toggle={() => this.toggleModal("showModalPreviewImage")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Image
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalPreviewImage")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <img
                  alt="example"
                  style={{ width: "100%", height: "100%" }}
                  src={this.state.previewImage}
                />
              </div>
            </Modal>

            {/* <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal> */}

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data ?
                  {/* {this.state.url}"? */}
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showHighlightModal}
              toggle={() => this.toggleModal("showHighlightModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Set Highlight Banner
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showHighlightModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to change highlight this banner ?</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showHighlightModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processHighlightBanner()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Change to default"
                  )}
                </Button>
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
