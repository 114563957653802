import Header from "components/Headers/Header";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Card, CardBody, Row, Container, Button, Alert } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosCustom from "http/axios";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpass: "",
      confpassword: "",
      newpass: "",
      isError: false,
      showLoading: false,
      success: false,
      successMessage: "",
      errorMessage: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    if (this.state.newpass !== this.state.confpassword) {
      this.setState({
        showLoading: false,
        isError: true,
        errorMessage:
          "Confirm new password must be same as new password, please try again",
      });
    } else {
      try {
        const res = await axiosCustom.post("change-password", {
          id: userInfo.id,
          oldpass: this.state.oldpass,
          newpass: this.state.newpass,
          confpassword: this.state.confpassword,
        });
        // console.log(res);

        if (res.status === 200) {
          this.setState({
            showLoading: false,
          });

          toast.success(res.data.messages, {
            position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/auth/login";
          }, 1000);
        }
      } catch (err) {
        // Handle Error Here
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };
  render() {
    return (
      <>
        <Helmet>
          <title>Change Password - Administrator Bikers Dream</title>
          <meta name="description" content="Change password page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Old Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={this.state.oldpass}
                            onChange={(e) =>
                              this.setState({ oldpass: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            New Password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={this.state.newpass}
                            onChange={(e) =>
                              this.setState({ newpass: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Confirm Password{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={this.state.confpassword}
                            onChange={(e) =>
                              this.setState({ confpassword: e.target.value })
                            }
                            required
                          />
                        </div>
                      </div>
                      {this.state.isError ? (
                        <div className="col-lg-12">
                          <Alert
                            color="danger"
                            isOpen={this.state.isError}
                            toggle={() =>
                              this.setState({
                                isError: false,
                              })
                            }
                          >
                            <strong>Error!</strong> {this.state.errorMessage}
                          </Alert>
                        </div>
                      ) : null}
                      <div className="col-md-12">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={this.state.showLoading}
                        >
                          {this.state.showLoading ? (
                            <div>
                              <i className="fas fa-circle-notch fa-spin"></i>{" "}
                              Executing your request
                            </div>
                          ) : (
                            "Save Changes"
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </div>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
