/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
// import NumberFormat from "react-number-format";
import Select from "react-select";
import { Spin } from "antd";

export default class AddressOrigin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      showLoading: false,

      id: "",
      setcode: "address_origin",
      label: "",
      value: "",
      file_image: "",
      file_url: "",
      created_at: new Date(),
      updated_at: new Date(),

      province: "",

      provinceOption: [],
      cityOption: [],

      showLoadingProvince: false,
      showLoadingCity: false,
    };
  }

  componentDidMount = () => {
    this.getdata();
    this.getProvince();
  };

  // getProvince = async () => {
  //   this.setState({
  //     showLoadingProvince: true,
  //   });
  //   try {
  //     const res = await axiosCustom.get("propinsi");
  //     // console.log(res);
  //     if (res.status === 200) {
  //       var dt = res.data.result;
  //       var provinceOption = dt.map((item) => {
  //         return {
  //           value: item.id,
  //           label: item.name,
  //         };
  //       });

  //       this.setState({
  //         provinceOption,
  //       });
  //     } else {
  //       this.setState({
  //         provinceOption: [],
  //       });
  //     }
  //     this.setState({
  //       showLoadingProvince: false,
  //     });
  //   } catch (err) {
  //     // Handle Error Here
  //     this.setState({
  //       showLoadingProvince: false,
  //     });
  //     var msg = err ? err.message : "Error invalid";
  //     toast.error(msg, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };
  getProvince = async () => {
    this.setState({
      showLoadingProvince: true,
    });
    try {
      const res = await axiosCustom.get("ongkir/wilayah/province");
      console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var provinceOption = dt.map((item) => {
          return {
            value: item.province_id,
            label: item.province,
          };
        });

        this.setState({
          provinceOption,
        });
      } else {
        this.setState({
          provinceOption: [],
        });
      }
      this.setState({
        showLoadingProvince: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingProvince: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCity = async (id) => {
    this.setState({
      showLoadingCity: true,
    });
    try {
      const res = await axiosCustom.get("ongkir/wilayah/city/" + id);
      // console.log(res);
      if (res.status === 200) {
        var dt = res.data.rajaongkir.results;
        var cityOption = dt.map((item) => {
          return {
            value: item.city_id,
            label: item.city_name,
            province_id: item.province_id,
            province: item.province,
            type: item.type,
            postal_code: item.postal_code,
          };
        });

        this.setState({
          cityOption,
        });
      } else {
        this.setState({
          cityOption: [],
        });
      }
      this.setState({
        showLoadingCity: false,
      });
    } catch (err) {
      // Handle Error Here
      this.setState({
        showLoadingCity: false,
      });
      var msg = err ? err.message : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // getCity = async (id) => {
  //   this.setState({
  //     showLoadingCity: true,
  //   });
  //   try {
  //     const res = await axiosCustom.get("kabupaten/" + id);
  //     // console.log(res);
  //     if (res.status === 200) {
  //       var dt = res.data.result;
  //       var cityOption = dt.map((item) => {
  //         return {
  //           value: item.id,
  //           label: item.name,
  //         };
  //       });

  //       this.setState({
  //         cityOption,
  //       });
  //     } else {
  //       this.setState({
  //         cityOption: [],
  //       });
  //     }
  //     this.setState({
  //       showLoadingCity: false,
  //     });
  //   } catch (err) {
  //     // Handle Error Here
  //     this.setState({
  //       showLoadingCity: false,
  //     });
  //     var msg = err ? err.message : "Error invalid";
  //     toast.error(msg, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //   }
  // };
  
  getdata = async () => {
    this.setState({
      showLoading: true,
      // value: "",
    });

    try {
      const res = await axiosCustom.get("setting/" + this.state.setcode);
      if (res.status === 200) {
        if (res.data.result !== "") {
          // var data = res.data.result
          var data = res.data.result;
          this.setState({
            id: data.id,
            label: data.label,
            value: data.value,
            file_image: data.file_image,
            file_url: data.file_url,
          });

          this.getCity(data.label)
        }
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
        data: [],
      });
    }
  };

  handleSubmit = async () => {
    // e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = new FormData();
    dataInput.append("label", this.state.label);
    dataInput.append("value", this.state.value);

    this.actionSubmit("setting/" + this.state.setcode, dataInput);
  };

  actionSubmit = (url, dataInput) => {
    axiosCustom
      .post(url, dataInput, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.toggleModal("showModalAdd");
        // this.getdata();
      })
      .catch((err) => {
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  handleChangeProvince = (e) => {
    if (e !== null) {
      this.setState({
        label: e.value,
      });
      this.getCity(e.value);
    } else {
      this.setState({
        label: "",
        value: "",
        cityOption: [],
      });
    }
  };
  handleChangeCity = (e) => {
    if (e !== null) {
      this.setState({
        value: e.value,
      });
    }
  };

  render() {
    const {
      // id,
      // setcode,
      label,
      // value,
      // file_image,
      // file_ur,
    } = this.state;
    return (
      <>
        <Row>
          <div className="col">
            <Spin spinning={this.state.showLoading}>
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Store Address</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <Select
                                isClearable={true}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                menuShouldScrollIntoView={false}
                                onChange={(e) => this.handleChangeProvince(e)}
                                // onChange={(e) =>
                                //   this.setState({
                                //     province: e !== null ? e.value : "",
                                //   })
                                // }
                                options={this.state.provinceOption}
                                value={this.state.provinceOption.filter(
                                  (obj) => obj.value === label
                                )}
                                isDisabled={this.state.showLoadingProvince}
                                isLoading={this.state.showLoadingProvince}
                                placeholder="Select province"
                              />
                              <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{
                                  opacity: 0,
                                  height: 0,
                                  position: "absolute",
                                }}
                                value={label}
                                required
                                onChange={(e) =>
                                  this.setState({ label: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-lg-5">
                              <Select
                                isClearable={true}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                menuPosition={"fixed"}
                                menuShouldScrollIntoView={false}
                                onChange={(e) => this.handleChangeCity(e)}
                                // onChange={(e) =>
                                //   this.setState({
                                //     value: e !== null ? e.value : "",
                                //   })
                                // }
                                options={this.state.cityOption}
                                value={this.state.cityOption.filter(
                                  (obj) => obj.value === this.state.value
                                )}
                                isDisabled={this.state.showLoadingCity}
                                isLoading={this.state.showLoadingCity}
                                placeholder="Select city"
                              />
                              <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{
                                  opacity: 0,
                                  height: 0,
                                  position: "absolute",
                                }}
                                value={this.state.value === ""}
                                required
                                onChange={(e) =>
                                  this.setState({ value: e.target.value })
                                }
                              />
                            </div>
                            <div className="col-lg-3">
                              <Button
                                color="primary"
                                type="button"
                                disabled={this.state.showLoading}
                                onClick={() => this.handleSubmit()}
                              >
                                {this.state.showLoading ? (
                                  <div>
                                    <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                    Executing your request
                                  </div>
                                ) : (
                                  "Save Changes"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span className="text-danger">
                              <i>
                                (Note: The selected city will be the reference
                                for the origin of the shipment)
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Spin>
          </div>
        </Row>

        <ToastContainer />
      </>
    );
  }
}
