import Header from "components/Headers/Header";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  Row,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormGeneral from "./FormGeneral";
import FormUploadProduct from "./FormUploadProduct";
// import SizeProduct from "./SizeProduct";
import FormVariantOptions from "./FormVariantOptions";
import FormVariantStockDetail from "./FormVariantStockDetail";
import { Link } from "react-router-dom";

export default class FormProductDetail extends Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "product-detail",
      record: null,
      uid: null,
      isUpdate: true,
    };
  }

  componentDidMount = () => {
    // console.log(this.props);
    if (this.props) {
      this.setState({
        uid: this.props.match.params.uid,
        activeTab: this.props.match.params.tabid,
      });

      if (this.state.activeTab !== this.props.match.params.tabid) {
        this.setState({
          activeTab: this.props.match.params.tabid,
        });
      }
    }
    // if (this.props.location.state) {
    //   this.editData(this.props.location.state);
    // }
  };

  componentDidUpdate = () => {
    if (this.props) {
      if (this.state.activeTab !== this.props.match.params.tabid) {
        this.setState({
          uid: this.props.match.params.uid,
          activeTab: this.props.match.params.tabid,
        });
      }
    }
  };

  editData = (dt) => {
    const tambahan = {
      isUpdate: true,
    };
    const finalResult = Object.assign(dt, tambahan);
    this.setState({
      record: finalResult,
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderSwitch = (tabActive) => {
    switch (tabActive) {
      case "product-detail":
        return (
          <FormGeneral
            {...this.props}
            {...this.state}
            record={this.state.record}
          />
        );
      case "product-images":
        return (
          <FormUploadProduct
            {...this.props}
            {...this.state}
            record={this.state.record}
          />
        );
      case "variant-option":
        return (
          <FormVariantOptions
            {...this.props}
            {...this.state}
            record={this.state.record}
          />
        );
      // return <SizeProduct {...this.props} record={this.state.record} />;
      case "variant-stock":
        return (
          <FormVariantStockDetail
            {...this.props}
            {...this.state}
            record={this.state.record}
          />
        );

      default:
        return (
          <FormGeneral
            {...this.props}
            {...this.state}
            record={this.state.record}
          />
        );
      // break;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Form Product Detail - Administrator Bikers Dream</title>
          <meta name="description" content="Form product detail page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "product-detail",
                        })}
                        // onClick={() => {
                        //   this.toggle("product-detail");
                        // }}
                        to={{
                          pathname: `/admin/form-product-detail/product-detail/${this.state.uid}`,
                        }}
                        tag={Link}
                      >
                        Product Detail
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "product-images",
                        })}
                        // onClick={() => {
                        //   this.toggle("product-images");
                        // }}
                        to={{
                          pathname: `/admin/form-product-detail/product-images/${this.state.uid}`,
                        }}
                        tag={Link}
                      >
                        Product Images
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "variant-option",
                        })}
                        // onClick={() => {
                        //   this.toggle("variant-option");
                        // }}
                        to={{
                          pathname: `/admin/form-product-detail/variant-option/${this.state.uid}`,
                        }}
                        tag={Link}
                      >
                        Variant Option
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "variant-stock",
                        })}
                        // onClick={() => {
                        //   this.toggle("variant-stock");
                        // }}
                        to={{
                          pathname: `/admin/form-product-detail/variant-stock/${this.state.uid}`,
                        }}
                        tag={Link}
                      >
                        Variant Stock
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={this.state.activeTab}>
                      <Row>
                        <Col sm="12">
                          {this.renderSwitch(this.state.activeTab)}
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="product-images">
                      <Row>
                        <Col sm="12">
                          <FormUploadProduct
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="variant-option">
                      <Row>
                        <Col sm="12">
                          <SizeProduct
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="variant-stock">
                      <Row>
                        <Col sm="12">
                          <FormVariantStockDetail
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
          <ToastContainer />
        </Container>
      </>
    );
  }
}
