/* eslint-disable no-script-url */
import Header from "components/Headers/Header";
import React, { Component } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardHeader,
  Button,
  Table,
} from "reactstrap";

import { Helmet } from "react-helmet";
import axiosCustom from "http/axios";
import { Spin } from "antd";
import moment from "moment";
import { renderStatusTrx } from "helper/helper";
import { formatDateTimePlus } from "helper/helper";
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showLoadingTable: false,
      dt_product_posted: 0,
      dt_product_drafted: 0,
      dt_new_order: 0,
      dt_retur: 0,
      dataTrx: [],
    };
  }

  componentDidMount = () => {
    if (localStorage.getItem("firstlogin")) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      toast.info("Welcome, " + userInfo.fullname + "!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      localStorage.removeItem("firstlogin");
    }

    this.getdata();
    this.getdataTrx();
  };

  getdataTrx = async (statusFilter = null) => {
    this.setState({
      showLoadingTable: true,
    });

    try {
      const res = await axiosCustom.get("list-order", {
        params: {
          status_code: "2",
        },
      });
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return moment(b.updated_at).unix() - moment(a.updated_at).unix();
          });

          var dataTrx = data.slice(0, 10);
          this.setState({
            dataTrx,
          });
        }
      } else {
        this.setState({
          dataTrx: [],
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        dataTrx: [],
      });
    }
  };

  getdata = async () => {
    this.setState({
      showLoading: true,
    });

    try {
      const res = await axiosCustom.get("dashboard");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result;
          this.setState({
            dt_product_posted: data.dt_product_posted,
            dt_product_drafted: data.dt_product_drafted,
            dt_new_order: data.dt_new_order,
            dt_retur: data.dt_retur,
          });
        }
      } else {
        this.setState({
          dt_product_posted: 0,
          dt_product_drafted: 0,
          dt_new_order: 0,
          dt_retur: 0,
        });
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
        dt_product_posted: 0,
        dt_product_drafted: 0,
        dt_new_order: 0,
        dt_retur: 0,
      });
    }
  };

  gotoTrx = () => {
    this.props.history.push({
      pathname: "/admin/transaction",
      state: '2'
    });
  }

  render() {
    const { dt_product_posted, dt_product_drafted, dt_new_order, dt_retur } =
      this.state;
    return (
      <>
        <Helmet>
          <title>Dashboard - Administrator Bikers Dream</title>
          <meta name="description" content="Dashboard page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
        
            <Row>
              <div className="col-md-12">
              <Spin spinning={this.state.showLoading}>
                <Card className="bg-gradient-default shadow">
                  <CardBody>
                    {/* <Row>
                  <div className="col-md-12"> */}
                    <div className="row">
                      <div className="col-md-3">
                        <Card className="card-stats mb-4 mb-lg-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Product Draft
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {dt_product_drafted}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                  <i className="fas fa-box" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-md-3">
                        <Card className="card-stats mb-4 mb-lg-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Product Posted
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {dt_product_posted}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                  <i className="fas fa-box-open" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-md-3">
                        <Card className="card-stats mb-4 mb-lg-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Total Retur
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {dt_retur}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                  <i className="fas fa-undo" />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-md-3">
                        <Card className="card-stats mb-4 mb-lg-0">
                          <CardBody>
                            <Row>
                              <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0">
                                  Total New Order
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0">
                                  {dt_new_order}
                                </span>
                              </div>
                              <Col className="col-auto">
                                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                  <i className="fa fa-shopping-cart" />
                                </div>
                              </Col>
                            </Row>
                            {/* <p className="mt-3 mb-0 text-muted text-sm">
                            <span className="text-success mr-2">
                              <i className="fa fa-arrow-up" />
                              3.48%
                            </span>
                            <span className="text-nowrap">
                              Since last month
                            </span>
                          </p> */}
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                    {/* </div>
                </Row> */}
                  </CardBody>
                </Card>
                </Spin>
              </div>
            </Row>

            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="bg-gradient-default shadow">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="mb-0">Top Waiting Confirmation</h3>
                        </div>
                        <div className="col text-right">
                          <Button
                            color="primary"
                            href="javascript:void(0)"
                            onClick={() => this.gotoTrx()}
                            // size="sm"
                          >
                            See all
                          </Button>
                        </div>
                      </Row>
                    </CardHeader>
                    <Spin spinning={this.state.showLoadingTable}>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Invoice</th>
                          <th className="text-right">Total Payment</th>
                          <th>Customer</th>
                          <th>Email</th>
                          <th className="text-center">Status</th>
                          <th>Updated Date</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        {this.state.dataTrx.length
                          ? this.state.dataTrx.map((item) => (
                              <tr>
                                <td>{item.invoice}</td>
                                <td className="text-right">{item.total_payment}</td>
                                <td>{item.fullname}</td>
                                <td>{item.useremail}</td>
                                <td className="text-center">{renderStatusTrx(item)}</td>
                                <td>
                                <span>{formatDateTimePlus(item.updated_at)}</span>
                                </td>
                              </tr>
                            ))
                          : (
                            <tr>
                                <td colSpan={6} className="text-center">No items</td>
                              </tr>
                          )}
                      </tbody>
                    </Table>
                      </Spin>
                  </Card>
                </Card>
              </Col>
            </Row>
       
        </Container>
        <ToastContainer />
      </>
    );
  }
}
