/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
} from "reactstrap";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
import { formatDate } from "helper/helper";
import { formatDateTime } from "helper/helper";
export default class CourierMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      filteredData: [],
      value: "",
      showLoadingTable: false,
      showLoading: false,
      columns: [
        {
          title: "Courier Code",
          dataIndex: "courier_code",
          sorter: (a, b) => a.courier_code.localeCompare(b.courier_code),
        },
        {
          title: "Courier Service",
          dataIndex: "courier_service",
          sorter: (a, b) => a.courier_service.localeCompare(b.courier_service),
        },
        {
          title: "Created Date",
          dataIndex: "created_at",
          sorter: (a, b) =>
            moment(a.created_at).unix() - moment(b.created_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },
        {
          title: "Updated Date",
          dataIndex: "updated_at",
          sorter: (a, b) =>
            moment(a.updated_at).unix() - moment(b.updated_at).unix(),
          render: (text, record) => <span>{formatDateTime(text)}</span>,
        },

        {
          title: "Action",
          align: 'center',
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={() => this.editData(record)}>
                  <i className="ni ni-settings" />
                  <span>Edit</span>
                </DropdownItem>
                <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      courier_code: "",
      courier_name: "",
      courier_service: "",
      courier_service_description: "",
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  componentDidMount = () => {
    this.getdata()
  };
  

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("master-courier");
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: []
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.courier_code).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.courier_service).toLowerCase().startsWith(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .startsWith(value.toLowerCase());
        let includesCondition =
          String(item.courier_code).toLowerCase().includes(value.toLowerCase()) ||
          String(item.courier_service).toLowerCase().includes(value.toLowerCase()) ||
          String(formatDate(item.created_at))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(formatDate(item.updated_at))
            .toLowerCase()
            .includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      courier_code: "",
      courier_name: "",
      courier_service: "",
      courier_service_description: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: true,
      id: record.id,
      courier_code: record.courier_code,
      courier_name: record.courier_name,
      courier_service: record.courier_service,
      courier_service_description: record.courier_service_description,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      courier_code: record.courier_code,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = {
      courier_code: this.state.courier_code,
      courier_name: this.state.courier_name,
      courier_service: this.state.courier_service,
      courier_service_description: this.state.courier_service_description,
    };

    try {
      let res = {};
      if (this.state.isUpdateForm) {
        res = await axiosCustom.put("master-courier/" + this.state.id, dataInput);
      } else {
        res = await axiosCustom.post("master-courier", dataInput);
      }
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.getdata();

        this.toggleModal("showModalAdd");
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("master-courier/" + this.state.id);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };


  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              {/* <CardHeader className="border-0">
                
              </CardHeader> */}
              <CardBody>
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        value={value}
                        onChange={(e) => this.handleFilter(e)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3"></div>
                  <div className="col-sm-6 col-md-3"></div>
                  <div className="col-sm-6 col-md-3">
                    <div className="float-right">
                      <Button
                        color="primary"
                        data-toggle="modal"
                        data-target="#showModalAdd"
                        onClick={() => this.openModal()}
                      >
                        + Add data
                      </Button>
                      <Button color="primary" onClick={() => this.getdata()}>
                        Refresh
                      </Button>
                    </div>
                  </div>
                </div>
                {/* Search Filter */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <Table
                        className="table-hover"
                        pagination={{
                          total: value.length
                            ? filteredData.length
                            : data.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        bordered
                        dataSource={value.length ? filteredData : data}
                        rowKey={(record) => record.id}
                        loading={showLoadingTable}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <Modal
            className="modal-dialog-centered modal-lg"
            isOpen={this.state.showModalAdd}
            toggle={() => this.toggleModal("showModalAdd")}
          >
            <form onSubmit={this.handleSubmit}>
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                Form Courier Master
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalAdd")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Courier Code <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.courier_code}
                        onChange={(e) =>
                          this.setState({ courier_code: e.target.value })
                        }
                        required
                        placeholder="ex: JNE, Tiki, Sicepat ..."
                      />
                    
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Courier Service <span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.courier_service}
                        onChange={(e) =>
                          this.setState({ courier_service: e.target.value })
                        }
                        required
                        placeholder="ex: REG, YES, Express ..."
                      />
                    
                    </div>
                  </div>
          
                </div>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.showLoading}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i>{" "}
                      Executing your request
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </form>
          </Modal>

          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showDeleteModal}
            toggle={() => this.toggleModal("showDeleteModal")}
          >
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Delete data
              </h3>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("showDeleteModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure you want to delete this data "
                {this.state.courier_code}"?
              </p>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("showDeleteModal")}
              >
                Close
              </Button>
              <Button
                color="primary"
                type="button"
                disabled={this.state.showLoading}
                onClick={() => this.processDelete()}
              >
                {this.state.showLoading ? (
                  <div>
                    <i className="fas fa-circle-notch fa-spin"></i> Executing
                    your request
                  </div>
                ) : (
                  "Delete"
                )}
              </Button>
            </div>
          </Modal>

        </Row>

        <ToastContainer />
    </>
    );
  }
}
