import axios from "axios";

const axiosCustom = axios.create({
  // timeout: 300000,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    'Accept' : 'application/json, text/plain, */*',
    'Access-Control-Allow-Methods' : 'GET, PUT, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials' : true,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Expose-Headers':'*'
  },
});

axiosCustom.defaults.baseURL = 'https://webservice.bikersdream.id/';
// axiosCustom.defaults.baseURL = 'https://bikerapi.superteam.web.id/';

axiosCustom.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);


axiosCustom.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const { response } = error;
    if (response && response.status === 401) {
      // localStorage.removeItem('userInfo')
      // localStorage.removeItem('allEnumOption')
      // localStorage.removeItem('allGlobalMasterOption')
      localStorage.clear();
      localStorage.setItem("tokenExpired", true);
      window.location.href = "/auth/login";

    }

    return Promise.reject(error);
  }
);


export default axiosCustom;