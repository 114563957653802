import React, { Component } from "react";
import { Card, CardBody, Row, Button, Alert, CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosCustom from "http/axios";
import Select from "react-select";
// import moment from "moment";
import NumberFormat from "react-number-format";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { v4 as uuid } from "uuid";
// import { removetwoZero } from "helper/helper";

export default class FormGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      showLoadingCategory: false,
      showLoading: false,
      success: false,
      isUpdate: false,
      successMessage: "",
      errorMessage: "",
      uid: uuid(),
      id_category: "",
      produkname: "",
      slugname: "",
      code: "",
      price: "0",
      discount: "0",
      weight: "0",
      stock: "0",
      description: "",
      status_posting: "",
      created_at: new Date(),
      updated_at: new Date(),
      categoryname: "",
      slugcategory: "",

      categoryOption: [],
      editorState: EditorState.createEmpty(),
    };
  }

  componentDidMount = () => {
    this.getCategory();
    // if (this.props.uid) {
    //   this.getProductDetail(this.props.uid);
    // }
    if (this.props.uid || this.props.record) {
      // this.editData(this.props.uid);
      if (this.props.isUpdate === false) {
        console.log("componentdidmount isupdate false");
        this.getProductDetail(this.props.record.uid);
      } else {
        console.log("componentdidmount isupdate true");
        this.getProductDetail(this.props.uid);
      }
    }
  };

  componentDidUpdate(prevProps) {
    // if (this.props.uid !== prevProps.uid) {
    //   // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
    //   this.getProductDetail(this.props.uid);
    // }
    if (
      this.props.uid !== prevProps.uid ||
      this.props.record !== prevProps.record
    ) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      if (this.props.isUpdate === false) {
        console.log("componentDidUpdate isupdate false");
        this.getProductDetail(this.props.record.uid);
      } else {
        console.log("componentDidUpdate isupdate true");
        this.getProductDetail(this.props.uid);
      }
    }
  }

  getProductDetail = async (uid) => {
    try {
      const res = await axiosCustom.get("product/" + uid);
      if (res.status === 200) {
        var data = res.data.result;
        this.editData(data);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  editData = (record) => {
    const contentBlock = htmlToDraft(record.description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
    this.setState({
      isUpdate: true,
      uid: record.uid,
      id_category: record.id_category,
      produkname: record.produkname,
      code: record.code,
      // price: removetwoZero(record.price),
      // discount: record.discount,
      weight: record.weight,
      // stock: record.stock,
      description: record.description,
    });
  };

  getProductDetail = async (uid) => {
    try {
      const res = await axiosCustom.get("product/" + uid);
      if (res.status === 200) {
        var data = res.data.result;
        this.editData(data);
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getCategory = async () => {
    this.setState({
      showLoadingCategory: true,
    });

    try {
      const res = await axiosCustom.get("list-child");
      if (res.status === 200) {
        var data = res.data.result;
        // var data = res.data.result.sort(function (a, b) {
        //   return moment(b.updated_at).unix() - moment(a.updated_at).unix();
        // });
        var categoryOption = data.map((item) => {
          return {
            value: item.id,
            label: item.categoryname,
          };
        });

        this.setState({
          categoryOption,
        });
      } else {
        this.setState({
          categoryOption: [],
        });
      }
      this.setState({
        showLoadingCategory: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingCategory: false,
        categoryOption: [],
      });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.handleAction();

    // this.setState({
    //   showLoading: true,
    // });

    // const dataInput = {
    //   uid: this.state.uid,
    //   id_category: this.state.id_category,
    //   produkname: this.state.produkname,
    //   code: this.state.code,
    //   price: String(this.state.price),
    //   discount: String(this.state.discount),
    //   weight: String(this.state.weight),
    //   stock: String(this.state.stock),
    //   description: this.state.description,
    // };
    // // console.log(dataInput);

    // try {
    //   let res = {};
    //   if (this.state.isUpdate) {
    //     res = await axiosCustom.put("product/" + this.state.uid, dataInput);
    //   } else {
    //     res = await axiosCustom.post("product", dataInput);
    //   }
    //   // res = await axiosCustom.post("product", dataInput);

    //   if (res.status === 200) {
    //     this.setState({
    //       showLoading: false,
    //     });
    //     toast.success(res.data.messages, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //     localStorage.setItem("successSubmit", true);
    //     setTimeout(() => {
    //       this.props.history.push({
    //         pathname: "/admin/product",
    //       });
    //     }, 800);
    //   }
    // } catch (err) {
    //   // Handle Error Here
    //   var msg = err ? err.response.data.messages : "Error invalid";
    //   toast.error(msg, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   this.setState({
    //     showLoading: false,
    //   });
    // }
  };

  handleSaveFinish = () => {
    if (this.state.code === "") {
      this.setState({
        isError: true,
        errorMessage: "Field product code is required!",
      });
    } else if (this.state.produkname === "") {
      this.setState({
        isError: true,
        errorMessage: "Field product name is required!",
      });
    } else if (this.state.id_category === "") {
      this.setState({
        isError: true,
        errorMessage: "Field category is required!",
      });
    } else if (this.state.price === "") {
      this.setState({
        isError: true,
        errorMessage: "Field price is required!",
      });
    } else if (this.state.stock === "") {
      this.setState({
        isError: true,
        errorMessage: "Field stock is required!",
      });
    } else {
      this.setState({
        isError: false,
        errorMessage: "",
      });
      this.handleAction(true);
    }
  };

  handleAction = async (finish = false) => {
    this.setState({
      showLoading: true,
    });

    const dataInput = {
      uid: this.state.uid,
      id_category: this.state.id_category,
      produkname: this.state.produkname,
      code: this.state.code,
      // price: String(this.state.price),
      // discount: String(this.state.discount),
      weight: String(this.state.weight),
      // stock: String(this.state.stock),
      description: this.state.description,
    };
    // console.log(dataInput);

    try {
      let res = {};
      if (this.state.isUpdate) {
        res = await axiosCustom.put("product/" + this.state.uid, dataInput);
      } else {
        res = await axiosCustom.post("product", dataInput);
      }
      // res = await axiosCustom.post("product", dataInput);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (!this.state.isUpdate) {
          if (finish) {
            localStorage.setItem("successSubmit", true);
            setTimeout(() => {
              this.props.history.push({
                pathname: "/admin/product",
              });
            }, 800);
          } else {
            const tambahan = {
              isUpdate: false,
            };
            const finalResult = Object.assign(dataInput, tambahan);
            this.props.handleChangeState("record", finalResult);
            setTimeout(() => {
              this.props.handleChangeTabActive("form-upload");
            }, 800);
          }
        }
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Item Product</h3>
              </CardHeader>
              <CardBody>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Product Code <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.code}
                          onChange={(e) =>
                            this.setState({ code: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Product Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.produkname}
                          onChange={(e) =>
                            this.setState({ produkname: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Category
                          <span className="text-danger">*</span>
                        </label>
                        <Select
                          isClearable={true}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) =>
                            this.setState({
                              id_category: e !== null ? e.value : "",
                            })
                          }
                          options={this.state.categoryOption}
                          value={this.state.categoryOption.filter(
                            (obj) => obj.value === this.state.id_category
                          )}
                          isDisabled={this.state.showLoadingCategory}
                          isLoading={this.state.showLoadingCategory}
                          placeholder=""
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_category === ""}
                          required
                          onChange={(e) =>
                            this.setState({ id_category: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Price <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.price}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ price: values.floatValue })
                          }
                          required
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label>Discount</label>
                        <NumberFormat
                          style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.discount}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ discount: values.floatValue })
                          }
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Stock <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.stock}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ stock: values.floatValue })
                          }
                          required
                        />
                      </div>
                    </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Weight (gram) <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          style={{ textAlign: "right" }}
                          className="form-control"
                          value={this.state.weight}
                          prefix={""}
                          onValueChange={(values) =>
                            this.setState({ weight: values.floatValue })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Description</label>
                        <div
                          style={{
                            // height: "300px",
                            maxHeight: "300px",
                            overflow: "auto",
                          }}
                        >
                          <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper border p-3"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            // contentState={this.state.news}
                            // onChange={this.handleChangeEditor}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.isError ? (
                      <div className="col-md-12">
                        <Alert
                          color="danger"
                          isOpen={this.state.isError}
                          toggle={() =>
                            this.setState({
                              isError: false,
                            })
                          }
                        >
                          <strong>{this.state.errorMessage}</strong>
                        </Alert>
                      </div>
                    ) : null}
                    <div className="col-md-12">
                      {this.state.showLoading ? (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={this.state.showLoading}
                        >
                          <div>
                            <i className="fas fa-circle-notch fa-spin"></i>{" "}
                            Executing your request
                          </div>
                        </Button>
                      ) : (
                        <div>
                          <Button
                            color="primary"
                            type="submit"
                            disabled={this.state.showLoading}
                          >
                            {this.state.showLoading ? (
                              <div>
                                <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                Executing your request
                              </div>
                            ) : this.state.isUpdate ? (
                              "Save changes"
                            ) : (
                              "Save changes & next to upload images"
                            )}
                          </Button>
                          {!this.state.isUpdate ? (
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => this.handleSaveFinish()}
                            >
                              {this.state.isUpdate
                                ? "Save changes"
                                : "Save changes & finish"}
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </CardBody>
            </Card>
          </div>
        </Row>
        {/* <ToastContainer /> */}
      </>
    );
  }
}
