import React, { Component } from "react";
import Header from "components/Headers/Header";
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  Row,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
} from "reactstrap";
import classnames from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Additional from "./Additional";
import AddressOrigin from "./AddressOrigin";
import BannerSetting from "./BannerSetting";
import CourierMaster from "./CourierMaster";

export default class GeneralSetting extends Component {
  constructor(props) {
    super(props);

    // this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
    };
  }
  
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderSwitch = (tabActive) => {
    switch (tabActive) {
      case "1":
        return <Additional {...this.props} />;
      case "2":
        return <AddressOrigin {...this.props} />;
      case "3":
        return <BannerSetting {...this.props} />;
      case "4":
        return <CourierMaster {...this.props} />;

      default:
        return <Additional {...this.props} />;
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>General Setting - Administrator Bikers Dream</title>
          <meta name="description" content="General setting page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Additional
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                       Store Address
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        Banner Image
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "4",
                        })}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        Courier Master
                      </NavLink>
                    </NavItem>
                  
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={this.state.activeTab}>
                      <Row>
                        <Col sm="12">
                          {this.renderSwitch(this.state.activeTab)}
                        </Col>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <FormUploadProduct
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <SizeProduct
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <FormVariantStockDetail
                            {...this.props}
                            record={this.state.record}
                          />
                        </Col>
                      </Row>
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </Row>
          <ToastContainer />
        </Container>
      </>
    );
  }
}
