/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Container,
  // Badge,
  CardHeader,
  FormGroup,
  Form,
  // Input,
  Col,
  Table as TableReactstrap,
} from "reactstrap";
import { Spin, Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import { Helmet } from "react-helmet";
import Header from "components/Headers/Header";
import { formatDateTimePlus } from "helper/helper";
import { Link } from "react-router-dom";
import Select from "react-select";
import { renderStatusTrx } from "helper/helper";

// import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';

// const { Step } = Steps;
export default class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      data: [],
      filteredData: [],
      value: "",
      showLoadingTable: false,
      showLoading: false,
      columns: [
        {
          title: "Invoice",
          dataIndex: "invoice",
          sorter: (a, b) => a.invoice.localeCompare(b.invoice),
          render: (text, record) => (
            <span>
              <Link to={"#"} onClick={() => this.openDetail(record)}>
                {text}
              </Link>
            </span>
          ),
        },
        {
          title: "Total Payment",
          dataIndex: "total_payment",
          align: "right",
          sorter: (a, b) => a.total_payment.localeCompare(b.total_payment),
          render: (text, record) => <span>Rp. {text}</span>,
        },
        {
          title: "Customer",
          dataIndex: "fullname",
          sorter: (a, b) => a.fullname.localeCompare(b.fullname),
        },
        {
          title: "Email",
          dataIndex: "useremail",
          sorter: (a, b) => a.useremail.localeCompare(b.useremail),
        },

        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          sorter: (a, b) => a.status.localeCompare(b.status),
          render: (text, record) => renderStatusTrx(record),
        },
        {
          title: "Updated Date",
          dataIndex: "updated_at",
          sorter: (a, b) =>
            moment(a.updated_at).unix() - moment(b.updated_at).unix(),
          render: (text, record) => <span>{formatDateTimePlus(text)}</span>,
        },
        // {
        //   title: "Content",
        //   dataIndex: "content",
        //   ellipsis: true,
        //   width: '70%',
        //   sorter: (a, b) => a.content.localeCompare(b.content),
        // },
        // {
        //   title: "Created Date",
        //   dataIndex: "created_at",
        //   sorter: (a, b) =>
        //     moment(a.created_at).unix() - moment(b.created_at).unix(),
        //   render: (text, record) => <span>{formatDateTime(text)}</span>,
        // },

        {
          title: "Action",
          align: "center",
          sorter: false,
          width: "10%",
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {record.status_code === "2" ? (
                  <>
                    <DropdownItem
                      onClick={() => this.updateStatus(record, "3", "Verify")}
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>Verify</span>
                    </DropdownItem>
                    <DropdownItem
                      // onClick={() => this.updateStatus(record, "7", "Reject")}
                      onClick={() =>
                        this.updateStatusReject(record, "7", "Reject")
                      }
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>Reject</span>
                    </DropdownItem>
                  </>
                ) : null}

                {record.status_code === "3" ? (
                  <>
                    <DropdownItem
                      onClick={() =>
                        this.updateStatusSent(record, "4", "Change to send")
                      }
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>Change to sent</span>
                    </DropdownItem>
                  </>
                ) : null}
                {record.status_code === "4" ? (
                  <>
                    <DropdownItem
                      onClick={() => this.updateStatus(record, "6", "Finish")}
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>Finish</span>
                    </DropdownItem>
                  </>
                ) : null}
                {record.status_code === "6" ? (
                  <>
                    <DropdownItem
                      onClick={() => this.updateStatus(record, "8", "Retur")}
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>Retur</span>
                    </DropdownItem>
                  </>
                ) : null}
                {record.status_code === "8" || record.status_code === "7" ? (
                  <>
                    <DropdownItem
                      onClick={(e) => e.preventDefault()}
                      style={{ cursor: "not-allowed" }}
                    >
                      {/* <i className="ni ni-settings" /> */}
                      <span>No more action</span>
                    </DropdownItem>
                  </>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      id_user: "",
      fullname: "",
      useremail: "",
      id_address: "",
      invoice: "",
      total_weigth: "",
      total_shipping: "",
      total_payment: "",
      status_code: "",
      status: "",
      name_bank: "",
      number_account: "",
      an: "",
      address: "",
      receiver: "",
      phone: "",
      created_at: new Date(),
      updated_at: new Date(),
      data_product: [],
      record: {},
      showModalConfirmation: false,
      showModalConfirmationSent: false,
      showModalConfirmationReject: false,
      showModalItems: false,
      showModalAddress: false,
      showLoadingAddress: false,
      listAddress: [],
      status_to_update: "",
      titleUpdate: "",
      no_resi: "",
      rationale: "",

      showLoadingCourier: false,
      courierOption: [],

      id_courier: "",
      courier_code: "",
      courier_name: "",
      courier_service: "",
      courier_service_description: "",

      statusFilter: "",
      statusFilterOption: [
        {
          label: "Waiting for confirmation",
          value: "2",
        },
        {
          label: "Verified",
          value: "3",
        },
        {
          label: "Sent to Destination",
          value: "4",
        },
        {
          label: "Finish",
          value: "6",
        },
        {
          label: "Rejected",
          value: "7",
        },
        {
          label: "Retur",
          value: "8",
        },
      ],
    };
  }

  componentDidMount = () => {
    if (this.props.location.state) {
      this.setState({
        statusFilter: this.props.location.state,
      });
      this.getdata(this.props.location.state);
    } else {
      this.getdata();
    }
    this.getdataCourier();
  };

  getdataCourier = async () => {
    this.setState({
      showLoadingCourier: true,
    });

    try {
      const res = await axiosCustom.get("master-courier");
      if (res.status === 200) {
        if (res.data.result) {
          var data = res.data.result;
          var courierOption = data.map((item) => {
            return {
              value: item.id,
              label: item.courier_code + " - " + item.courier_service,
              courier_service: item.courier_service,
              courier_code: item.courier_code,
            };
          });
          this.setState({
            courierOption,
          });
        }
      }
      this.setState({
        showLoadingCourier: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingCourier: false,
        courierOption: [],
      });
    }
  };

  getAddressUser = async (id_user, id_address) => {
    this.setState({
      showLoadingAddress: true,
    });
    try {
      const res = await axiosCustom.get(`address-options/${id_user}`);
      // console.log(res);
      if (res.status === 200) {
        var listAddress = res.data.result;

        var Address = listAddress.filter((item) => item.id === id_address);

        this.setState({
          listAddress: Address.length ? Address[0] : [],
        });
      } else {
        this.setState({
          listAddress: [],
        });
      }
      this.setState({
        showLoadingAddress: false,
      });
    } catch (err) {
      // Handle Error Here
      // console.log(err);
      this.setState({
        listAddress: [],
        showLoadingAddress: false,
      });
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  getdata = async (statusFilter = null) => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("list-order", {
        params: {
          status_code: statusFilter,
        },
      });
      if (res.status === 200) {
        if (res.data.result) {
          // var data = res.data.result
          var data = res.data.result.sort(function (a, b) {
            return parseInt(b.id) - parseInt(a.id);
          });
          this.setState({
            data,
          });
        }
      } else {
        this.setState({
          data: [],
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: [],
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.fullname).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.useremail)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.invoice).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.total_payment)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(formatDateTimePlus(item.updated_at))
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.status).toLowerCase().startsWith(value.toLowerCase());
        let includesCondition =
          String(item.fullname).toLowerCase().includes(value.toLowerCase()) ||
          String(item.useremail).toLowerCase().includes(value.toLowerCase()) ||
          String(item.invoice).toLowerCase().includes(value.toLowerCase()) ||
          String(item.total_payment)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(formatDateTimePlus(item.updated_at))
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(item.status).toLowerCase().includes(value.toLowerCase());
        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdateForm: false,
      id: "",
      id_parent: "",
      title: "",
      content: "",
      created_at: new Date(),
      updated_at: new Date(),
    });
  };
  openDataProduct = () => {
    this.toggleModal("showModalItems");
  };
  openAddressModal = () => {
    this.toggleModal("showModalAddress");
    this.getAddressUser(this.state.id_user, this.state.id_address);
  };
  openDetail = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      record,
      id: record.id,
      id_user: record.id_user,
      fullname: record.fullname,
      useremail: record.useremail,
      id_address: record.id_address,
      invoice: record.invoice,
      total_weigth: record.total_weigth,
      total_shipping: record.total_shipping,
      total_payment: record.total_payment,
      status_code: record.status_code,
      status: record.status,
      name_bank: record.name_bank,
      number_account: record.number_account,
      an: record.an,
      address: record.address,
      receiver: record.receiver,
      phone: record.phone,
      no_resi: record.no_resi,
      courier_code: record.courier_code,
      courier_service: record.courier_service,
      courier_service_description: record.courier_service_description,
      courier_name: record.courier_name,
      data_product: record.data_product,
      rationale: record.rationale,
      created_at: formatDateTimePlus(record.created_at),
      updated_at: formatDateTimePlus(record.updated_at),
    });
  };

  updateStatus = (record, status_to_update, titleUpdate) => {
    this.toggleModal("showModalConfirmation");
    this.setState({
      id: record.id,
      status_code: record.status_code,
      invoice: record.invoice,
      created_at: record.created_at,
      updated_at: new Date(),
      status_to_update,
      titleUpdate,
      rationale: null,
    });
  };
  updateStatusSent = (record, status_to_update, titleUpdate) => {
    this.toggleModal("showModalConfirmationSent");
    this.setState({
      id: record.id,
      status_code: record.status_code,
      invoice: record.invoice,
      created_at: record.created_at,
      updated_at: new Date(),
      status_to_update,
      titleUpdate,
      rationale: null,
      id_courier: "",
      courier_code: "",
      courier_name: "",
      courier_service: "",
      courier_service_description: "",
    });
  };
  updateStatusReject = (record, status_to_update, titleUpdate) => {
    this.toggleModal("showModalConfirmationReject");
    this.setState({
      id: record.id,
      status_code: record.status_code,
      invoice: record.invoice,
      created_at: record.created_at,
      updated_at: new Date(),
      status_to_update,
      titleUpdate,
      rationale: "",
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      id_parent: record.id_parent,
      title: record.title,
      content: record.content,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  handleSubmitUpdateSent = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = {
      no_resi: this.state.no_resi,
      courier_code: this.state.courier_code,
      courier_name: this.state.courier_name,
      courier_service: this.state.courier_service,
      courier_service_description: this.state.courier_service_description,
    };
    this.actionPostResi(this.state.id, dataInput);
  };
  handleSubmitUpdateReject = async (e) => {
    e.preventDefault();
    this.setState({
      showLoading: true,
    });

    this.processUpdateStatus(this.state.status_to_update);
  };

  actionPostResi = async (id, dataInput) => {
    try {
      let res = await axiosCustom.post("push-resi/" + id, dataInput);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showModalConfirmationSent");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  processUpdateStatus = async (status) => {
    this.setState({
      showLoading: true,
    });
    // var status = parseInt(this.state.status_code) + 1
    try {
      let res = {};
      res = await axiosCustom.post(
        "status-order/" + this.state.id + "/" + status,
        {
          rationale: this.state.rationale,
        }
      );
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (status === "7") {
          this.toggleModal("showModalConfirmationReject");
        } else {
          this.toggleModal("showModalConfirmation");
        }

        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Helmet>
          <title>Transaction - Administrator Bikers Dream</title>
          <meta name="description" content="Transaction page" />
        </Helmet>
        <Header />

        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-1"></div>
                    <div className="col-sm-6 col-md-4">
                      <Select
                        isClearable={true}
                        styles={{
                          menuPortal: (base) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        menuShouldScrollIntoView={false}
                        onChange={(e) =>
                          this.setState({
                            statusFilter: e !== null ? e.value : "",
                          })
                        }
                        options={this.state.statusFilterOption}
                        value={this.state.statusFilterOption.filter(
                          (obj) => obj.value === this.state.statusFilter
                        )}
                        isDisabled={
                          this.state.showLoadingTypeDiscount ||
                          this.state.showLoading
                        }
                        isLoading={this.state.showLoadingTypeDiscount}
                        placeholder="Select status"
                      />
                    </div>
                    <div className="col-sm-6 col-md-2">
                      {/* <div className="float-right"> */}
                      <Button
                        color="primary"
                        onClick={() => this.getdata(this.state.statusFilter)}
                        style={{ width: "100%" }}
                      >
                        Filter
                      </Button>
                      {/* </div> */}
                    </div>
                    <div className="col-sm-6 col-md-2">
                      {/* <div className="float-right"> */}
                      <Button
                        color="primary"
                        onClick={() => this.getdata()}
                        style={{ width: "100%" }}
                      >
                        Refresh
                      </Button>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalItems}
              toggle={() => this.toggleModal("showModalItems")}
            >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Items - {this.state.invoice}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {/* <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("showModalItems")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button> */}
                      <Button
                        color="primary"
                        type="button"
                        data-dismiss="modal"
                        onClick={() => this.toggleModal("showModalItems")}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <TableReactstrap
                  className="align-items-center table-flush"
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col" className="text-right">
                        Qty
                      </th>
                      <th scope="col" className="text-right">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.data_product.length ? (
                      this.state.data_product.map((item) => (
                        <tr>
                          <td>
                            <h4>{item.produkname}</h4>
                          </td>
                          <td className="text-right">{item.qty}</td>
                          <td className="text-right">Rp. {item.price}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No Items
                        </td>
                      </tr>
                    )}
                  </tbody>
                </TableReactstrap>
              </Card>
            </Modal>

            <Modal
              className="modal-dialog-centered modal-lg"
              isOpen={this.state.showModalAddress}
              toggle={() => this.toggleModal("showModalAddress")}
            >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Address Detail</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        type="button"
                        data-dismiss="modal"
                        onClick={() => this.toggleModal("showModalAddress")}
                      >
                        Close
                      </Button>
                      {/* <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("showModalAdd")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button> */}
                    </Col>
                  </Row>
                </CardHeader>
                <Spin spinning={this.state.showLoadingAddress}>
                  <CardBody>
                    {Array.isArray(this.state.listAddress) ? null : (
                      <Form>
                        {/* <h6 className="heading-small text-muted mb-4">
                        Invoice Information
                      </h6> */}
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="12">
                              <FormGroup>
                                <h4 className="mb-0">
                                  <b className="mr-4">
                                    {this.state.listAddress.receiver}
                                  </b>
                                </h4>
                                <p className="mb-0">
                                  {this.state.listAddress.address}
                                </p>
                                <p className="mb-0">
                                  {this.state.listAddress.propinsi}
                                </p>
                                <p className="mb-0">
                                  {this.state.listAddress.kabupaten}
                                </p>
                                <p className="mb-0">
                                  {this.state.listAddress.kecamatan}
                                </p>
                                <p className="mb-0">
                                  {this.state.listAddress.kelurahan}
                                </p>
                                <p className="mb-0">
                                  {this.state.listAddress.zip_code}
                                </p>
                                <p className="mb-4">
                                  {this.state.listAddress.phone}
                                </p>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    )}
                  </CardBody>
                </Spin>
              </Card>
            </Modal>

            <Modal
              className="modal-dialog modal-xl"
              // className="modal-dialog-centered modal-xl"
              isOpen={this.state.showModalAdd}
              toggle={() => this.toggleModal("showModalAdd")}
              // size={1200}
            >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Transaction Detail</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        type="button"
                        data-dismiss="modal"
                        onClick={() => this.toggleModal("showModalAdd")}
                      >
                        Close
                      </Button>
                      {/* <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("showModalAdd")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button> */}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    Invoice Information
                  </h6>
                  <div className="pl-lg-4">
                    <TableReactstrap
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Invoice</th>
                          <th className="text-center">Status</th>
                          <th className="text-right">Weight (gr)</th>
                          <th className="text-right">Order Date</th>
                          <th className="text-right">Total Shipping</th>
                          <th className="text-right">Total Payment</th>
                          {/* <th>Transfer to</th>
                          <th className="text-right">Account Number</th>
                          <th>Account Name</th> */}
                          {/* <th>Reason For Reject</th> */}
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{this.state.invoice}</td>
                          <td className="text-center">{this.state.status}</td>
                          <td className="text-right">
                            {this.state.total_weigth}
                          </td>
                          <td className="text-right">
                            {this.state.created_at}
                          </td>
                          <td className="text-right">{`Rp. ${this.state.total_shipping}`}</td>
                          <td className="text-right">{`Rp. ${this.state.total_payment}`}</td>
                          {/* <td>{this.state.name_bank}</td>
                          <td className="text-right">
                            {this.state.number_account}
                          </td>
                          <td>{this.state.an}</td> */}
                          {/* <td>{this.state.rationale}</td> */}
                        </tr>
                      </tbody>
                    </TableReactstrap>
                    <TableReactstrap
                      className="align-items-center table-flush mt-4"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Transfer to</th>
                          <th className="text-right">Account Number</th>
                          <th>Account Name</th>
                          <th>Reason For Reject</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{this.state.name_bank}</td>
                          <td className="text-right">
                            {this.state.number_account}
                          </td>
                          <td>{this.state.an}</td>
                          <td>{this.state.rationale ? this.state.rationale : "-"}</td>
                        </tr>
                      </tbody>
                    </TableReactstrap>
                    {/* <TableReactstrap
                      className="align-items-center table-flush mt-4"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Reason For Reject</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{this.state.rationale ? this.state.rationale : "-"}</td>
                        </tr>
                      </tbody>
                    </TableReactstrap> */}
                  </div>

                  <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Shipping Information
                    </h6>
                    <div className="pl-lg-4">
                    <TableReactstrap
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>No. Resi</th>
                          <th>Courier</th>
                          <th>Courier Service</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{this.state.no_resi}</td>
                          <td>{this.state.courier_code}</td>
                          <td>{this.state.courier_service}</td>
                          
                        </tr>
                      </tbody>
                    </TableReactstrap>
                    </div>

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Customer Information
                    </h6>
                    <div className="pl-lg-4">
                    <TableReactstrap
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th>Customer</th>
                          <th>Email address</th>
                          <th>Phone</th>
                          <th>Receiver</th>
                          <th>Address</th>
                          
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{this.state.fullname}</td>
                          <td>{this.state.useremail}</td>
                          <td>{this.state.phone}</td>
                          <td>{this.state.receiver}</td>
                          <td>
                          <Link
                                to="#"
                                onClick={() => this.openAddressModal()}
                                style={{
                                  textDecorationColor: "blue",
                                  textDecoration: "underline",
                                }}
                              >
                                See Detail
                              </Link>
                          </td>
                          
                        </tr>
                      </tbody>
                    </TableReactstrap>
                    </div>

                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">
                      Items Order
                    </h6>
                    <div className="pl-lg-4">
                      <FormGroup>
                        <Link
                          to={"#"}
                          onClick={() => this.openDataProduct()}
                          style={{
                            textDecorationColor: "blue",
                            textDecoration: "underline",
                          }}
                        >
                          See all items
                        </Link>
                      </FormGroup>
                    </div>
                </CardBody>
              </Card>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalConfirmationReject}
              toggle={() => this.toggleModal("showModalConfirmationReject")}
            >
              <form onSubmit={this.handleSubmitUpdateReject}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Confirmation Reject
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("showModalConfirmationReject")
                    }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Reason <span className="text-danger">*</span>
                        </label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={this.state.rationale}
                          onChange={(e) =>
                            this.setState({ rationale: e.target.value })
                          }
                          required
                          rows={3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {this.state.showLoading ? (
                    <Button
                      color="primary"
                      type="button"
                      disabled={this.state.showLoading}
                    >
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>
                          this.toggleModal("showModalConfirmationSent")
                        }
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.showLoading}
                      >
                        {this.state.titleUpdate}
                      </Button>
                    </>
                  )}
                </div>
              </form>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalConfirmationSent}
              toggle={() => this.toggleModal("showModalConfirmationSent")}
            >
              <form onSubmit={this.handleSubmitUpdateSent}>
                <div className="modal-header">
                  <h3 className="modal-title" id="exampleModalLabel">
                    Confirmation
                  </h3>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>
                      this.toggleModal("showModalConfirmationSent")
                    }
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Courier <span className="text-danger">*</span>
                        </label>
                        <Select
                          // isClearable={true}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                          menuPosition={"fixed"}
                          menuShouldScrollIntoView={false}
                          onChange={(e) =>
                            this.setState({
                              id_courier: e !== null ? e.value : "",
                              courier_code: e !== null ? e.courier_code : "",
                              courier_service:
                                e !== null ? e.courier_service : "",
                            })
                          }
                          options={this.state.courierOption}
                          value={this.state.courierOption.filter(
                            (obj) => obj.value === this.state.id_courier
                          )}
                          placeholder="Select courier ..."
                        />
                        <input
                          tabIndex={-1}
                          autoComplete="off"
                          style={{
                            opacity: 0,
                            height: 0,
                            position: "absolute",
                          }}
                          value={this.state.id_courier}
                          required
                          onChange={(e) =>
                            this.setState({ id_courier: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Courier Service <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.courier_service}
                          onChange={(e) =>
                            this.setState({ courier_service: e.target.value })
                          }
                          readOnly
                        />
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          No. Resi <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.no_resi}
                          onChange={(e) =>
                            this.setState({ no_resi: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {this.state.showLoading ? (
                    <Button
                      color="primary"
                      type="button"
                      disabled={this.state.showLoading}
                    >
                      <div>
                        <i className="fas fa-circle-notch fa-spin"></i>{" "}
                        Executing your request
                      </div>
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() =>
                          this.toggleModal("showModalConfirmationSent")
                        }
                      >
                        Close
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={this.state.showLoading}
                      >
                        {this.state.titleUpdate}
                      </Button>
                    </>
                  )}
                </div>
              </form>
            </Modal>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showModalConfirmation}
              toggle={() => this.toggleModal("showModalConfirmation")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Confirmation
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showModalConfirmation")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to{" "}
                  <b>{this.state.titleUpdate.toUpperCase()}</b> this invoice "
                  <b>{this.state.invoice}</b>"?
                </p>
              </div>
              <div className="modal-footer">
                {this.state.showLoading ? (
                  <Button
                    color="primary"
                    type="button"
                    disabled={this.state.showLoading}
                  >
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  </Button>
                ) : (
                  <>
                    <Button
                      color="secondary"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("showModalConfirmation")}
                    >
                      Close
                    </Button>
                    <Button
                      color="primary"
                      type="button"
                      disabled={this.state.showLoading}
                      onClick={() =>
                        this.processUpdateStatus(this.state.status_to_update)
                      }
                    >
                      {this.state.titleUpdate}
                    </Button>
                  </>
                )}
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
