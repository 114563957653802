/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  Container,
  DropdownMenu,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  Modal,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { Helmet } from "react-helmet";
import { Table } from "antd";
import "antd/dist/antd.css";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { currencyFormatIndo } from "helper/helper";
import { renderStatus } from "helper/helper";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

export default class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      showSubcategory: false,
      isUpdate: false,
      data: [],
      filteredData: [],
      value: "",
      dataSubcategory: [],
      filteredDataSubcategory: [],
      valueSubcategory: "",
      showLoadingTableSubcategory: false,
      showLoadingTable: false,
      showLoading: false,
      isSuccessSubmit: false,
      submitMessage: "",
      columns: [
        {
          title: "Code",
          dataIndex: "code",
          sorter: (a, b) => a.code.localeCompare(b.code),
        },
        {
          title: "Product",
          dataIndex: "produkname",
          sorter: (a, b) => a.produkname.localeCompare(b.produkname),
        },
        {
          title: "Category",
          dataIndex: "categoryname",
          sorter: (a, b) => a.categoryname.localeCompare(b.categoryname),
        },
        // {
        //   title: "Price",
        //   dataIndex: "price",
        //   sorter: (a, b) => a.price - b.price,
        //   render: (text, record) => <span>{currencyFormatIndo(text)}</span>,
        // },
        // {
        //   title: "Discount",
        //   dataIndex: "discount",
        //   sorter: (a, b) => a.discount - b.discount,
        //   render: (text, record) => <span>{currencyFormatIndo(text)}</span>,
        // },
        // {
        //   title: "Stock",
        //   dataIndex: "stock",
        //   sorter: (a, b) => a.stock - b.stock,
        // },
        {
          title: "Weight (gram)",
          dataIndex: "weight",
          sorter: (a, b) => a.weight - b.weight,
        },
        {
          title: "Posted",
          dataIndex: "status_posting",
          sorter: (a, b) => a.status_posting.localeCompare(b.status_posting),
          render: (text, record) => <span>{renderStatus(text)}</span>,
        },

        {
          title: "Action",
          align: "center",
          sorter: false,
          render: (text, record) => (
            <UncontrolledDropdown>
              <DropdownToggle>
                <i className="fa fa-cog"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  to={{
                    pathname: `/admin/form-product-detail/product-detail/${record.uid}`,
                    state: record,
                  }}
                  // to={{ pathname: `/admin/form-product-detail`, state: record }}
                  tag={Link}
                >
                  {/* <i className="ni ni-settings" /> */}
                  <span>Edit product</span>
                </DropdownItem>
                {/* <DropdownItem to={{ pathname: `/admin/form-upload-product`, state: record }} tag={Link}>
                  <i className="ni ni-cloud-upload-96" />
                  <span>Upload Image</span>
                </DropdownItem> */}
                {record.status_posting === "N" ? (
                  <DropdownItem onClick={() => this.postedData(record)}>
                    {/* <i className="ni ni-check-bold" /> */}
                    <span>Change to posted</span>
                  </DropdownItem>
                ) : (
                  <DropdownItem onClick={() => this.disableData(record)}>
                    {/* <i className="fa fa-trash" /> */}
                    <span>Disabled</span>
                  </DropdownItem>
                )}

                {/* <DropdownItem onClick={() => this.deleteData(record)}>
                  <i className="fa fa-trash" />
                  <span>Delete</span>
                </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          ),
        },
      ],

      id: "",
      uid: "",
      id_category: "",
      produkname: "",
      slugname: "",
      code: "",
      price: "0",
      discount: "0",
      weight: "0",
      stock: "0",
      description: "",
      status_posting: "",
      created_at: new Date(),
      updated_at: new Date(),
      categoryname: "",
      slugcategory: "",
    };
  }

  componentDidMount = () => {
    this.getdata();
    if (localStorage.getItem("successSubmit")) {
      this.setState({
        isSuccessSubmit: true,
        submitMessage: "Data has been saved",
      });
      localStorage.removeItem("successSubmit");
    }
  };

  getdata = async () => {
    this.setState({
      showLoadingTable: true,
      value: "",
    });

    try {
      const res = await axiosCustom.get("product");
      if (res.status === 200) {
        var data = res.data.result.sort(function (a, b) {
          return parseInt(b.id) - parseInt(a.id);
        });
        this.setState({
          data,
        });
      }
      this.setState({
        showLoadingTable: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoadingTable: false,
        data: [],
      });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    let data = this.state.data;
    let filteredData = this.state.filteredData;
    this.setState({ value });

    if (value.length) {
      filteredData = data.filter((item) => {
        let startsWithCondition =
          String(item.code).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.produkname)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          String(item.categoryname)
            .toLowerCase()
            .startsWith(value.toLowerCase()) ||
          // String(currencyFormatIndo(item.price))
          //   .toLowerCase()
          //   .startsWith(value.toLowerCase()) ||
          // String(currencyFormatIndo(item.discount))
          //   .toLowerCase()
          //   .startsWith(value.toLowerCase()) ||
          // String(item.stock).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.weight).toLowerCase().startsWith(value.toLowerCase()) ||
          String(item.status_posting)
            .toLowerCase()
            .startsWith(value.toLowerCase());
        let includesCondition =
          String(item.code).toLowerCase().includes(value.toLowerCase()) ||
          String(item.produkname).toLowerCase().includes(value.toLowerCase()) ||
          String(item.categoryname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          // String(currencyFormatIndo(item.price))
          //   .toLowerCase()
          //   .includes(value.toLowerCase()) ||
          // String(currencyFormatIndo(item.discount))
          //   .toLowerCase()
          //   .includes(value.toLowerCase()) ||
          // String(item.stock).toLowerCase().includes(value.toLowerCase()) ||
          String(item.weight).toLowerCase().includes(value.toLowerCase()) ||
          String(item.status_posting)
            .toLowerCase()
            .includes(value.toLowerCase());

        if (startsWithCondition) {
          return startsWithCondition;
        } else if (!startsWithCondition && includesCondition) {
          return includesCondition;
        } else return null;
      });
      this.setState({ filteredData });
    }
  };

  openModal = () => {
    this.props.history.push("/admin/form-product");
    // this.toggleModal("showModalAdd");
    // this.setState({
    //   isUpdate: false,
    //   id: "",
    //   id_category: null,
    //   categoryname: "",
    //   slugname: "",
    //   created_at: new Date(),
    //   updated_at: new Date(),
    // });
  };

  editData = (record) => {
    this.toggleModal("showModalAdd");
    this.setState({
      isUpdate: true,
      id: record.id,
      id_category: record.id_category,
      categoryname: record.categoryname,
      slugname: record.slugname,
      created_at: new Date(record.created_at),
      updated_at: new Date(),
    });
  };
  deleteData = (record) => {
    this.toggleModal("showDeleteModal");
    this.setState({
      id: record.id,
      uid: record.uid,
      produkname: record.produkname,
    });
  };
  postedData = (record) => {
    this.toggleModal("showPostedModal");
    this.setState({
      id: record.id,
      uid: record.uid,
      produkname: record.produkname,
    });
  };
  disableData = (record) => {
    this.toggleModal("showDisabledModal");
    this.setState({
      id: record.id,
      uid: record.uid,
      produkname: record.produkname,
    });
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  processDelete = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.delete("product/" + this.state.uid);
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDeleteModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processPosting = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.post("product-change-status/" + this.state.uid, {
        status_posting: "Y",
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showPostedModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };
  processDisable = async (e) => {
    this.setState({
      showLoading: true,
    });
    try {
      let res = {};
      res = await axiosCustom.post("product-change-status/" + this.state.uid, {
        status_posting: "N",
      });
      // console.log(res);

      if (res.status === 200) {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        this.toggleModal("showDisabledModal");
        this.getdata();
      }
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
      });
    }
  };

  render() {
    const { value, filteredData, data, showLoadingTable, columns } = this.state;
    return (
      <>
        <Helmet>
          <title>Product - Administrator Bikers Dream</title>
          <meta name="description" content="Product page" />
        </Helmet>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                {/* <CardHeader className="border-0">
                  
                </CardHeader> */}
                <CardBody>
                  {this.state.isSuccessSubmit ? (
                    <div className="row">
                      <div className="col-md-12">
                        <Alert
                          color="success"
                          isOpen={this.state.isSuccessSubmit}
                          toggle={() =>
                            this.setState({
                              isSuccessSubmit: false,
                            })
                          }
                        >
                          <strong>Success!</strong> {this.state.submitMessage}
                        </Alert>
                      </div>
                    </div>
                  ) : null}
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          value={value}
                          onChange={(e) => this.handleFilter(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3"></div>
                    <div className="col-sm-6 col-md-3">
                      <div className="float-right">
                        <Button
                          color="primary"
                          // data-toggle="modal"
                          // data-target="#showModalAdd"

                          onClick={() => this.openModal()}
                        >
                          {/* <Link to="/admin/form-product">+ Add data</Link> */}
                          + Add data
                        </Button>
                        <Button color="primary" onClick={() => this.getdata()}>
                          Refresh
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* Search Filter */}
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-hover"
                          pagination={{
                            total: value.length
                              ? filteredData.length
                              : data.length,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto" }}
                          columns={columns}
                          bordered
                          dataSource={value.length ? filteredData : data}
                          rowKey={(record) => record.id}
                          loading={showLoadingTable}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDisabledModal}
              toggle={() => this.toggleModal("showDisabledModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Disable data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDisabledModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to disable this data "
                  {this.state.produkname}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDisabledModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDisable()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Disabled"
                  )}
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showPostedModal}
              toggle={() => this.toggleModal("showPostedModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Post data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showPostedModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to post this data "
                  {this.state.produkname}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showPostedModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processPosting()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Post"
                  )}
                </Button>
              </div>
            </Modal>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.showDeleteModal}
              toggle={() => this.toggleModal("showDeleteModal")}
            >
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  Delete data
                </h3>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you want to delete this data "
                  {this.state.produkname}"?
                </p>
              </div>
              <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("showDeleteModal")}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  type="button"
                  disabled={this.state.showLoading}
                  onClick={() => this.processDelete()}
                >
                  {this.state.showLoading ? (
                    <div>
                      <i className="fas fa-circle-notch fa-spin"></i> Executing
                      your request
                    </div>
                  ) : (
                    "Delete"
                  )}
                </Button>
              </div>
            </Modal>
          </Row>

          <ToastContainer />
        </Container>
      </>
    );
  }
}
