/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Button, Card, CardBody, Row } from "reactstrap";
import "../../antdstyle.css";
// import moment from "moment";
import axiosCustom from "http/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { formatDateTime } from "helper/helper";
// import { formatDate } from "helper/helper";
import NumberFormat from "react-number-format";
import { Spin } from "antd";

export default class Additional extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalAdd: false,
      showDeleteModal: false,
      isUpdate: false,
      isUpdateForm: false,
      showLoading: false,

      id: "",
      setcode: "add_ongkir",
      label: "",
      value: 0,
      file_image: "",
      file_url: "",
      created_at: new Date(),
      updated_at: new Date(),
    };
  }

  componentDidMount = () => {
    this.getdata()
  };

  getdata = async () => {
    this.setState({
      showLoading: true,
      // value: "",
    });

    try {
      const res = await axiosCustom.get("setting/" + this.state.setcode);
      if (res.status === 200) {
        if (res.data.result !== "") {
          // var data = res.data.result
          var data = res.data.result
          this.setState({
            id: data.id,
            label: data.label,
            value: parseInt(data.value),
            file_image: data.file_image,
            file_url: data.file_url,
          });
        }
      }
      this.setState({
        showLoading: false,
      });
    } catch (err) {
      // Handle Error Here
      var msg = err ? err.response.data.messages : "Error invalid";
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      this.setState({
        showLoading: false,
        data: [],
      });
    }
  };

  handleSubmit = async () => {
    // e.preventDefault();
    this.setState({
      showLoading: true,
    });

    const dataInput = new FormData();
    dataInput.append("label", this.state.label);
    dataInput.append("value", this.state.value);

    this.actionSubmit("setting/" + this.state.setcode, dataInput);
  };

  actionSubmit = (url, dataInput) => {
    axiosCustom
      .post(url, dataInput, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.setState({
          showLoading: false,
        });
        toast.success(res.data.messages, {
          position: toast.POSITION.TOP_RIGHT,
        });
        // this.toggleModal("showModalAdd");
        // this.getdata();
      })
      .catch((err) => {
        this.setState({
          showLoading: false,
        });
        var msg = err ? err.response.data.messages : "Error invalid";
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  render() {
    const { 
      // id,
      // setcode,
      label,
      // value,
      // file_image,
      // file_ur,
     } = this.state;
    return (
      <>
         <Row>
            <div className="col">
           <Spin spinning={this.state.showLoading}>
           <Card className="shadow">
                {/* <CardHeader className="border-0">
                
              </CardHeader> */}
                <CardBody>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{label}</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group row">
                            <div className="col-lg-8">
                            <NumberFormat
                              style={{ textAlign: "right" }}
                              className="form-control"
                              value={this.state.value}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={""}
                              onValueChange={(e) =>
                                this.setState({
                                  value: e.floatValue
                                })
                              }
                              disabled={this.state.showLoading}
                            />
                            </div>
                            <div className="col-lg-4">
                              <Button
                                color="primary"
                                type="button"
                                disabled={this.state.showLoading}
                                onClick={() => this.handleSubmit()}
                              >
                                {this.state.showLoading ? (
                                  <div>
                                    <i className="fas fa-circle-notch fa-spin"></i>{" "}
                                    Executing your request
                                  </div>
                                ) : (
                                  "Save Changes"
                                )}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <span className="text-danger">
                              <i>
                                (Note: additional fee that will be combined with
                                the shipping fee in the order transaction)
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
           </Spin>
            </div>
          </Row>

          <ToastContainer />
      </>
    );
  }
}
